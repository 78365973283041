import { useMemo } from "react"
import { useState } from "react"
import { useRef } from "react"
import GenericForm from "../../../../containers/form-composer/generic-form"
import { useRequestAccessMutation } from "../../../../queries-and-mutations/mutation"
import { useGetAllBuildings } from "../../../../queries-and-mutations/queries"
import { validation } from "../../../../utils"
import { getMapImageUri } from "../../../../utils/map"

const RequestAccess = () => {
  const [position, setPosition] = useState()
  const formref = useRef()
  const requestAccessMutation = useRequestAccessMutation()
  const { data, isFetching } = useGetAllBuildings()
  const buildings = data?.data?.results || []
  const formFields = [
    {
      label: "Building",
      name: "building",
      placeholder: "Select Building",
      compType: "dropdown",
      menu: buildings,
      onChange: build => {
        setPosition({ lat: build.latitude, long: build.longitude })
      },
      rules: {
        required: "Building is required!"
      }
    },
    {
      label: "Floor Number",
      name: "floor_number",
      placeholder: "Floor Number",
      rules: {
        required: "Floor Number is required!"
      }
    },
    {
      label: "Unit Number",
      name: "unit_number",
      placeholder: "Unit Number",
      rules: {
        required: "Unit Number is required!"
      }
    },
    {
      label: "Phone Number",
      name: "phone",
      placeholder: "phone",
      rules: {
        required: "Phone Number is required!",
        validate: { ...validation.internationalPhone }
      }
    },
    {
      label: "Carrier Delivery Description",
      name: "delivery_description",
      placeholder: "Enter Carrier Delivery Description",
      rules: {
        required: "Delivery Description is required!"
      }
    },
    {
      label: "Concierge notes",
      name: "concierge_notes",
      placeholder: "Enter Concierge notes",
      rules: {
        required: "Concierge notes is required!"
      }
    },
    {
      label: "Arrival Time",
      name: "arrival_time",
      placeholder: "Enter Arrival Time",
      type: "time",
      className: `half`
    },
    {
      label: "Departure Time",
      name: "departure",
      placeholder: "Enter Departure",
      type: "time",
      className: `half`
    }
  ]

  const onSubmit = vals => {
    console.log("On submit values", vals)
    requestAccessMutation.mutate(
      {
        floor_number: vals.floor_number,
        unit_number: vals.unit_number,
        phone: vals.phone,
        description: vals.delivery_description,
        concierge_notes: vals.concierge_notes,
        arrival_time: vals.arrival_time,
        departure_time: vals.departure,
        building: vals.building
      },
      {
        onSuccess: response => {
          alert("Request Access Created !")
        }
      }
    )
  }

  return (
    <>
      <h2 className="text-center">Request Access</h2>
      <div className="row white-bg p-2 gap-2">
        <div className="col">
          <GenericForm
            ref={formref}
            submitButtonClass="mt-5"
            formFields={formFields}
            submitButtonText="Save"
            onSubmit={onSubmit}
            logging={false}
          />
        </div>
        <div className="col">
          {position && (
            <img src={getMapImageUri(position.lat, position.long)} alt="map" />
          )}
        </div>
      </div>
    </>
  )
}
export default RequestAccess
