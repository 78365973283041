import { Button, Col, Container, Dropdown, Row } from "react-bootstrap"
import { useParams } from "react-router-dom"
import BackArrowNoTail from "../../../atoms/back-arrow-no-tail"
import { useGetAdminUserDetail } from "../../../queries-and-mutations/queries"
import { assets, getUserTypeStringFromEnum } from "../../../utils"

function AdminUserDetail() {
  const { id } = useParams()
  const { data, isFetching } = useGetAdminUserDetail({ id })
  const { user_address, userprofile, ...user } = data?.data || {}
  return isFetching ? (
    ""
  ) : (
    <div>
      <div className="d-flex justify-content-between section-heading">
        <div className="d-flex justify-content-start">
          <BackArrowNoTail />
          <h2 className="">View User</h2>
        </div>
        <div>
          <Button variant="light">+ Give Access</Button>
        </div>
      </div>
      <div className="white-bg">
        <Container>
          <Row>
            <Col className="border pt-4 pb-4 ps-0 pe-0">
              <div className="d-flex flex-column align-items-center">
                <div>
                  <img
                    src={userprofile?.avatar || assets.avatar}
                    alt="orderdetail"
                  />
                </div>
                <div>
                  <h6>{`${user?.first_name} ${user?.last_name}`}</h6>
                </div>
                <div>{user?.email}</div>
              </div>
              <hr />
              <div className="p-2">
                <Row className="mb-4">
                  <Col>
                    <div>
                      <h6>ID Number</h6>
                    </div>
                    <div>{userprofile?.id_number}</div>
                  </Col>
                  <Col>
                    <div>
                      <h6>Mobile Number</h6>
                    </div>
                    <div>{user?.mobile_number}</div>
                  </Col>
                  <Col>
                    <div>
                      <h6>Category</h6>
                    </div>
                    <div>{getUserTypeStringFromEnum(user?.user_type)}</div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div>
                      <h6>Assigned Building </h6>
                    </div>
                    <div>
                      {user_address
                        ? `${"Floor: " + user_address.floor_number + ","} ${
                            "Unit: " + user_address.unit_number + ","
                          } ${user_address.building_name}`
                        : ""}
                    </div>
                  </Col>
                  <Col>
                    <div>
                      <h6>Status</h6>
                    </div>
                    <div>
                      {userprofile?.is_profile_confirm
                        ? "Approved"
                        : "Not Approved"}
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col className="border pt-4 pb-4">
              <div>
                <h6>Authorised Areas</h6>
              </div>
              <Row className="mb-2">
                <Col>
                  <div className="border p-2 d-flex flex-column align-items-center">
                    <div className="authorised-area">
                      <img src={assets.Parking} alt="parking" />
                    </div>
                    <div>Parking</div>
                  </div>
                </Col>
                <Col>
                  <div className="border p-2 d-flex flex-column align-items-center">
                    <div className="authorised-area">
                      <img src={assets.Parking} alt="parking" />
                    </div>
                    <div>Gym</div>
                  </div>
                </Col>
                <Col>
                  <div className="border p-2 d-flex flex-column align-items-center">
                    <div className="authorised-area">
                      <img src={assets.Parking} alt="parking" />
                    </div>
                    <div>Yoga Studio</div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="border p-2 d-flex flex-column align-items-center">
                    <div className="authorised-area">
                      <img src={assets.Parking} alt="parking" />
                    </div>
                    <div>Digital Locker</div>
                  </div>
                </Col>
                <Col></Col>
                <Col></Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}
export default AdminUserDetail
