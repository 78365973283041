import React, { useEffect, useState } from "react"
import { Dropdown } from "react-bootstrap"
import DatePicker from "react-datepicker"
import { formatDateToStringUS } from "../utils"

import "react-datepicker/dist/react-datepicker.css"
import "./atoms-styles/dropdown-datepicker.scss"

const DropdownDatePicker = ({
  className,
  onChange,
  selectedDate = new Date()
}) => {
  const [date, setSelectedDate] = useState(selectedDate)

  useEffect(() => {
    onChange?.(new Date(date))
  }, [date])

  return (
    <DatePicker
      placeholderText={
        !!date ? formatDateToStringUS(new Date(date)) : "Select Date"
      }
      selected={date}
      onChange={date => setSelectedDate(date)}
      className={`form-control ${className}`}
    />
  )
}

export default DropdownDatePicker
