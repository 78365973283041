import React from "react"
import { APPCONFIG } from "../../config"

import "./chart-index-palette.scss"

function IndexPalleteBarChartMinMaxAvg({ className }) {
  return (
    <>
      <div className={`d-flex align-items-center ${className}`}>
        <span
          className="index--bar-chart"
          style={{ backgroundColor: APPCONFIG.graphs.colorBar }}
        >
          <span className="label low"></span>
          <span className="space-1"></span>
          <span
            className="label avg"
            style={{ backgroundColor: APPCONFIG.graphs.colorAvgMark }}
          ></span>
          <span className="space-2"></span>
          <span className="label high"></span>
        </span>
      </div>
    </>
  )
}

export default IndexPalleteBarChartMinMaxAvg
