import React, { useState } from "react"
import { FormControl, Spinner, InputGroup } from "react-bootstrap"
import { assets } from "../../../../utils"
import NoOfVisitorChart from "./access-chart/no-of-visitor-chart"
import "./analytics.scss"
import DeliveryDurationChart from "./delivery-duration"
import DeliveryFrequencyChart from "./delivery-frequency"
import DeliveryTimesChart from "./delivery-times"
import LiftPeakTimesLine from "./lift-peak-times-line"
import PeakTime from "./peak-times/peak-times"
import WaitTime from "./top-deliveries/top-deliveries"
import TopTenServiceProviders from "./top-sp-table/top-sp-table"

function Analytics(props) {
  const [query, setQuery] = useState("")

  return (
    <div className="analytics-screen">
      <h2 className="section-heading">Analytics {"&"} Reports</h2>
      <div className="d-flex align-items-center">
        <InputGroup className="my-3 w-50">
          <FormControl
            style={{ borderRight: 0 }}
            onChange={e => setQuery(e.target.value)}
            placeholder={"Search"}
            className="dashboard-header-search w-50"
          />
        </InputGroup>

        <button className="page-btn ms-auto px-2">
          <img src={assets.downloadIcon} alt="download" />
        </button>
        <button className="page-btn d-flex align-items-center mx-3 px-3">
          <img src={assets.sortIcon} alt="download" />
          <img src={assets.dropdownArrow} alt="" />
        </button>
      </div>
      <div className="row g-3">
        <div className="col-lg-6 col-xs-12 chart-container">
          <NoOfVisitorChart />
        </div>
        <div className="col-lg-6 col-xs-12 chart-container">
          <TopTenServiceProviders />
        </div>
        <div className="col-md-12 col-xs-12 chart-container">
          <LiftPeakTimesLine />
        </div>
        <div className="col-md-12 col-xs-12 chart-container">
          <PeakTime />
        </div>
        <div className="col-lg-6 col-xs-12 chart-container">
          <DeliveryTimesChart />
        </div>
        <div className="col-lg-6 col-xs-12 chart-container">
          <DeliveryDurationChart />
        </div>
        <div className="col-lg-6 col-xs-12 chart-container">
          <WaitTime />
        </div>
        <div className="col-lg-6 col-xs-12 chart-container">
          <DeliveryFrequencyChart />
        </div>
      </div>
    </div>
  )
}

export default Analytics
