import React from "react"
import { Outlet } from "react-router-dom"

const GenericContainer = ({}) => {
  return (
    <div className="generic-container">
      <Outlet />
    </div>
  )
}

export default GenericContainer
