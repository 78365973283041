import React, { useEffect, useLayoutEffect, useState } from "react"
import * as d3 from "d3"
import { APPCONFIG } from "../config"

function BarChartMinMaxAvg({
  id,
  chartW = 550,
  chartH = 324,
  customPaddings = {},
  startYscaleFrom = 0,
  data = [],
  yAxisLabelSuffix = "",
  barWidth = 30,
  avgMarkHeight = 2,
  colorBar = APPCONFIG.graphs.colorBar,
  colorAvgMark = APPCONFIG.graphs.colorAvgMark
}) {
  const paddings = {
    top: 25,
    left: 25 + yAxisLabelSuffix.length * 5,
    right: 25,
    bottom: 20,
    ...customPaddings
  }

  const createScales = () => {
    const _xScale = d3
      .scaleBand()
      .domain(data.map(e => e.bandName))
      .range([paddings.left, chartW - paddings.right])
    _xScale.idd = Date.now()

    const _yScale = d3
      .scaleLinear()
      .domain([
        startYscaleFrom,
        d3.max(data.map(e => e.bars.map(bar => bar.max)).flatMap(e => e))
      ])
      .range([chartH - paddings.bottom, paddings.top])
    _yScale.idd = Date.now()
    return [_xScale, _yScale]
  }

  const [scaleX, setScalX] = useState(undefined)
  const [scaleY, setScalY] = useState(undefined)

  const createAxes = () => {
    if (scaleX && scaleY) return [d3.axisBottom(scaleX), d3.axisLeft(scaleY)]
    return []
  }
  const barMargin = (bandwidth, count) =>
    (bandwidth - count * barWidth) / (count + 1)

  const getBarEnterFn = enter => {
    // bar from avg to min
    enter
      .append("rect")
      .attr("width", barWidth)
      .attr("fill", colorBar)
      .attr("class", d => "entered " + d.bandName)
      .attr("transform", (d, i) => {
        const rightShift =
          scaleX(d.bandName) +
          barMargin(scaleX.bandwidth(), d.count) * (i + 1) +
          barWidth * (i + 0)
        const downshift = chartH - paddings.bottom
        return `translate(${rightShift /* - barWidth*/},${
          !!d.avg ? scaleY(d.avg) + avgMarkHeight / 2 : downshift
        }) rotate(0)`
      })
      .transition()
      .duration(1000)
      .attr("height", d => chartH - paddings.bottom - scaleY(d.avg - d.min))

    // bar from avg to max
    enter
      .append("rect")
      .attr("width", barWidth)
      .attr("fill", colorBar)
      .attr("class", d => "entered " + d.bandName)
      .attr("transform", (d, i) => {
        const rightShift =
          scaleX(d.bandName) +
          barMargin(scaleX.bandwidth(), d.count) * (i + 1) +
          barWidth * (i + 1)
        const downshift = chartH - paddings.bottom
        return `translate(${rightShift},${
          !!d.avg ? scaleY(d.avg) + avgMarkHeight / 2 : downshift
        }) rotate(180)`
      })
      .transition()
      .duration(1000)
      .attr("height", d => chartH - paddings.bottom - scaleY(d.max - d.avg))

    // avg mark
    enter
      .append("rect")
      .attr("width", barWidth)
      .attr("fill", colorAvgMark)
      .attr("height", avgMarkHeight) // d => (d.height < barWidth / 2 ? d.height : barWidth / 2)
      .attr("transform", (d, i) => {
        const rightShift =
          scaleX(d.bandName) +
          barMargin(scaleX.bandwidth(), d.count) * (i + 1) +
          barWidth * (i + 1)
        const downshift = chartH - paddings.bottom
        return `translate(${rightShift},${
          !!d.avg ? scaleY(d.avg) + avgMarkHeight / 2 : downshift //- (!!d.avg ? d.avg + avgMarkHeight / 2 : 0)
        }) rotate(180)`
      })
      .transition()
      .duration(1000)
      .attr("height", avgMarkHeight)
  }

  const getBarGroupEnterFunction = enter => {
    return enter
      .append("g")
      .attr("class", "bars")
      .selectAll("rect")
      .data(d =>
        d.bars.map(e => ({ ...e, bandName: d.bandName, count: d.bars.length }))
      )
      .join(getBarEnterFn)
  }

  const getBarGroupUpdateFunction = update => {
    update.selectAll("rect").remove()
    update
      .selectAll("rect")
      .data(d =>
        d.bars.map(e => ({ ...e, bandName: d.bandName, count: d.bars.length }))
      )
      .join(getBarEnterFn)
  }

  useEffect(() => {
    const [x, y] = createScales()
    setScalX(() => x)
    setScalY(() => y)
  }, [data])

  useEffect(() => {
    const [xAxisCall, yAxisCall] = createAxes()
    if (xAxisCall && yAxisCall) {
      const svgContainer = d3.select(`svg#${id}`)
      const svg = svgContainer.select("g.container").node()
        ? svgContainer.select("g.container")
        : svgContainer.append("g").attr("class", "container")

      // y axis label suffix
      yAxisCall.tickFormat((d, _) => `${d}${yAxisLabelSuffix}`)

      const xAxis = svg.selectAll("g.x-axis").node()
        ? svg.selectAll("g.x-axis")
        : svg.append("g").attr("class", "x-axis")

      xAxis
        .attr("transform", `translate(${0},${chartH - paddings.bottom})`)
        .transition()
        .duration(1000)
        .call(xAxisCall)

      const yAxis = svg.selectAll("g.y-axis").node()
        ? svg.selectAll("g.y-axis")
        : svg.append("g").attr("class", "y-axis")

      yAxis
        .attr("transform", `translate(${paddings.left},${0})`)
        .transition()
        .duration(1000)
        .call(yAxisCall)
        .call(g => g.select(".domain").remove())
        .call(g =>
          g
            .selectAll(".tick line")
            .attr("x2", chartW - paddings.left - paddings.right)
            .attr("stroke-opacity", 0.2)
        )

      svg
        .selectAll("g.bars")
        .data(data)
        .join(getBarGroupEnterFunction, getBarGroupUpdateFunction)
    }
  }, [scaleX, scaleY])

  return (
    <svg
      className="bar-chart svg-chart"
      id={id}
      viewBox={`0 0 ${chartW} ${chartH}`}
    ></svg>
  )
}

export default BarChartMinMaxAvg
