import React, { useEffect } from "react"
import { FormControl, InputGroup, Spinner } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { svgIcons } from "../../../../assets/svgIcons"
import { Dropdown } from "../../../../atoms"
import { useModalAPIs } from "../../../../containers/popup-cotainer/modal-apis"
import { Table } from "../../../../molecules"
import {
  useGetCarriers,
  useGetUsers
} from "../../../../queries-and-mutations/queries"
import {
  assets,
  getUserEnum,
  getUserTypeStringFromEnum,
  UserTypes
} from "../../../../utils"
import AddCarrier from "./add-carrier"
import { EditUserScreen } from "./add-user"
import "./home.scss"
import DeliveryTimeChart from "./sp-charts/delivery-time-chart"
import ServiceProviderDeliveryCompetetion from "./sp-charts/delivery-competetion-doughnut"
import WaitingTimeChart from "./owner-charts/waiting-time-chart"
import AccessAreaChart from "./owner-charts/access-area-chart"
import { useDeleteUser } from "../../../../queries-and-mutations/mutation"
import Button from "../../../../atoms/button"

function Home() {
  const nav = useNavigate()
  const userEnum = getUserEnum()
  const isServiceProvider = userEnum == UserTypes.serviceProvider
  const isOwner = userEnum == UserTypes.owner
  const [page, setPage] = React.useState(1)
  const [query, setQuery] = React.useState("")
  const [allowFetchCarrier, setAllowFetchCarrier] = React.useState(false)
  const [allowFetchUsers, setAllowFetchUsers] = React.useState(false)
  const [fetchTimeout, setFetchTimeout] = React.useState()
  const [searchTerm, setSearchTerm] = React.useState("")
  const [textLoading, setTextLoading] = React.useState(false)
  const [selectedUser, setSelectedUser] = React.useState(null)
  const [sorting, setSorting] = React.useState(null)
  const [ordering, setOrdering] = React.useState()
  const deleteUserMutation = useDeleteUser()

  const updateSorting = fieldName => {
    if (sorting && sorting.field === fieldName) {
      if (sorting.order === "ASC") {
        setSorting({ ...sorting, order: "DESC" })
      } else {
        setSorting(null)
      }
    } else {
      setSorting({ field: fieldName, order: "ASC" })
    }
  }

  useEffect(() => {
    if (sorting) {
      if (sorting.order === "ASC") {
        setOrdering(sorting.field)
      } else {
        setOrdering(`-${sorting.field}`)
      }
    } else {
      setOrdering(null)
    }
  }, [sorting])

  const {
    setModalComponent,
    setModalVisibility,
    setModalContentClass
  } = useModalAPIs()

  const size = 10
  const getUserTypeParams = () =>
    selectedUser?.value ? { user_type: selectedUser.value } : {}

  const {
    data: usersResponse,
    isFetching: usersFetching,
    refetch
  } = useGetUsers(
    page,
    searchTerm,
    size,
    { ...getUserTypeParams(), ordering },
    {
      enabled: isOwner && allowFetchUsers
    }
  )

  const openAddUserPopup = (editMode = false, user) => {
    setModalContentClass("p-5")
    setModalComponent(() => (
      <EditUserScreen isEditMode={editMode} user={user} />
    ))
    setModalVisibility(true)
  }
  const openDeleteUserPopup = id => {
    setModalContentClass("p-4")
    setModalComponent(() => (
      <div>
        <div>
          <h2 className="text-center"> DELETE USER </h2>
          Are You sure to delete user ?
        </div>
        <div className="d-flex gap-2 mt-4">
          <Button
            className={"delete-button"}
            text={"SUBMIT"}
            onClick={() => deleteUser(id)}
          />
          <Button
            className={"delete-button"}
            text={"CANCEL"}
            onClick={() => setModalVisibility(false)}
          />
        </div>
      </div>
    ))
    setModalVisibility(true)
  }

  const openAddCarrierPopup = (editMode = false, user = {}) => {
    setModalContentClass("p-5")
    setModalComponent(() => <AddCarrier isEditMode={editMode} user={user} />)
    setModalVisibility(true)
  }

  const { data: carrierResponse, isFetching: carrierFetching } = useGetCarriers(
    page,
    searchTerm,
    size,
    {
      enabled: isServiceProvider && allowFetchCarrier
    }
  )

  const fetchForQuery = () => {
    if (!textLoading) setTextLoading(true)
    if (fetchTimeout) clearTimeout(fetchTimeout)
    setFetchTimeout(
      setTimeout(() => {
        setSearchTerm(query)
        setTextLoading(false)
      }, 1000)
    )
  }

  useEffect(() => {
    if (query.length == "") {
      if (isServiceProvider) setAllowFetchCarrier(true)
      if (isOwner) setAllowFetchUsers(true)
    }
    fetchForQuery()
  }, [query])

  const getCarrierProfileCell = cell => (
    <div className="d-flex table-profile-cell align-items-center ">
      <span className="header-avatar-container">
        <img src={assets.avatar} alt="avatar" />
      </span>
      <div className="d-flex flex-column align-items-start justify-content-center ms-3">
        <span className="profile-name">{`${cell.first_name} ${cell.last_name}`}</span>
        <span className="profile-email">{cell.email}</span>
      </div>
    </div>
  )
  const getUserProfileCell = cell => (
    <div
      className="d-flex table-profile-cell align-items-center pointer"
      onClick={() => nav(`/dashboard/user/${cell.id}`)}
    >
      <span className="header-avatar-container">
        <img src={assets.avatar} alt="avatar" />
      </span>
      <div className="d-flex flex-column align-items-start justify-content-center ms-3">
        <span className="profile-name">{`${cell.name}`}</span>
        <span className="profile-email">{cell.email}</span>
      </div>
    </div>
  )

  const deleteUser = id => {
    deleteUserMutation.mutate(id, {
      onSuccess: res => {
        alert("User Deleted !")
        setModalVisibility(false)
        refetch()
      },
      onError: err => {
        console.log(err)
      }
    })
  }
  const getActionCell = cell => (
    <div className="d-flex mx-auto table-actions-container justify-content-between p-1 px-3">
      <span className="action-cell">
        <img src={assets.maskIcon} alt="mask" />
      </span>
      <span
        onClick={() =>
          isServiceProvider
            ? openAddCarrierPopup(true, cell)
            : openAddUserPopup(true, cell)
        }
        className="action-cell"
      >
        <svgIcons.EditIcon />
      </span>
      <span
        onClick={() => openDeleteUserPopup(cell.id)}
        className="action-cell"
      >
        <svgIcons.DeleteIcon />
      </span>
    </div>
  )

  const getCategoryHeader = () => (
    <Dropdown
      className="table-header-dropdown border-0 no-hover-highlight d-flex justify-content-center"
      menu={[
        ...Object.keys(UserTypes).map(user => ({
          name: getUserTypeStringFromEnum(UserTypes[user]),
          value: UserTypes[user]
        }))
      ]}
      onChange={e => {
        setSelectedUser(e)
        setPage(1)
      }}
      selectedOption={selectedUser}
      placeholder="User Type"
      showNullOption={true}
    />
  )
  const getSortingHeader = (fieldLabel, fieldName) => {
    if (sorting && sorting.field === fieldName) {
      if (sorting.order === "ASC") {
        return (
          <div className="pointer" onClick={() => updateSorting(fieldName)}>
            {fieldLabel}
            <span className="ms-2">&#x2191;</span>
          </div>
        )
      }
      return (
        <div className="pointer" onClick={() => updateSorting(fieldName)}>
          {fieldLabel}
          <span className="ms-2">&#x2193;</span>
        </div>
      )
    }
    return (
      <div className="pointer" onClick={() => updateSorting(fieldName)}>
        {fieldLabel}
      </div>
    )
  }
  const columns = React.useMemo(() => {
    if (isServiceProvider)
      return [
        {
          Header: "Carrier Full Name",
          styles: { textAlign: "left", paddingLeft: "42px" },
          accessor: getCarrierProfileCell
        },
        {
          Header: "ID Number",
          accessor: "id_number"
        },
        {
          Header: "Mobile Number",
          accessor: "phone"
        },
        {
          Header: "Action",
          accessor: getActionCell
        }
      ]
    if (isOwner)
      return [
        {
          Header: getSortingHeader("Name", "first_name"),
          id: "first_name",
          accessor: getUserProfileCell
        },
        {
          Header: "ID Number",
          accessor: cell => cell?.userprofile?.id_number
        },
        {
          id: "category",
          Header: getCategoryHeader,
          accessor: cell => getUserTypeStringFromEnum(cell.user_type),
          styles: {
            minWidth: "230px",
            textAlign: "center",
            justifyContent: "center",
            display: "flex",
            minHeight: "65px"
          }
        },
        {
          Header: getSortingHeader("Mobile Number", "phone"),
          id: "phone",
          accessor: "mobile_number"
        },
        {
          Header: "Action",
          accessor: getActionCell
        }
      ]
  }, [selectedUser, sorting])

  const carrierResponseData = carrierResponse?.data?.data || {}
  const { results: carrierData } = carrierResponseData

  const usersResponseData = usersResponse?.data || {}
  const { results: usersData } = usersResponseData

  const { count, previous, next } =
    (isOwner ? usersResponseData : carrierResponseData) || {}

  const onNext = () => {
    setPage(prev => (next ? prev + 1 : prev))
  }
  const onPrev = () => {
    setPage(prev => (previous ? prev - 1 : prev))
  }

  const isLoading = carrierFetching || usersFetching

  return (
    <div className="home-screen">
      <h2 className="section-heading">Home</h2>
      <div className="d-flex chart-container">
        <div className="chart-wrap d-flex flex-column w-50 white-bg">
          {isServiceProvider ? (
            <DeliveryTimeChart
              title={`Delivery Time`}
              description={`by Categ. (Bldg.# - DATE)`}
              colorIndexPalleteClassName={`mt-2 py-5`}
            />
          ) : (
            <WaitingTimeChart />
          )}
        </div>
        <div className="chart-wrap d-flex flex-column w-50 white-bg">
          {isServiceProvider ? (
            <ServiceProviderDeliveryCompetetion />
          ) : (
            <AccessAreaChart />
          )}
        </div>
      </div>

      <h2 className="section-heading">
        {isServiceProvider ? "Carriers" : "Users"}
      </h2>
      <div className="d-flex align-items-center">
        <InputGroup className="my-3 w-50">
          <FormControl
            style={{ borderRight: 0 }}
            onChange={e => setQuery(e.target.value)}
            placeholder={isServiceProvider ? "Search Carriers" : "Search Users"}
            className="dashboard-header-search w-50"
          />
          {textLoading && (
            <InputGroup.Text style={{ borderLeft: 0 }} className="white-bg">
              <Spinner variant="warning" animation="grow" size="sm" />
            </InputGroup.Text>
          )}
        </InputGroup>

        <button className="page-btn ms-auto px-2">
          <img src={assets.downloadIcon} alt="download" />
        </button>
        {/* <button className="page-btn d-flex align-items-center mx-3 px-3">
          <img src={assets.sortIcon} alt="download" />
          <img src={assets.dropdownArrow} alt="" />
        </button> */}
        {isOwner ? (
          <button
            onClick={() => openAddUserPopup()}
            className="page-btn mx-2 px-2"
          >
            Add User
          </button>
        ) : (
          <button
            onClick={() => openAddCarrierPopup()}
            className="page-btn mx-2 px-2"
          >
            Add Carrier
          </button>
        )}
      </div>
      <Table
        data={(isServiceProvider ? carrierData : usersData) || []}
        columns={columns}
        count={count}
        page={page}
        onNext={onNext}
        onPrev={onPrev}
        pageSize={size}
        isLoading={isLoading}
      />
    </div>
  )
}

export default Home
