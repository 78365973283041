import { APPCONFIG } from "../config"

export const getMapImageUri = (lat, lon) => {
  const markerColor = "#000"
  const url = `https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/pin-l+${markerColor.replace(
    "#",
    ""
  )}(${lon},${lat})/${lon},${lat},13/500x800?access_token=${
    APPCONFIG.mapBoxKey
  }`
  return url
}
