import React from "react"
import { useQuery } from "react-query"
import { getUserEnum, webApiHandler } from "../utils"

export const useGetCarriers = (page, q, size = 10, config = {}) => {
  return useQuery(
    ["carriers", page, size, q],
    () => webApiHandler.getCarriers(page, q, size),
    {
      keepPreviousData: true,
      ...config
    }
  )
}

export const useGetUsers = (page, search, size = 10, params, config = {}) => {
  return useQuery(
    ["users", page, size, search, params],
    () => webApiHandler.getUsers(page, search, size, params),
    {
      keepPreviousData: true,
      ...config
    }
  )
}

export const useGetAdminUsers = (
  page,
  search,
  size = 10,
  params,
  config = {}
) => {
  return useQuery(
    ["adminusers", page, size, search, params],
    () => webApiHandler.getAdminUsers(page, search, size, params),
    {
      keepPreviousData: true,
      ...config
    }
  )
}
export const useGetAdminUserDetail = ({ id }, config = {}) => {
  return useQuery(
    ["adminuserdetail", id],
    () => webApiHandler.getAdminUserDetail(id),
    { ...config }
  )
}

export const useGetCategories = (config = {}) => {
  return useQuery(["categories"], () => webApiHandler.getCategories(), config)
}

export const useGetSubCategories = (id, config = {}) => {
  return useQuery(
    ["subCategories", id],
    () => webApiHandler.getSubCategories(id),
    {
      keepPreviousData: true,
      ...config
    }
  )
}

export const useGetAllBuildings = config => {
  return useQuery(["buildings"], () => webApiHandler.getAllBuildings(), config)
}

export const useGetElevatorGraph = ({ params, enabled, config = {} }) => {
  return useQuery(
    ["elevator", params],
    () => webApiHandler.getElevatorGraph(params),
    {
      enabled,
      ...config
    }
  )
}

export const useGetUser = ({ id }, config = {}) => {
  return useQuery(["user", id], () => webApiHandler.getUser(id), { ...config })
}
export const useGetApproveUser = (page, q, size = 10, params, config = {}) => {
  return useQuery(
    ["approve", page, size, q, params],
    () => webApiHandler.getApproveUser(page, q, size, params),
    {
      ...config
    }
  )
}

export const useGetFeedbackListings = (params = {}, config = {}) => {
  return useQuery(
    ["feedback", getUserEnum(), params],
    () => webApiHandler.getFeedbackListing(),
    { keepPreviousData: true, ...config }
  )
}

export const useGetWaitingTime = ({ params, enabled, config = {} }) => {
  return useQuery(
    ["waiting-time", params.date, params["report-type"]],
    () => webApiHandler.getWaitingTime(params),
    {
      enabled,
      ...config
    }
  )
}

export const useGetReviews = (page, q, size = 10, params, config = {}) => {
  return useQuery(
    ["reviews", page, size, q, params],
    () => webApiHandler.getReviews(page, q, size, params),
    {
      keepPreviousData: true,
      ...config
    }
  )
}

export const useGetCarSlots = (config = {}) => {
  return useQuery(["slots"], () => webApiHandler.getSlots(), {
    keepPreviousData: true,
    ...config
  })
}

export const useGetOwnerList = (page, q, size = 10, params, config = {}) => {
  return useQuery(
    ["ownerlist", page, size, q, params],
    () => webApiHandler.getOwnerList(page, q, size, params),
    {
      keepPreviousData: true,
      ...config
    }
  )
}
