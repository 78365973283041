import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import {
  FormControl,
  InputGroup,
  Dropdown,
  Button,
  Row,
  Col,
  Container
} from "react-bootstrap"
import { Table } from "../../../../molecules"
import { useGetUsers } from "../../../../queries-and-mutations/queries"
import {
  assets,
  getUserTypeStringFromEnum,
  openBase64Image,
  UserEnum,
  UserTypes
} from "../../../../utils"
import "./user.scss"
import { useModalAPIs } from "../../../../containers/popup-cotainer/modal-apis"
import { EditUserScreen } from "../home/add-user"
import { useApproveUserMutation, useSendAppLinkOwner } from "../../../../queries-and-mutations/mutation"
import { useEffect } from "react"
import { Dropdown as CustomDropdown } from "../../../../atoms"
import { useQueryClient } from "react-query"

function Users() {
  const nav = useNavigate()
  const [query, setQuery] = React.useState("")
  const [page, setPage] = React.useState(1)
  const [selectedUser, setSelectedUser] = React.useState(null)
  const approveUserCall = useApproveUserMutation()
  const sendAppLinkOwner = useSendAppLinkOwner()
  const [sorting, setSorting] = useState(null)
  const [ordering, setOrdering] = useState()
  const queryClient = useQueryClient()

  const updateSorting = fieldName => {
    if (sorting && sorting.field === fieldName) {
      if (sorting.order === "ASC") {
        setSorting({ ...sorting, order: "DESC" })
      } else {
        setSorting(null)
      }
    } else {
      setSorting({ field: fieldName, order: "ASC" })
    }
  }

  useEffect(() => {
    if (sorting) {
      if (sorting.order === "ASC") {
        setOrdering(sorting.field)
      } else {
        setOrdering(`-${sorting.field}`)
      }
    } else {
      setOrdering(null)
    }
  }, [CustomDropdown, sorting])

  const getCategoryHeader = () => (
    <CustomDropdown
      className="table-header-dropdown border-0 no-hover-highlight d-flex justify-content-center"
      menu={[
        ...Object.keys(UserTypes).map(user => ({
          name: getUserTypeStringFromEnum(UserTypes[user]),
          value: UserTypes[user]
        }))
      ]}
      onChange={e => {
        setSelectedUser(e)
        setPage(1)
      }}
      selectedOption={selectedUser}
      placeholder="User Type"
      showNullOption={true}
    />
  )
  const { data, isFetching } = useGetUsers(
    page,
    query,
    10, //page size
    { ordering, user_type: selectedUser?.value },
    {
      enabled: true //UserEnum == UserTypes.owner
    }
  )
  const { results: usersData, count, previous, next } = data?.data || {}

  const onNext = () => {
    setPage(prev => (next ? prev + 1 : prev))
  }
  const onPrev = () => {
    setPage(prev => (previous ? prev - 1 : prev))
  }

  const approveUser = (user, approveStatus) => {
    approveUserCall.mutate(
      { id: user.id, is_approved: approveStatus },
      {
        onSuccess: res => {
          console.log("user approve status", res)
        },
        onError: err => {}
      }
    )
  }
  const {
    setModalComponent,
    setModalVisibility,
    setModalContentClass
  } = useModalAPIs()
  const getUserProfileCell = cell => (
    <div
      className="d-flex table-profile-cell align-items-center pointer"
      // onClick={() => nav(`/dashboard/users/${cell.id}`)}
    >
      <span className="header-avatar-container">
        <img src={assets.avatar} alt="avatar" />
      </span>
      <div className="d-flex flex-column align-items-start justify-content-center ms-3">
        <span className="profile-name">{`${cell.name}`}</span>
        <span className="profile-email">{cell.email}</span>
      </div>
    </div>
  )
  const getSortingHeader = (fieldLabel, fieldName) => {
    if (sorting && sorting.field === fieldName) {
      if (sorting.order === "ASC") {
        return (
          <div className="pointer" onClick={() => updateSorting(fieldName)}>
            {fieldLabel}
            <span className="ms-2">&#x2191;</span>
          </div>
        )
      }
      return (
        <div className="pointer" onClick={() => updateSorting(fieldName)}>
          {fieldLabel}
          <span className="ms-2">&#x2193;</span>
        </div>
      )
    }
    return (
      <div className="pointer" onClick={() => updateSorting(fieldName)}>
        {fieldLabel}
      </div>
    )
  }
  const sendUserActivationLink = (userId) => {
    sendAppLinkOwner.mutate({user_id:userId},{
      onSuccess:()=>{
        alert("Activation Link Sent !")
        queryClient.invalidateQueries(["users"])
      },
      onError:()=>{
        alert("Error Sending Activation Link")
      }
    })
  }
  const columns = React.useMemo(
    () => [
      {
        Header: () => getSortingHeader("User Full Name", "first_name"),
        id: "first_name",
        styles: { textAlign: "left", paddingLeft: "42px" },
        accessor: getUserProfileCell
      },
      {
        Header: "ID Number",
        accessor: cell => cell?.userprofile?.id_number
      },
      {
        id: "category",
        Header: getCategoryHeader,
        accessor: cell => getUserTypeStringFromEnum(cell.user_type),
        styles: {
          minWidth: "230px",
          textAlign: "center",
          justifyContent: "center",
          display: "flex",
          minHeight: "65px"
        }
      },
      {
        Header: "Category",
        accessor: cell => cell?.userprofile?.category
      },
      {
        Header: "Company",
        accessor: cell => cell?.userprofile?.company
      },
      // {
      //   Header: "Mobile Number",
      //   accessor: cell => cell?.mobile_number
      // },
      {
        Header: "Documents",
        accessor: cell =>
          cell.userdocument ? (
            <div className="d-flex gap-2">
              {cell.userdocument.front_image && (
                <a
                  href="#"
                  onClick={() => openBase64Image(cell.userdocument.front_image)}
                  target="_blank"
                >
                  <img
                    src={cell.userdocument.front_image}
                    alt="front_id"
                    width="50px"
                    height="50px"
                  />
                </a>
              )}
              {cell.userdocument.back_image && (
                <a
                  href="#"
                  onClick={() => openBase64Image(cell.userdocument.back_image)}
                  target="_blank"
                >
                  <img
                    src={cell.userdocument.back_image}
                    alt="front_id"
                    width="50px"
                    height="50px"
                  />
                </a>
              )}
            </div>
          ) : (
            ""
          )
      },
      {
        Header: "Action",
        accessor: cell => (
          <div>
            <Dropdown className="order-dropdown">
              <Dropdown.Toggle variant="link" id="dropdown-basic">
                ...
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href={`/dashboard/users/${cell.id}`}>
                  View User
                </Dropdown.Item>
                <Dropdown.Item onClick={() => openAddUserPopup(true, cell)}>
                  Edit User
                </Dropdown.Item>
                {cell.last_login ? "" :<Dropdown.Item onClick={()=>sendUserActivationLink(cell.id)}>
                  Send Activation Link
                </Dropdown.Item>}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )
      }
    ],
    [sorting]
  )
  const openAddUserPopup = (editMode = false, user) => {
    setModalContentClass("p-5")
    setModalComponent(() => (
      <EditUserScreen isEditMode={editMode} user={user} />
    ))
    setModalVisibility(true)
  }
  return (
    <div>
      <div className="d-flex justify-content-between">
        <div className="section-heading">
          <h2>{"Users"}</h2>
        </div>
        <div>
          <Button onClick={() => openAddUserPopup()} variant="custom">
            + Add New User
          </Button>
        </div>
      </div>
      <div className="d-flex align-items-center">
        <InputGroup className="my-3 w-50">
          <FormControl
            style={{ borderRight: 0 }}
            onChange={e => setQuery(e.target.value)}
            placeholder={"Search Users"}
            className="dashboard-header-search w-50"
          />
        </InputGroup>
        <button className="page-btn ms-auto px-2">
          <img src={assets.downloadIcon} alt="download" />
        </button>
        <button className="page-btn d-flex align-items-center mx-3 px-3">
          <img src={assets.sortIcon} alt="download" />
          <span className="ms-1 me-1">Filter</span>
          <img src={assets.dropdownArrow} alt="" />
        </button>
      </div>
      <Table
        data={usersData || []}
        columns={columns}
        count={count}
        page={page}
        onNext={onNext}
        onPrev={onPrev}
        pageSize={10}
        isLoading={isFetching}
      />
    </div>
  )
}

export default Users
