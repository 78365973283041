import React, { useState } from "react"
import ChartHeader from "../../../../charts/chart-header"
import StackedBarChart from "../../../../charts/stacked-bar-chart"

const DeliveryFrequencyChart = () => {
  const colors = {
    ["Food & Grocery"]: "#3F4589",
    ["Cleaning Co."]: "#5983B2",
    ["Courier Co. (incl.AMZN)"]: "#6593C6",
    ["Laundry Co."]: "#84A8D6",
    ["Guests"]: "#A9BFE0",
    ["Mainten. (incl.ETSL)"]: "#C7D5E9"
  }
  const data = [
    {
      key: "10am",
      ["Food & Grocery"]: 100,
      ["Cleaning Co."]: 120,
      ["Courier Co. (incl.AMZN)"]: 70,
      ["Laundry Co."]: 100,
      ["Guests"]: 105,
      ["Mainten. (incl.ETSL)"]: 75
    },
    {
      key: "11am",
      ["Food & Grocery"]: 85,
      ["Cleaning Co."]: 75,
      ["Courier Co. (incl.AMZN)"]: 110,
      ["Laundry Co."]: 105,
      ["Guests"]: 95,
      ["Mainten. (incl.ETSL)"]: 90
    },
    {
      key: "12pm",
      ["Food & Grocery"]: 100,
      ["Cleaning Co."]: 90,
      ["Courier Co. (incl.AMZN)"]: 75,
      ["Laundry Co."]: 85,
      ["Guests"]: 85,
      ["Mainten. (incl.ETSL)"]: 115
    },
    {
      key: "1pm",
      ["Food & Grocery"]: 100,
      ["Cleaning Co."]: 120,
      ["Courier Co. (incl.AMZN)"]: 70,
      ["Laundry Co."]: 100,
      ["Guests"]: 60,
      ["Mainten. (incl.ETSL)"]: 95
    },
    {
      key: "2pm",
      ["Food & Grocery"]: 85,
      ["Cleaning Co."]: 75,
      ["Courier Co. (incl.AMZN)"]: 110,
      ["Laundry Co."]: 105,
      ["Guests"]: 115,
      ["Mainten. (incl.ETSL)"]: 80
    },
    {
      key: "3pm",
      ["Food & Grocery"]: 100,
      ["Cleaning Co."]: 90,
      ["Courier Co. (incl.AMZN)"]: 75,
      ["Laundry Co."]: 85,
      ["Guests"]: 65,
      ["Mainten. (incl.ETSL)"]: 70
    }
  ]
  const keys = [
    "Food & Grocery",
    "Cleaning Co.",
    "Courier Co. (incl.AMZN)",
    "Laundry Co.",
    "Guests",
    "Mainten. (incl.ETSL)"
  ]

  const filters = [
    {
      name: "Time",
      value: "time"
    },
    {
      name: "Category",
      value: "category"
    }
  ]
  return (
    <div className="white-bg chart-wrap p-3">
      <ChartHeader
        title="Delivery frequency"
        subtitle="Lorem ipsum dolor sit amet consectetur adipisicing elit."
        dateFilter={true}
        filters={filters}
      />
      <div className="text-center position-relative">
        <StackedBarChart
          colors={colors}
          data={data}
          keys={keys}
          id={"delivery-frequency-stacked-chart"}
        />
      </div>
    </div>
  )
}

export default DeliveryFrequencyChart
