import React, { useEffect, useState } from "react"
import { Dropdown, Form, Nav } from "react-bootstrap"

import { assets, getUserEnum, UserTypes } from "../../../../utils"

import { Loader } from "../../../../atoms"
import ChartArea from "../../../../molecules/chart-sections/chart-area"

import { useGetAllBuildings } from "../../../../queries-and-mutations/queries"

import "./services.scss"

function ScreenDashServicesElevatorStatus(props) {
  const userEnum = getUserEnum()
  const isServiceProvider = userEnum === UserTypes.serviceProvider
  const isOwner = userEnum === UserTypes.owner

  const elevatorTabs = [
    {
      slug: "time",
      title: "Average wait time"
    },
    {
      slug: "stops",
      title: "# Stops"
    },
    {
      slug: "calls",
      title: "# Calls"
    }
  ]

  const [activeBuilding, setActiveBuilding] = useState(null)
  const [activeElevator, setActiveElevator] = useState(null)
  const [activeElevatorTab, setActiveElevatorTab] = useState(elevatorTabs[0])

  const {
    data: dataBuildings,
    isFetching: isFetchingBuildings
  } = useGetAllBuildings({})

  useEffect(() => {
    if (!!dataBuildings) {
      setActiveBuilding(
        dataBuildings?.data?.results.find(bld => bld?.elevator?.length > 0) ||
          dataBuildings?.data?.results[0]
      )
    }
  }, [dataBuildings])

  useEffect(() => {
    if (!!activeBuilding) {
      setActiveElevator(activeBuilding?.elevator[0] || null)
    }
  }, [activeBuilding])

  if (isFetchingBuildings || activeBuilding === null)
    return <Loader className="h-100 d-flex flex-column align-items-center" />

  return (
    <div className="primary-tab-content px-md-3 px-lg-3" id="elevator-status">
      <div className="d-flex align-items-center justify-content-between mb-4">
        <h3 className="mb-0">Current Capacity</h3>
        <div className="building-label d-flex">
          <div
            className="icon icon-19 me-3"
            style={{ backgroundImage: `url(${assets.buildingIcon})` }}
          />
          {activeBuilding?.name}
        </div>
      </div>

      <Form.Group className="form-group my-3">
        <Form.Label>Elevators</Form.Label>
        <Dropdown className="dv-dropdown w-100">
          <Dropdown.Toggle
            variant="secondary"
            id="dropdown-basic"
            className="d-flex align-items-center justify-content-between w-100"
          >
            {activeElevator?.title}
          </Dropdown.Toggle>

          <Dropdown.Menu className="w-100">
            {activeBuilding?.elevator.map((elevator, i) => (
              <Dropdown.Item
                key={i}
                onClick={() => {
                  setActiveElevator(
                    activeBuilding?.elevator.find(
                      elev => elev?.kone_id === elevator?.kone_id
                    )
                  )
                }}
              >
                {elevator?.title}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </Form.Group>

      <div className="d-flex jusify-content-center">
        <Nav className="sub-tabs pills d-inline-flex mx-auto mb-3 mt-5">
          {elevatorTabs.map((tab, i) => (
            <Nav.Item
              className={`pill${
                tab.slug === activeElevatorTab?.slug ? " active" : ""
              }`}
              title={tab.title}
              key={i}
            >
              <Nav.Link
                onClick={() => {
                  setActiveElevatorTab(tab)
                }}
              >
                {tab.title}
              </Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
      </div>

      {activeElevator && activeElevatorTab ? (
        <div>
          <ChartArea
            chartFor="elevator"
            chartOptions={{
              elevator_kone_id: activeElevator?.kone_id,
              report_type: activeElevatorTab?.slug
            }}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  )
}

export default ScreenDashServicesElevatorStatus
