import React from "react"
import "./centered-container.scss"

const CenteredContainer = ({
  children,
  containerClass = "",
  parentContainerClass = ""
}) => {
  return (
    <div
      className={`d-flex w-100 justify-content-center position-relative centered-container ${parentContainerClass}`}
    >
      <div className={`${containerClass} centered-content`}>{children}</div>
    </div>
  )
}

export default CenteredContainer
