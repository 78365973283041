import React, { useEffect, useRef, useState, useMemo, useCallback } from "react"
import { useQueryClient } from "react-query"
import GenericForm from "../../../../containers/form-composer/generic-form"
import { useModalAPIs } from "../../../../containers/popup-cotainer/modal-apis"
import {
  useCreateUserMutation,
  useEditUserMutation
} from "../../../../queries-and-mutations/mutation"
import {
  useGetAllBuildings,
  useGetCarSlots,
  useGetCategories,
  useGetSubCategories
} from "../../../../queries-and-mutations/queries"
import {
  extractValuesFromForm,
  resolveKeyPath,
  UserTypes,
  validation
} from "../../../../utils"

export const EditUserScreen = ({ user, isEditMode }) => {
  const isSubTenant = user?.user_profile?.is_subtenant || false
  const formref = useRef()
  const { setModalVisibility } = useModalAPIs()
  const [renderedFields, setRenderedFields] = useState([])
  const [selectedUser, setSelectedUser] = useState(null)
  const [selectedCat, setSelectedCat] = useState(null)
  const [allowSubmit, setBlockSubmit] = useState(false)
  const { data: categoryResponse } = useGetCategories({})
  const {
    data: subCategoryResponse,
    isFetching: subCatLoading
  } = useGetSubCategories(selectedCat?.id, {
    enabled: selectedCat?.id ? true : false
  })

  const {
    data: buildingResponse,
    isFetching: buildingLoading
  } = useGetAllBuildings({
    enabled: selectedUser?.enum == UserTypes.tenant
  })

  const { data: carSlotResponse, isFetching: carSlotLoading } = useGetCarSlots({
    enabled: selectedUser?.enum == UserTypes.tenant
  })

  const createUserMutation = useCreateUserMutation()
  const editUserMutation = useEditUserMutation()
  const queryClient = useQueryClient()

  const categories =
    categoryResponse?.data?.data?.filter(e =>
      selectedUser ? e?.user_type == selectedUser?.enum : false
    ) || []

  const subCategories = subCategoryResponse?.data?.data || []

  const buildings = buildingResponse?.data?.results || []
  const carSlots = carSlotResponse?.data?.results || []

  const commonKeys = [
    "full_name",
    "phone",
    "email",
    "car_park_spot",
    ["user_type", "user_type.enum"]
  ]

  const catKeys = [
    ["category", "category.id"],
    ["user_subcategory", "sub_category.id"]
  ]

  const addressKeys = [
    "user_address",
    ["user_address.building", "user_address.building.id"],
    "car_detail",
    ["car_detail.parking_slot", "car_detail.parking_slot.id"]
  ]

  const carrierKeys = ["driving_licence", "id_number"]

  const userTypeMenu = useMemo(
    () => [
      { name: "Service Provider", enum: UserTypes.serviceProvider },
      { name: "Tenant", enum: UserTypes.tenant },
      { name: "Carrier", enum: UserTypes.carrier },
      { name: "Owner", enum: UserTypes.owner }
    ],
    []
  )

  const generateDefaultValues = useCallback(() => {
    if (!isEditMode) return null
    let defaultVals = { ...user }
    defaultVals.user_type = userTypeMenu.find(e => e.enum === user.user_type)
    defaultVals.category = user?.userprofile?.category
    defaultVals.sub_category = user?.userprofile?.user_subcategory
    defaultVals.full_name = `${user?.first_name} ${user?.last_name}`
    defaultVals.phone = user?.mobile_number.replace(/ /g, "")
    defaultVals.id_number = user?.userprofile?.id_number
    defaultVals.car_detail.parking_slot = user?.car_detail?.[0]?.parking_slot
    defaultVals.car_detail.plate_number = user?.car_detail?.[0]?.plate_number
    defaultVals.driving_licence = user?.userprofile?.driving_licence
    if (user?.user_address) {
      defaultVals.user_address = user?.user_address
      defaultVals.user_address.building =
        buildings.find(e => e?.id === user?.user_address.building) ||
        defaultVals.user_address.building
    }
    return defaultVals
  }, [buildings?.[0]?.id])

  useEffect(() => {
    if (isEditMode) setSelectedUser(generateDefaultValues()?.user_type)
  }, [])

  const formFields = [
    {
      label: "Full Name",
      name: "full_name",
      placeholder: "Enter Full Name",
      rules: {
        required: "Full Name required!",
        validate: { ...validation.name }
      }
    },
    {
      label: "Email",
      name: "email",
      placeholder: "Enter Email",
      rules: {
        required: "Email required!",
        validate: { ...validation.email }
      },
      className: "half"
    },
    {
      label: "Mobile Number",
      name: "phone",
      placeholder: "Enter Contact Number",
      rules: {
        required: "Mobile Number required!",
        validate: { ...validation.internationalPhone }
      },
      className: "half"
    },
    {
      label: "User Type",
      name: "user_type",
      placeholder: "Select Usertype",
      rules: {
        required: "User Type required"
      },
      compType: "dropdown",
      menu: isEditMode
        ? userTypeMenu
        : userTypeMenu.filter(v => v.enum === 1 || v.enum === 3),
      onChange: user => {
        if (user && user?.enum !== selectedUser?.enum) {
          setSelectedUser(user)
        }
      },
      disabled: isEditMode
    }
  ].map(e => ({
    ...e,
    defaultValue: resolveKeyPath(generateDefaultValues(), e.name)
  }))

  const categoryFields = [
    {
      label: "Category",
      name: "category",
      placeholder: "Select Category",
      compType: "dropdown",
      menu: categories,
      className: `half`,
      onChange: cat => {
        if (cat && cat?.id !== selectedCat?.id) {
          formref.current && formref.current.setValue("sub_category", null)
          setSelectedCat(cat)
        }
      },
      rules: { required: "required" }
    },
    {
      label: "Sub-category",
      name: "sub_category",
      placeholder: "Select Sub-category",
      compType: "dropdown",
      menu: subCatLoading ? [] : subCategories,
      className: `half`,
      rules: { required: "required" }
    }
  ].map(e => ({
    ...e,
    defaultValue: resolveKeyPath(generateDefaultValues(), e.name),
    disabled: isEditMode
  }))

  const addressFields = [
    {
      label: "Car Slot",
      name: "car_detail.parking_slot",
      placeholder: isEditMode
        ? user.car_detail.parking_slot
        : "Select Car Slot",
      compType: "dropdown",
      menu: carSlotLoading ? [] : carSlots,
      className: `half`,
      labelKey: "title",
      rules: isSubTenant ? {} : { required: "required" }
    },
    {
      label: "Plate Number",
      name: "car_detail.plate_number",
      placeholder: "Enter Plate Number",
      className: `half`,
      rules: isSubTenant ? {} : { required: "required" }
    },
    {
      label: "Floor",
      name: "user_address.floor_number",
      placeholder: "Enter Floor",
      className: `half`,
      rules: isSubTenant ? {} : { required: "required" }
    },
    {
      label: "Unit Number",
      name: "user_address.unit_number",
      placeholder: "Enter Unit Number",
      className: `half`,
      rules: isSubTenant ? {} : { required: "required" }
    },
    {
      label: "Building",
      name: "user_address.building",
      placeholder: "Select Building",
      compType: "dropdown",
      menu: buildingLoading ? [] : buildings,
      rules: isSubTenant ? {} : { required: "required" }
    }
  ].map(e => ({
    ...e,
    defaultValue: resolveKeyPath(generateDefaultValues(), e.name),
    disabled: isSubTenant
  }))

  const carrierFields = [
    {
      label: "Driver's Licence",
      name: "driving_licence",
      placeholder: "Enter Driver's License",
      className: `half`,
      rules: { required: "required" }
    },
    {
      label: "ID Number",
      name: "id_number",
      placeholder: "Enter ID Number",
      className: `half`,
      rules: { required: "required" }
    }
  ].map(e => ({
    ...e,
    defaultValue: resolveKeyPath(generateDefaultValues(), e.name)
  }))

  const getFinalFormFields = () => {
    if (!selectedUser) return formFields
    if (selectedUser.enum == UserTypes.carrier)
      return formFields.concat(carrierFields)
    if (selectedUser.enum == UserTypes.tenant)
      return formFields.concat(addressFields)
    else return formFields.concat(categoryFields)
  }

  useEffect(() => {
    formref.current &&
      formref.current.setValue(
        "user_address.building",
        resolveKeyPath(generateDefaultValues(), "user_address.building")
      )
  }, [buildings])

  useEffect(() => {
    if (isEditMode) {
      if (selectedUser) {
        setRenderedFields(getFinalFormFields())
      }
    } else {
      setRenderedFields(getFinalFormFields())
    }
  }, [selectedUser, selectedCat, subCategories?.[0]?.id, buildings?.[0]?.id])

  useEffect(() => {
    if (isEditMode) {
      categoryFields.forEach(field => {
        const selectedValue = field.menu?.filter?.(
          e => e.name === generateDefaultValues()[field.name]
        )?.[0]
        formref.current && formref.current.setValue(field.name, selectedValue)
        field?.onChange?.(selectedValue)
      })
    }
  }, [categories, subCategories])

  const onSubmit = vals => {
    setBlockSubmit(true)
    const commonVals = extractValuesFromForm(commonKeys, vals)
    const [first_name, ...rest] = commonVals.full_name.split(" ")
    const last_name = rest.join(" ")
    let userTypeBasedVals = {}
    switch (selectedUser.enum) {
      case UserTypes.carrier:
        userTypeBasedVals = {
          userprofile: extractValuesFromForm(carrierKeys, vals)
        }
        break

      case UserTypes.serviceProvider:
      case UserTypes.owner:
        userTypeBasedVals = {
          userprofile: extractValuesFromForm(catKeys, vals)
        }
        break

      case UserTypes.tenant:
        userTypeBasedVals = extractValuesFromForm(addressKeys, vals)
        break
    }
    if (!isEditMode) {
      createUserMutation.mutate(
        { first_name, last_name, ...commonVals, ...userTypeBasedVals },
        {
          onSuccess: response => {
            alert("User Created !")
            setBlockSubmit(false)
            queryClient.invalidateQueries(["users"])
            setModalVisibility(false)
          },
          onError: err => {
            setBlockSubmit(false)
          }
        }
      )
    } else {
      let { car_detail } = extractValuesFromForm(addressKeys, vals)
      let editRequestObject = {
        id: user?.id,
        first_name,
        last_name,
        ...extractValuesFromForm(commonKeys, vals)
      }
      if (selectedUser.enum === UserTypes.carrier) {
        editRequestObject = {
          ...editRequestObject,
          userprofile: extractValuesFromForm(carrierKeys, vals)
        }
      } else if (
        selectedUser.enum === UserTypes.serviceProvider ||
        selectedUser.enum === UserTypes.owner
      ) {
        editRequestObject = {
          ...editRequestObject,
          userprofile: extractValuesFromForm(catKeys, vals)
        }
      } else {
        editRequestObject = {
          ...editRequestObject,
          ...extractValuesFromForm(addressKeys, vals)
        }
        if (user.car_detail && user.car_detail.length) {
          editRequestObject = {
            ...editRequestObject,
            car_detail: {
              ...user.car_detail[0],
              ...extractValuesFromForm(addressKeys, vals).car_detail
            }
          }
        }
      }

      editUserMutation.mutate(editRequestObject, {
        onSuccess: response => {
          alert("User Updated !")
          setBlockSubmit(false)
          queryClient.invalidateQueries(["users"])
          setModalVisibility(false)
        },
        onError: err => {
          setBlockSubmit(false)
        }
      })
    }
  }

  return (
    <>
      <h2 className="text-center">
        {isEditMode ? "Edit User" : "Add New User"}
      </h2>
      {renderedFields.length ? (
        <GenericForm
          ref={formref}
          submitButtonClass="mt-5"
          formFields={renderedFields}
          submitButtonText="Save"
          onSubmit={onSubmit}
          logging={false}
          submitDisabled={allowSubmit}
        />
      ) : (
        ""
      )}
    </>
  )
}
