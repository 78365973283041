import React, { useRef } from "react"
import { useQueryClient } from "react-query"
import GenericForm from "../../../../containers/form-composer/generic-form"
import { useModalAPIs } from "../../../../containers/popup-cotainer/modal-apis"
import {
  useCreateUserMutation,
  useEditUserMutation
} from "../../../../queries-and-mutations/mutation"
import { resolveKeyPath, UserTypes, validation } from "../../../../utils"

function AddCarrier({ isEditMode = false, user = {} }) {
  const formref = useRef()
  const queryClient = useQueryClient()
  const { setModalVisibility } = useModalAPIs()
  const createUserMutation = useCreateUserMutation()
  const editUserMutation = useEditUserMutation()
  user.full_name = isEditMode ? `${user?.first_name} ${user?.last_name}` : ""

  const formFields = [
    {
      label: "Full Name",
      name: "full_name",
      placeholder: "Enter Full Name",

      rules: {
        required: "Full Name required!",
        validate: { ...validation.name }
      }
    },
    {
      label: "Email",
      name: "email",
      placeholder: "Enter Email",
      loadValuePath: "email",
      rules: {
        required: "Email required!",
        validate: { ...validation.email }
      },
      className: "half"
    },
    {
      label: "Mobile Number",
      name: "phone",
      loadValuePath: "phone",
      placeholder: "Enter Contact Number",
      rules: {
        required: "Mobile Number required!",
        validate: { ...validation.internationalPhone }
      },
      className: "half"
    },
    {
      label: "Driver's Licence",
      name: "driving_licence",
      placeholder: "Enter Driver's License",
      loadValuePath: "driving_licence",
      className: `half`,
      rules: { required: "required" }
    },
    {
      label: "ID Number",
      name: "id_number",
      loadValuePath: "id_number",
      placeholder: "Enter ID Number",
      className: `half`,
      rules: { required: "required" }
    }
  ].map(e => ({
    ...e,
    defaultValue: resolveKeyPath(user, e.loadValuePath || e.name)
  }))

  const onSubmit = vals => {
    const [first_name, ...rest] = vals.full_name.split(" ")
    const last_name = rest.join(" ")
    if (!isEditMode) {
      createUserMutation.mutate(
        {
          first_name,
          last_name,
          ...vals,
          userprofile: { ...vals },
          user_type: UserTypes.carrier
        },
        {
          onSuccess: response => {
            alert("User Created !")
            queryClient.invalidateQueries(["carriers"])
            setModalVisibility(false)
          }
        }
      )
    } else {
      editUserMutation.mutate(
        {
          id: user?.id,
          first_name,
          last_name,
          userprofile: {
            driving_licence: vals.driving_licence,
            id_number: vals.id_number
          },
          ...vals
        },
        {
          onSuccess: response => {
            alert("User Updated !")
            queryClient.invalidateQueries(["carriers"])
            setModalVisibility(false)
          }
        }
      )
    }
  }

  return (
    <>
      <h2 className="text-center">
        {isEditMode ? "Edit Carrier" : "Add New Carrier"}
      </h2>
      <GenericForm
        ref={formref}
        submitButtonClass="mt-5"
        formFields={formFields}
        submitButtonText="Save"
        onSubmit={onSubmit}
        logging={false}
      />
    </>
  )
}

export default AddCarrier
