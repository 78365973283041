import React, { useEffect, useState } from "react"
import { Alert, Modal } from "react-bootstrap"

const ErrorAlert = () => {
  const [show, setShow] = useState(false)
  const [errorObject, setErrorObject] = useState()

  useEffect(() => {
    window.customErrorCallback = errorObject => {
      setShow(true)
      setErrorObject(errorObject)
    }
  }, [show, errorObject])

  useEffect(() => {
    // console.log(errorObject)
  }, [errorObject])

  return (
    <>
      {show && (
        <>
          <Modal
            show={show}
            onHide={() => setShow(false)}
            dialogClassName="error-alert-modal "
            aria-labelledby="example-custom-modal-styling-title"
            backdrop={true}
            backdropClassName="error-alert-backdrop"
          >
            <Alert
              variant={"danger"}
              onClose={() => setShow(false)}
              dismissible
              style={{ zIndex: 1000, left: 10, right: 10, top: 25 }}
              className={"position-absolute"}
            >
              <Alert.Heading>{"Error !"}</Alert.Heading>
              <p>{errorObject}</p>
            </Alert>
          </Modal>
        </>
      )}
    </>
  )
}

export default ErrorAlert
