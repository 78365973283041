import React from "react"
import { Navigate, useRoutes } from "react-router-dom"

import { SplashScreen } from "./pages/splash-screen"
import SelectUser from "./pages/select-user/select-user"
import Login from "./pages/login"
import Registration from "./pages/registration"

import PrivateRoute from "./containers/private-route"

import DashboardContainer from "./pages/dashboard/dashboard"
import { DashboardHome } from "./pages/dashboard/dashboard-children/home"
import AcceptAccess from "./pages/dashboard/dashboard-children/accept-access/accept-access"
import Analytics from "./pages/dashboard/dashboard-children/analytics/analytics"
import CarrierFeedbackListing from "./pages/dashboard/dashboard-children/carrier-feedback"
import CarrierListingPerformance from "./pages/dashboard/dashboard-children/carrier-performance"
import ScreenDashServices from "./pages/dashboard/dashboard-children/services/services"
import OrderReview from "./pages/dashboard/dashboard-children/order-review-screen/order-review"
import PrivacyPolicy from "./pages/dashboard/dashboard-children/privacy-policy"
import CheckID from "./pages/id-verification-test"
import PhotoUpload from "./pages/upload-photo"
import DocumentUpload from "./pages/upload-id"
import TermsAndConditions from "./pages/dashboard/dashboard-children/terms-and-conditions"
import ViewUser from "./pages/dashboard/dashboard-children/view-user/view-user"

import GenericContainer from "./pages/generic-container"
import ChartArea from "./molecules/chart-sections/chart-area"

import Settings from "./pages/settings"
import Order from "./pages/dashboard/dashboard-children/orders/order"
import OrderDetail from "./pages/dashboard/dashboard-children/orders/order-detail"
import Users from "./pages/dashboard/dashboard-children/users/users"
import UserDetail from "./pages/dashboard/dashboard-children/users/user-detail"
import WaitingTimeChart from "./pages/dashboard/dashboard-children/home/owner-charts/waiting-time-chart"
import AccessAreaChart from "./pages/dashboard/dashboard-children/home/owner-charts/access-area-chart"
import NoOfVisitorChart from "./pages/dashboard/dashboard-children/analytics/access-chart/no-of-visitor-chart"
import LiftPeakTimesLine from "./pages/dashboard/dashboard-children/analytics/lift-peak-times-line"
import PeakTime from "./pages/dashboard/dashboard-children/analytics/peak-times/peak-times"
import DeliveryTimesChart from "./pages/dashboard/dashboard-children/analytics/delivery-times"
import WaitTime from "./pages/dashboard/dashboard-children/analytics/top-deliveries/top-deliveries"
import DeliveryDurationChart from "./pages/dashboard/dashboard-children/analytics/delivery-duration"
import DeliveryFrequencyChart from "./pages/dashboard/dashboard-children/analytics/delivery-frequency"
import RequestAccess from "./pages/dashboard/dashboard-children/orders/request-access"
import UserRoute from "./containers/user-route"
import ApproveAccount from "./pages/dashboard/dashboard-children/approve-account/approve-account"
import AdminLogin from "./pages/admin/login"
import AdminUsers from "./pages/admin/user/users"
import AdminOwners from "./pages/admin/owners"
import AdminUserDetail from "./pages/admin/user/user-detail"
import AdminAnalytics from "./pages/admin/analytics/analytics"
import AdminDatabase from "./pages/admin/database/database"

export const ALLROUTES = {
  login: "/login",
  registration: "/registration",
  registrationUploadPhotoIdFront: "/upload-photoid-front",
  registrationUploadPhotoIdBack: "/upload-photoid-back",

  dashboard: {
    home: "/dashboard/home",
    analytics: "/dashboard/analytics",
    orderReview: "/dashboard/order-review",
    settings: "/dashboard/settings",
    carrierFeedback: "/dashboard/carrier-feedback",
    acceptAccess: "/dashboard/accept-access",
    orders: "/dashboard/orders",
    sendAccess: "/dashboard/send-access",
    approveAccount: "/dashboard/approve-account",
    users: "/dashboard/users",
    feedback: "/dashboard/feedback"
  }
}

const AppRoutes = () => {
  const element = useRoutes([
    { path: "/", element: <SplashScreen />, index: true },
    {
      path: "select-user-screen",
      element: <SelectUser />
    },
    {
      path: ALLROUTES.registration.replace(/\//g, ""),
      element: <UserRoute element={<Registration />} />
    },
    {
      path: ALLROUTES.login.replace(/\//g, ""),
      element: <UserRoute element={<Login />} />
    },
    {
      path: ALLROUTES.registrationUploadPhotoIdFront.replace(/\//g, ""),
      element: <UserRoute element={<PhotoUpload />} />
    },
    {
      path: ALLROUTES.registrationUploadPhotoIdBack.replace(/\//g, ""),
      element: <UserRoute element={<DocumentUpload />} />
    },
    {
      path: "/admin/login",
      element: <AdminLogin />
    },
    {
      path: ALLROUTES.dashboard.home.split("/")[1],
      element: <PrivateRoute element={<DashboardContainer />} />,
      children: [
        {
          element: <DashboardHome />,
          path: ALLROUTES.dashboard.home.split("/")[2],
          index: true
        },
        {
          element: <Analytics />,
          path: ALLROUTES.dashboard.analytics.split("/")[2]
        },
        {
          element: <OrderReview />,
          path: ALLROUTES.dashboard.orderReview.split("/")[2]
        },
        { element: <ViewUser />, path: "user/:id" },
        {
          element: <Settings />,
          path: ALLROUTES.dashboard.settings.split("/")[2]
        },
        {
          element: <CarrierFeedbackListing />,
          path: ALLROUTES.dashboard.carrierFeedback.split("/")[2]
        },
        {
          element: <AcceptAccess />,
          path: ALLROUTES.dashboard.acceptAccess.split("/")[2]
        },
        { element: <Order />, path: ALLROUTES.dashboard.orders.split("/")[2] },
        {
          element: <OrderDetail />,
          path: `${ALLROUTES.dashboard.orders.split("/")[2]}/:id`
        },
        {
          element: <RequestAccess />,
          path: `${ALLROUTES.dashboard.orders.split("/")[2]}/:id/requestaccess`
        },
        {
          element: <div>send acess</div>,
          path: `${ALLROUTES.dashboard.sendAccess.split("/")[2]}`
        },
        {
          element: <ApproveAccount />,
          path: `${ALLROUTES.dashboard.approveAccount.split("/")[2]}`
        },
        {
          element: <Users />,
          path: `${ALLROUTES.dashboard.users.split("/")[2]}`
        },
        {
          element: <UserDetail />,
          path: `${ALLROUTES.dashboard.users.split("/")[2]}/:id`
        },
        {
          element: <div>feedback acess</div>,
          path: `${ALLROUTES.dashboard.feedback.split("/")[2]}`
        },
        {
          // element: <>In Progress</>,
          element: <CheckID />,
          path: "orders"
        },
        { element: <CarrierListingPerformance />, path: "carrier-performance" },
        { element: <ScreenDashServices />, path: "services" },
        { element: <>In Progress</>, path: "profile" },
        { element: <TermsAndConditions />, path: "terms-and-conditions" },
        { element: <PrivacyPolicy />, path: "privacy-policy" },
        {
          path: "admin-users",
          element: <AdminUsers />
        },
        {
          path: "admin-users/:id",
          element: <AdminUserDetail />
        },
        {
          path: "admin-analytics",
          element: <AdminAnalytics />
        },
        {
          path: "admin-owners",
          element: <AdminOwners />
        },
        {
          path: "admin-database",
          element: <AdminDatabase />
        },
        {
          path: "*",
          element: <Navigate to="/dashboard/home" replace />
        }
      ]
    },
    {
      path: "charts", // for use with mobile app via webview
      element: (
        <PrivateRoute
          displayMessageForUnauth={true}
          element={<GenericContainer />}
        />
      ),
      children: [
        {
          element: <ChartArea />,
          path: "all/:urlChartFor/:urlParam1/:urlParam2"
        },
        {
          element: <ChartArea />,
          path: "all/:urlChartFor/:urlParam1/:urlParam2/:urlParam3"
        },
        {
          element: <WaitingTimeChart />,
          path: "all/waitingtime/:urlParam1"
        },
        {
          element: <AccessAreaChart />,
          path: "all/accessarea/:urlParam1"
        },
        {
          element: <NoOfVisitorChart />,
          path: "visitorchart"
        },
        {
          element: <LiftPeakTimesLine />,
          path: "liftpeaktimeschart"
        },
        {
          element: <PeakTime />,
          path: "peaktimechart"
        },
        {
          element: <DeliveryTimesChart />,
          path: "deliverytimechart"
        },
        {
          element: <DeliveryDurationChart />,
          path: "deliverydurationchart"
        },
        {
          element: <WaitTime />,
          path: "waittimechart"
        },
        {
          element: <DeliveryFrequencyChart />,
          path: "deliveryfrequencychart"
        }
      ]
    }
  ])

  return element
}

export default AppRoutes
