import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import {
  FormControl,
  InputGroup,
  Dropdown,
  Button,
  Image
} from "react-bootstrap"
import { Table } from "../../../../molecules"
import { useGetApproveUser } from "../../../../queries-and-mutations/queries"
import {
  assets,
  getUserTypeStringFromEnum,
  openBase64Image
} from "../../../../utils"
import { useModalAPIs } from "../../../../containers/popup-cotainer/modal-apis"
import { EditUserScreen } from "../home/add-user"
import { useApproveUserMutation } from "../../../../queries-and-mutations/mutation"

function ApproveAccount() {
  const nav = useNavigate()
  const [query, setQuery] = useState("")
  const [page, setPage] = useState(1)
  const approveUserCall = useApproveUserMutation()
  const [sorting, setSorting] = useState(null)
  const [ordering, setOrdering] = useState()

  const updateSorting = fieldName => {
    if (sorting && sorting.field === fieldName) {
      if (sorting.order === "ASC") {
        setSorting({ ...sorting, order: "DESC" })
      } else {
        setSorting(null)
      }
    } else {
      setSorting({ field: fieldName, order: "ASC" })
    }
  }

  useEffect(() => {
    if (sorting) {
      if (sorting.order === "ASC") {
        setOrdering(sorting.field)
      } else {
        setOrdering(`-${sorting.field}`)
      }
    } else {
      setOrdering(null)
    }
  }, [sorting])

  const { data, isFetching, refetch } = useGetApproveUser(
    page,
    query,
    10, //page size
    { ordering }
  )
  const { results: usersData, count, previous, next } = data?.data || {}

  const onNext = () => {
    setPage(prev => (next ? prev + 1 : prev))
  }
  const onPrev = () => {
    setPage(prev => (previous ? prev - 1 : prev))
  }

  const approveUser = (user, approveStatus) => {
    approveUserCall.mutate(
      { id: user.id, is_approved: approveStatus },
      {
        onSuccess: res => {
          refetch()
          console.log("user approve status", res)
        },
        onError: err => {}
      }
    )
  }
  const {
    setModalComponent,
    setModalVisibility,
    setModalContentClass
  } = useModalAPIs()
  const getUserProfileCell = cell => (
    <div className="d-flex table-profile-cell align-items-center pointer">
      <span className="header-avatar-container">
        <img src={assets.avatar} alt="avatar" />
      </span>
      <div className="d-flex flex-column align-items-start justify-content-center ms-3">
        <span className="profile-name">{cell.name}</span>
        <span className="profile-email">{cell.email}</span>
      </div>
    </div>
  )
  const renderImage = (image, name) => {
    var image = new Image()
    image.src = image

    var w = window.open("")
    w.document.write(image.outerHTML)
  }
  const getSortingHeader = (fieldLabel, fieldName) => {
    if (sorting && sorting.field === fieldName) {
      if (sorting.order === "ASC") {
        return (
          <div className="pointer" onClick={() => updateSorting(fieldName)}>
            {fieldLabel}
            <span className="ms-2">&#x2191;</span>
          </div>
        )
      }
      return (
        <div className="pointer" onClick={() => updateSorting(fieldName)}>
          {fieldLabel}
          <span className="ms-2">&#x2193;</span>
        </div>
      )
    }
    return (
      <div className="pointer" onClick={() => updateSorting(fieldName)}>
        {fieldLabel}
      </div>
    )
  }
  const columns = React.useMemo(
    () => [
      {
        Header: getSortingHeader("User Full Name", "first_name"),
        id: "first_name",
        styles: { textAlign: "left", paddingLeft: "42px" },
        accessor: getUserProfileCell
      },
      {
        Header: "ID Number",
        accessor: cell => cell?.userprofile?.id_number
      },
      {
        Header: "User Type",
        accessor: cell => getUserTypeStringFromEnum(cell.user_type)
      },
      {
        Header: getSortingHeader("Mobile Number", "phone"),
        id: "phone",
        accessor: cell => cell?.mobile_number
      },
      {
        Header: "Documents",
        accessor: cell =>
          cell.userdocument ? (
            <div className="d-flex gap-2">
              {cell.userdocument.front_image && (
                <a
                  href="#"
                  onClick={() => openBase64Image(cell.userdocument.front_image)}
                  target="_blank"
                >
                  <img
                    src={cell.userdocument.front_image}
                    alt="front_id"
                    width="50px"
                    height="50px"
                  />
                </a>
              )}
              {cell.userdocument.back_image && (
                <a
                  href="#"
                  onClick={() => openBase64Image(cell.userdocument.back_image)}
                  target="_blank"
                >
                  <img
                    src={cell.userdocument.back_image}
                    alt="front_id"
                    width="50px"
                    height="50px"
                  />
                </a>
              )}
            </div>
          ) : (
            ""
          )
      },
      {
        Header: "Action",
        accessor: cell => (
          <div>
            {!cell.is_approved && (
              <Dropdown className="order-dropdown">
                <Dropdown.Toggle variant="link" id="dropdown-basic">
                  ...
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="#" onClick={_ => approveUser(cell, 1)}>
                    Approve
                  </Dropdown.Item>
                  <Dropdown.Item href="#" onClick={_ => approveUser(cell, 2)}>
                    Reject
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
            {cell.is_approved ? "APPROVED" : ""}
          </div>
        )
      }
    ],
    [sorting]
  )
  const openAddUserPopup = (editMode = false, user = {}) => {
    setModalContentClass("p-5")
    setModalComponent(() => (
      <EditUserScreen isEditMode={editMode} user={user} />
    ))
    setModalVisibility(true)
  }
  return (
    <div>
      <div className="d-flex justify-content-between">
        <div className="section-heading">
          <h2>{"Approve Users"}</h2>
        </div>
      </div>
      <div className="d-flex align-items-center">
        <InputGroup className="my-3 w-50">
          <FormControl
            style={{ borderRight: 0 }}
            onChange={e => setQuery(e.target.value)}
            placeholder={"Search Users"}
            className="dashboard-header-search w-50"
          />
        </InputGroup>
        <button className="page-btn ms-auto px-2">
          <img src={assets.downloadIcon} alt="download" />
        </button>
        <button className="page-btn d-flex align-items-center mx-3 px-3">
          <img src={assets.sortIcon} alt="download" />
          <span className="ms-1 me-1">Filter</span>
          <img src={assets.dropdownArrow} alt="" />
        </button>
      </div>
      <Table
        data={usersData || []}
        columns={columns}
        count={count}
        page={page}
        onNext={onNext}
        onPrev={onPrev}
        pageSize={10}
        isLoading={isFetching}
      />
    </div>
  )
}

export default ApproveAccount
