import React from "react"
import { Dropdown } from "react-bootstrap"
import DatePicker from "react-datepicker"

import { Loader } from "../../atoms"
import IndexPalleteBarChartMinMaxAvg from "../../atoms/chart-index-palette/bar-chart-min-max-avg"
import BarChartMinMaxAvg from "../../charts/bar-chart-min-max-avg"
import { formatDateToStringUS } from "../../utils"

import "react-datepicker/dist/react-datepicker.css"
import "../../atoms/atoms-styles/dropdown-datepicker.scss"
import "../molecules-styles/bar-chart-section.scss"

function BarChartSection({
  barChartId = `bar-chart--${Math.ceil(Math.random() * 10e7)}`,
  title = "General",
  description = "",
  loadingData = false,
  data = [],
  yAxisLabelSuffix = "",
  colorIndexPalleteClassName = "mb-3 py-1",
  showCountCircle = false,
  CountCircleIcon = <></>,
  countCircleValue = 0,
  countCircleLabel = "",
  allFilters,
  activeFilter,
  setActiveFilter
}) {
  return (
    <>
      <div className="pt-4"></div>
      {!!showCountCircle && (
        <div
          className={`count-circle mx-auto${loadingData ? " invisible" : ""}`}
        >
          <CountCircleIcon />
          <div className="count">
            {isNaN(countCircleValue) ? "-" : countCircleValue}
          </div>
          <div className="entity">{countCircleLabel}</div>
        </div>
      )}
      <div className="d-flex chart-container">
        <div className="chart-wrap d-flex flex-column">
          <div className="d-flex align-items-center p-4">
            <div className="chart-description">
              <h3>{title}</h3>
              <p>{description}</p>
              {/* {!!description ? (
                <p>{description}</p>
              ) : (
                <div className="p-datepicker">
                  <DatePicker
                    customInput={
                      <p className="p-datepicker--label">
                        {!!selectedDate
                          ? formatDateToStringUS(new Date(selectedDate))
                          : "Select Date"}
                      </p>
                    }
                    selected={selectedDate}
                    onChange={date => {
                      setSelectedDate(date)
                      onFiltersChange({ date, filter: activeFilter })
                    }}
                  />
                </div>
              )} */}
            </div>
            <div className="flex-grow-1"></div>
            {/*<DropdownDatepicker
              className="ms-auto me-2"
              onChange={date => {
                setSelectedDate(date)
                onFiltersChange({ date, filter: activeFilter })
              }}
              selectedDate={selectedDate}
            />*/}
            <Dropdown>
              <Dropdown.Toggle variant="success" id={`${barChartId}--dropdown`}>
                {activeFilter}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {allFilters.map((filterItem, i) => (
                  <Dropdown.Item
                    key={i}
                    onClick={() => {
                      setActiveFilter(filterItem)
                    }}
                  >
                    {filterItem}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          {loadingData ? (
            <Loader className="not-h-100 d-flex flex-column align-items-center" />
          ) : (
            <>
              <div className="text-center position-relative">
                {data && data.length ? (
                  <BarChartMinMaxAvg
                    data={data}
                    id={barChartId}
                    yAxisLabelSuffix={yAxisLabelSuffix}
                  />
                ) : (
                  ""
                )}
              </div>
              <IndexPalleteBarChartMinMaxAvg
                className={colorIndexPalleteClassName}
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default BarChartSection
