import React from "react"

const UserSelectionCard = ({ title, logo, className = "" }) => {
  return (
    <div
      className={
        "user-select-card d-flex flex-column justify-content-center align-items-center " +
        className
      }
    >
      <img src={logo} alt={title} />
      <p className="pt-5">{title}</p>
    </div>
  )
}

export default UserSelectionCard
