import React, { useEffect, useLayoutEffect, useState } from "react"
import * as d3 from "d3"

function BarChart({
  id,
  width = 550,
  height = 324,
  paddings = { top: 25, left: 25, right: 25, bottom: 50 },
  startYscaleFrom = 0,
  data = [],
  barWidth = 30
}) {
  const createScales = () => {
    const _xScale = d3
      .scaleBand()
      .domain(data.map(e => e.bandName))
      .range([paddings.left, width - paddings.right])
      .padding(0.2)
    _xScale.idd = Date.now()

    const _yScale = d3
      .scaleLinear()
      .domain([
        startYscaleFrom,
        d3.max(data.map(e => e.bars.map(bar => bar.height)).flatMap(e => e))
      ])
      .range([height - paddings.bottom, paddings.top])
    _yScale.idd = Date.now()
    return [_xScale, _yScale]
  }

  const [scaleX, setScalX] = useState(undefined)
  const [scaleY, setScalY] = useState(undefined)

  const createAxes = () => {
    if (scaleX && scaleY) return [d3.axisBottom(scaleX), d3.axisLeft(scaleY)]
    return []
  }

  const getBarEnterFn = enter => {
    enter
      .append("rect")
      .attr("width", scaleX.bandwidth())
      .attr("fill", d => d.color)
      .attr("opacity", 0.5)
      .attr("class", d => "entered " + d.bandName)
      .attr("x", d => scaleX(d.bandName))
      .attr("y", d => scaleY(d.height))
      .transition()
      .duration(1000)
      .attr("height", d => height - scaleY(d.height) - paddings.bottom)

    enter
      .select("rect")
      .on("mouseenter", function (actual, i) {
        d3.select(this).attr("opacity", 1)
      })
      .on("mouseleave", function (actual, i) {
        d3.select(this).attr("opacity", 0.5)
      })
  }

  const getBarTextFun = enter => {
    enter
      .append("text")
      .attr("x", d => scaleX(d.bandName) + scaleX.bandwidth() / 3)
      .attr("y", d => scaleY(d.height))
      .text(d => d.height)
  }

  const getBarGroupEnterFunction = enter => {
    return enter
      .append("g")
      .attr("class", "bars")
      .selectAll("rect")
      .data(d =>
        d.bars.map(e => ({ ...e, bandName: d.bandName, count: d.bars.length }))
      )
      .join(getBarEnterFn)
      .join(getBarTextFun)
  }

  const getBarGroupUpdateFunction = update => {
    update.selectAll("rect").remove()
    update.selectAll("text").remove()
    update
      .selectAll("rect")
      .data(d =>
        d.bars.map(e => ({ ...e, bandName: d.bandName, count: d.bars.length }))
      )
      .join(getBarEnterFn)
      .join(getBarTextFun)
  }

  useEffect(() => {
    const [x, y] = createScales()
    setScalX(() => x)
    setScalY(() => y)
  }, [data])

  useEffect(() => {
    const [xAxisCall, yAxisCall] = createAxes()
    if (xAxisCall && yAxisCall) {
      const svgContainer = d3.select(`svg#${id}`)
      const svg = svgContainer.select("g.container").node()
        ? svgContainer.select("g.container")
        : svgContainer.append("g").attr("class", "container")

      const xAxis = svg.selectAll("g.x-axis").node()
        ? svg.selectAll("g.x-axis")
        : svg.append("g").attr("class", "x-axis")

      xAxis
        .attr("transform", `translate(${0},${height - paddings.bottom})`)
        .transition()
        .duration(1000)
        .call(xAxisCall)

      const yAxis = svg.selectAll("g.y-axis").node()
        ? svg.selectAll("g.y-axis")
        : svg.append("g").attr("class", "y-axis")

      yAxis
        .attr("transform", `translate(${paddings.left},${0})`)
        .transition()
        .duration(1000)
        .call(yAxisCall)

      svg
        .selectAll("g.bars")
        .data(data)
        .join(getBarGroupEnterFunction, getBarGroupUpdateFunction)
    }
  }, [scaleX, scaleY])

  return (
    <svg
      className="bar-chart svg-chart"
      id={id}
      viewBox={`0 0 ${width} ${height}`}
    ></svg>
  )
}

export default BarChart
