import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"

import { useModalAPIs } from "../../containers/popup-cotainer/modal-apis"
import { Loader } from "../../atoms"
import "./splash-screen.scss"

const SplashScreen = () => {
  const nav = useNavigate()
  const {
    setModalComponent,
    setModalVisibility,
    setModalContentClass
  } = useModalAPIs()

  useEffect(() => {
    setModalComponent(() => <Loader />)
    setModalContentClass("transparent-bg border-0")
    setModalVisibility(true)
    setTimeout(() => {
      nav("select-user-screen")
      setModalVisibility(false)
    }, 2000)
  }, [])

  return <div className="splash-screen"></div>
}

export default SplashScreen
