import React, { useEffect } from "react"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { DashboardHeader, SideNav } from "../../molecules"

import "./dashboard.scss"

const DashboardContainer = ({}) => {
  const location = useLocation()
  const nav = useNavigate()

  useEffect(() => {
    const pathArr = location.pathname.split("/")
    if (pathArr[pathArr.length - 1] === "") pathArr.pop()
    if (pathArr.pop() === "dashboard") nav("/dashboard/home", { replace: true })
  }, [])

  return (
    <>
      <div className="d-flex">
        <SideNav />
        <div className="d-flex flex-column w-100">
          <DashboardHeader />
          <div className="outlet-container ">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  )
}

export default DashboardContainer
