import React from "react"
import "./atoms-styles/button.scss"

const Button = ({
  text = "",
  onClick = () => {},
  disabled = false,
  className = "",
  type = "button"
}) => {
  return (
    <button
      disabled={disabled}
      className={` dv-btn border-0 ${
        disabled ? "btn btn-disabled" : ""
      } ${className}`}
      onClick={onClick}
      type={type}
    >
      {text}
    </button>
  )
}

export default Button
