import React from "react"
import { useMutation } from "react-query"
import { webApiHandler } from "../utils"

export const useSendOtpMutation = () => {
  return useMutation(payload => webApiHandler.sendOtp(payload))
}

export const useLoginMutation = () => {
  return useMutation(payload => webApiHandler.verifyOtp(payload))
}

export const useAdminLoginMutation = () => {
  return useMutation(payload => webApiHandler.adminLogin(payload))
}
export const useAdminLogoutMutation = () => {
  return useMutation(_ => webApiHandler.adminLogout())
}

export const useCreateUserMutation = () => {
  return useMutation(payload => webApiHandler.createUser(payload))
}

export const useEditUserMutation = () => {
  return useMutation(({ id, ...payload }) =>
    webApiHandler.editUser(id, payload)
  )
}

export const useRequestAccessMutation = () => {
  return useMutation(payload => webApiHandler.createRequestAccess(payload))
}

export const useReplyFeedbackMutation = () => {
  return useMutation(({ id, ...payload }) =>
    webApiHandler.feedbackReply(id, payload)
  )
}

export const useApproveUserMutation = () => {
  return useMutation(({ id, ...payload }) =>
    webApiHandler.approveUser(id, payload)
  )
}

export const useSignupMutation = () => {
  return useMutation(payload => webApiHandler.userSignup(payload))
}

export const useDocumentMutation = () => {
  return useMutation(payload => webApiHandler.userDocument(payload))
}

export const useDeleteUser = () => {
  return useMutation(id => webApiHandler.deleteUser(id))
}

export const useCreateOwnerMutation = () => {
  return useMutation(payload => webApiHandler.createOwner(payload))
}

export const useEditOwnerMutation = () => {
  return useMutation(({ id, ...payload }) =>
    webApiHandler.editOwner(id, payload)
  )
}

export const useDeleteOwnerMutation = () => {
  return useMutation(id => webApiHandler.deleteOwner(id))
}

export const useSendAppLinkOwner = () => {
   return useMutation(payload => webApiHandler.sendAppLinkOwner(payload))
}

export const useSendAppLinkAdmin = () => {
   return useMutation(payload => webApiHandler.sendAppLinkAdmin(payload))
}
export const useFlagUnflagUser = () => {
   return useMutation(({ id, ...payload }) => webApiHandler.flagUnflagUser(id, payload))
}
