import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { DropdownDatepicker, Dropdown } from "../../../../../atoms"
import ChartHeader from "../../../../../charts/chart-header"
import StackedBarChart from "../../../../../charts/stacked-bar-chart"
import { getChartFilters, getDate } from "../../../../../utils"

function AccessAreaChart(props) {
  const [params, setParams] = useState({})
  const { urlParam1 } = useParams()

  useEffect(() => {
    if (!!urlParam1) {
      document?.body
        ?.querySelector(".daarna-app")
        ?.classList?.add("custom-bg-color")
      document?.documentElement?.style?.setProperty(
        "--app-custom-bg-color",
        atob(urlParam1)
      )
    }
  })

  const [selectedArea, setSelectedArea] = useState({ name: "Access Area 1" })
  const colors = {
    tenants: "#3F4589",
    carriers: "#2196F3",
    owner: "#C2E0F8",
    guest: "#E6EBEF"
  }
  const data = [
    {
      key: "10am",
      tenants: 100,
      carriers: 120,
      owner: 70,
      guest: 100
    },
    {
      key: "11am",
      tenants: 85,
      carriers: 75,
      owner: 110,
      guest: 105
    },
    {
      key: "12pm",
      tenants: 100,
      carriers: 90,
      owner: 75,
      guest: 85
    },
    {
      key: "1pm",
      tenants: 100,
      carriers: 120,
      owner: 70,
      guest: 100
    },
    {
      key: "2pm",
      tenants: 85,
      carriers: 75,
      owner: 110,
      guest: 105
    },
    {
      key: "3pm",
      tenants: 100,
      carriers: 90,
      owner: 75,
      guest: 85
    }
  ]
  const keys = ["tenants", "carriers", "owner", "guest"]
  const getTitle = () => (
    <Dropdown
      className="no-hover-highlight border-0"
      selectedOption={selectedArea}
      onChange={setSelectedArea}
      menu={[
        { name: "Access Area 1" },
        { name: "Access Area 2" },
        { name: "Access Area 3" }
      ]}
    />
  )
  return (
    <div className="white-bg chart-wrap p-3">
      <ChartHeader
        title={() => getTitle()}
        subtitle="NO Of Persons in Access Area"
        dateFilter={true}
        filters={getChartFilters()}
        updateParams={q => setParams(q)}
      />
      <div className="text-center position-relative">
        <StackedBarChart
          colors={colors}
          data={data}
          keys={keys}
          id={"access-area-chart"}
        />
      </div>
    </div>
  )
}

export default AccessAreaChart
