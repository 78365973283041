import React from "react"
import ChartHeader from "../../../../charts/chart-header"
import { Table } from "../../../../molecules"

function TopTenServiceProviders(props) {
  const filters = [
    {
      name: "Time",
      value: "time"
    },
    {
      name: "Category",
      value: "category"
    }
  ]
  const [data, setData] = React.useState(() =>
    new Array(10).fill({
      name: "Kores Ltd",
      slug: "Korean",
      number: 200
    })
  )

  const columns = React.useMemo(
    () => [
      {
        Header: "Service Provider",
        styles: { textAlign: "left", paddingLeft: "42px" },
        accessor: "name"
      },
      {
        Header: "Service Name",
        accessor: "slug"
      },
      {
        Header: "No. of Deliveries",
        accessor: "number"
      }
    ],
    []
  )
  return (
    <div className="white-bg chart-wrap p-3">
      <ChartHeader
        title="Top 10 service providers"
        subtitle="No. of Deliveries by categories, NO of deliveries and Percentage"
        dateFilter={true}
        filters={filters}
      />
      <Table
        columns={columns}
        fixedHead={true}
        data={data}
        hidePagination={true}
      />
      <div className="d-flex">
        <a
          onClick={() => setData(pr => pr.concat(pr))}
          className="view-more-btn ms-auto p-3"
        >
          View More
        </a>
      </div>
    </div>
  )
}

export default TopTenServiceProviders
