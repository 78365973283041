import React, { useState } from "react"
import ChartHeader from "../../../../charts/chart-header"
import StackedBarChart from "../../../../charts/stacked-bar-chart"
import { getDate } from "../../../../utils"

function NoOfVisitorChart(props) {
  const [params, setParams] = useState({})
  const [selectedDate, setSelectedDate] = useState(getDate())
  const filters = [
    {
      name: "Time",
      value: "time"
    },
    {
      name: "Category",
      value: "category"
    },
    {
      name: "Building",
      value: "building"
    }
  ]
  const colors = {
    tenants: "#3F4589",
    carriers: "#2196F3",
    owner: "#C2E0F8",
    guest: "#E6EBEF"
  }
  const data = [
    {
      key: "10am",
      tenants: 100,
      carriers: 120,
      owner: 70,
      guest: 100
    },
    {
      key: "11am",
      tenants: 85,
      carriers: 75,
      owner: 110,
      guest: 105
    },
    {
      key: "12pm",
      tenants: 100,
      carriers: 90,
      owner: 75,
      guest: 85
    },
    {
      key: "1pm",
      tenants: 100,
      carriers: 120,
      owner: 70,
      guest: 100
    },
    {
      key: "2pm",
      tenants: 85,
      carriers: 75,
      owner: 110,
      guest: 105
    },
    {
      key: "3pm",
      tenants: 100,
      carriers: 90,
      owner: 75,
      guest: 85
    }
  ]
  const keys = ["tenants", "carriers", "owner", "guest"]

  return (
    <div className="white-bg chart-wrap p-3">
      <ChartHeader
        title="No of visitors"
        subtitle="Lorem ipsum dolor sit amet consectetur adipisicing elit."
        dateFilter={true}
        filters={filters}
        updateParams={q => setParams(q)}
      />
      <div className="text-center position-relative">
        <StackedBarChart
          colors={colors}
          data={data}
          keys={keys}
          id={"access-chart"}
        />
      </div>
    </div>
  )
}

export default NoOfVisitorChart
