import React, { useState, useEffect, useCallback } from "react"
import { Navigate } from "react-router-dom"
import { getUserEnum } from "../utils"

function PrivateRoute({ element, displayMessageForUnauth = false }) {
  const [sessionToken, setToken] = useState(localStorage.getItem("token"))
  const [user, setUser] = useState(getUserEnum())

  const storageListener = useCallback(() => {
    setToken(localStorage.getItem("token"))
    setUser(getUserEnum())
  }, [])

  useEffect(() => {
    window.addEventListener("storage", storageListener)
    return () => {
      window.removeEventListener("storage", storageListener)
    }
  }, [])

  if (!user || !sessionToken) {
    if (!!displayMessageForUnauth)
      return (
        <div className="d-flex w-100 h-100 align-items-center justify-content-center text-center">
          <p>You are not authorized to access this content.</p>
        </div>
      )
    return <Navigate to="/" replace />
  }
  return element
}

export default PrivateRoute
