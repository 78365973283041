import React, { useState } from "react"
import BarChart from "../../../../charts/bar-chart"
import ChartHeader from "../../../../charts/chart-header"
import { data } from "../../../../charts/sample-data/bar-chart"

function DeliveryDurationChart(props) {
  const [usedData, setUsedData] = useState(
    data.map(e => ({ ...e, bars: [{ ...e.bars[0], color: "#3F4589" }] }))
  )
  const filters = [
    {
      name: "Time",
      value: "time"
    },
    {
      name: "Building",
      value: "building"
    },
    {
      name: "Category",
      value: "category"
    },
    {
      name: "Stay Time",
      value: "stay-time"
    }
  ]
  return (
    <div className="white-bg chart-wrap p-3">
      <ChartHeader
        title="Long Stays"
        subtitle="Lorem ipsum dolor sit amet consectetur adipisicing elit."
        dateFilter={true}
        filters={filters}
      />
      <div className="text-center position-relative">
        <BarChart height={500} data={usedData} id="delivery-duration" />
      </div>
    </div>
  )
}

export default DeliveryDurationChart
