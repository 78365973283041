import React from "react"
import { Spinner } from "react-bootstrap"

const Loader = ({ className = "" }) => {
  return (
    <div className={`d-flex justify-content-center ${className}`}>
      <Spinner animation="border" role="status" variant="warning" />
    </div>
  )
}

export default Loader
