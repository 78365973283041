import React, { useLayoutEffect, useRef } from "react"
import * as d3 from "d3"

const Doughnut = ({ data, outerRadius = 72, thickness = 36, id }) => {
  const getArc = (item, dataArray = data) => {
    const circumFerence = Math.PI * 2
    const currIndex = dataArray.findIndex(e => e.name === item.name)
    const dataBefore = dataArray.filter((_, i) => i < currIndex)
    const startAngle = dataBefore.reduce(
      (acc, el) => acc + (circumFerence * el.percentage) / 100,
      0
    )

    const endAngle = startAngle + (circumFerence * item.percentage) / 100
    const values = d3
      .arc()
      .innerRadius(outerRadius - thickness)
      .outerRadius(outerRadius)
      .startAngle(startAngle)
      .endAngle(endAngle)()
    return values
  }

  useLayoutEffect(() => {
    const svgContainer = d3.select(`svg#${id}`)
    const svg = svgContainer.select("g").node()
      ? svgContainer.select("g")
      : svgContainer.append("g")
    svg
      .selectAll("path")
      .data(data, d => d.name)
      .join(
        enter => {
          enter
            .append("g")
            .attr("transform", `translate(${outerRadius},${outerRadius})`)
            .append("path")
            .attr("fill", d => d.color)
            .attr("d", d => getArc(d))
        },
        update =>
          update
            .select("path")
            .attr("d", d => getArc(d))
            .attr("fill", d => d.color)
            .attr("stroke-dasharray", `100%`)
            .transition()
            .duration(1000)
            .attr("stroke-dasharray", `00%`),
        exit => exit.remove("path")
      )
  }, [])

  return (
    <>
      <svg
        className={"doughnut svg-chart"}
        id={id}
        viewBox={`0 0 ${outerRadius * 2} ${outerRadius * 2}`}
      ></svg>
    </>
  )
}

export default Doughnut
