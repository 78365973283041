import React from "react"
import { FormControl, InputGroup, Spinner } from "react-bootstrap"
import Button from "../../../../atoms/button"
import { useModalAPIs } from "../../../../containers/popup-cotainer/modal-apis"
import { Table } from "../../../../molecules"
import { useGetFeedbackListings } from "../../../../queries-and-mutations/queries"
import { assets } from "../../../../utils"
import CarrierFeedbackResponsePopup from "./carrier-feedback-response"

function CarrierFeedbackListing(props) {
  const [page, setPage] = React.useState(1)

  const {
    setModalComponent,
    setModalVisibility,
    setModalContentClass
  } = useModalAPIs()

  const openFeedbackResponsePopup = cell => {
    setModalComponent(() => (
      <CarrierFeedbackResponsePopup feedbackDetails={cell} />
    ))
    setModalContentClass("p-4")
    setModalVisibility(true)
  }

  const {
    data: feedbackListingResponse,
    isFetching: feedbackFetching
  } = useGetFeedbackListings({ page })

  const { results: feedbackListing = [], count, next, previous } =
    feedbackListingResponse?.data || {}

  const columns = React.useMemo(
    () => [
      {
        Header: "Carrier Name",
        styles: { textAlign: "left", paddingLeft: "42px" },
        accessor: cell => `${cell.user.first_name} ${cell.user.last_name}`
      },
      {
        Header: "User Email",
        accessor: cell => cell.user.email
      },
      {
        Header: "Message",
        styles: { width: "320px" },
        accessor: cell => (
          <span style={{ width: "320px" }} className="text-truncate d-block ">
            {cell.message}
          </span>
        )
      },
      {
        Header: "Message Time",
        accessor: cell => new Date(cell.created_on).toUTCString()
      },

      {
        Header: "Action",
        accessor: cell => (
          <div>
            <Button
              onClick={() => openFeedbackResponsePopup(cell)}
              className="h-32p"
              text="View"
            />
          </div>
        )
      }
    ],
    []
  )

  const onNext = () => {
    if (!!feedbackListing.length && next) {
      setPage(p => p + 1)
    }
  }

  const onPrev = () => {
    if (!!feedbackListing.length && previous) {
      setPage(p => p - 1)
    }
  }

  return (
    <div>
      <h2 className="section-heading">Carrier Feedback</h2>
      <Table
        data={feedbackListing}
        columns={columns}
        count={count}
        page={page}
        isLoading={feedbackFetching}
        onNext={onNext}
        onPrev={onPrev}
        // pageSize={size}
      />
    </div>
  )
}

export default CarrierFeedbackListing
