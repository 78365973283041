export const APPCONFIG = {
  debugJs: true,
  debugJsCharts: false,
  appPublicName: "SAFI7",
  graphs: {
    colorBar: "#C2E0F8",
    colorAvgMark: "#3F4589"
  },
  mapBoxKey:
    "pk.eyJ1IjoibW9uaWthYmFuc2FsMDciLCJhIjoiY2w0eHE1d3ZwMDhtMzNrcWZhcnNncG1iOSJ9.ld-yUOY_XVkN_n2_ZSLT0Q",
  mapKey: "AIzaSyBy5IpSGvYtpjuMPgis4leS7ub4ljoN5wQ"
}
