import React, { useEffect } from "react"
import "./App.scss"
import AppRoutes from "./routes"
import { BrowserRouter as Router } from "react-router-dom"
import { ModalContainer } from "./containers/popup-cotainer"
import { QueryClient, QueryClientProvider } from "react-query"
import { ReactQueryDevtools } from "react-query/devtools"
import ErrorAlert from "./atoms/error-alert"

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: 1800 * 1000
    }
  }
})
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ErrorAlert />
      <div className="daarna-app">
        <ModalContainer />
        <Router>
          <AppRoutes />
        </Router>
      </div>
      <ReactQueryDevtools initialIsOpen={false} position={"top-right"} />
    </QueryClientProvider>
  )
}

export default App
