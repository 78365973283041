import React from "react"
import { Table } from "../../../../molecules"
import Button from "../../../../atoms/button"
import { Dropdown } from "react-bootstrap"

function Outgoing() {
    const columns = React.useMemo(
        () => [
            {
                Header: "Ordered Items",
                styles: { textAlign: "left", paddingLeft: "42px" },
                accessor: cell => "Amanda Jake"
            },
            {
                Header: "Restaurant Name",
                accessor: () => "Mandy@eg.com"
            },
            {
                Header: "Pickup Address",
                accessor: () => "Chicken Wings"
            },
            {
                Header: "Delivery Address",
                accessor: () => new Date().toUTCString()
            },
            {
                Header: "Delivery Note",
                accessor: () => new Date().toUTCString()
            },
            {
                Header: "Action",
                accessor: () => (
                    <div>
                        <Dropdown className="order-dropdown">
                            <Dropdown.Toggle variant="link" id="dropdown-basic">
                                ...
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href="#">Request access</Dropdown.Item>
                                <Dropdown.Item href="#">Track driver</Dropdown.Item>
                                <Dropdown.Item href="/dashboard/orders/1">View order</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                )
            }
        ],
        []
    )
    return (
        <Table
            data={new Array(10).fill({})}
            columns={columns}
            paginationStyle={true}
        // count={count}
        // page={page}
        // onNext={onNext}
        // onPrev={onPrev}
        // pageSize={size}
        // isLoading={isLoading}
        />
    )
}

export default Outgoing