import React from "react"
import create from "zustand"

const useModalStore = create(set => ({
  ModalComponent: () => <></>,
  modalVisibility: false,
  onCloseModal: () => {},
  contentClassName: "",
  backdrop: true,
  setModalComponent: (component, onCloseFn = () => {}) =>
    set({ ModalComponent: component, onCloseModal: onCloseFn }),
  setModalVisibility: boolean => set({ modalVisibility: boolean }),
  setOnClose: fn => set({ onCloseModal: fn }),
  setModalContentClass: className => set({ contentClassName: className }),
  setBackdrop: val => set({ backdrop: val })
}))

export const useModalAPIs = () => {
  const [
    ModalComponent,
    modalVisibility,
    onCloseModal,
    setModalVisibility,
    setModalComponent,
    setModalContentClass,
    contentClassName,
    backdrop,
    setBackdrop
  ] = useModalStore(state => [
    state.ModalComponent,
    state.modalVisibility,
    state.onCloseModal,
    state.setModalVisibility,
    state.setModalComponent,
    state.setModalContentClass,
    state.contentClassName,
    state.backdrop,
    state.setBackdrop
  ])

  return {
    ModalComponent,
    modalVisibility,
    onCloseModal,
    setModalVisibility,
    setModalComponent,
    setModalContentClass,
    contentClassName,
    backdrop,
    setBackdrop
  }
}
