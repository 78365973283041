import React, { useEffect, useRef, useState } from "react"
import {
  DocumentReaderApi,
  TextFieldType,
  GraphicFieldType
} from "@regulaforensics/document-reader-webclient"

import "react-image-crop/src/ReactCrop.scss"
import { assets, webApiHandler } from "../../utils"
import { DaarnaButton } from "../../atoms"
import { useSignupApis, useStoreApis } from "../../store"
import {
  useDocumentMutation,
  useSignupMutation
} from "../../queries-and-mutations/mutation"
import { useModalAPIs } from "../../containers/popup-cotainer/modal-apis"
import { useNavigate } from "react-router-dom"
import { Alert } from "react-bootstrap"
import { APPCONFIG } from "../../config"

const {
  DOCUMENT_NUMBER,
  SURNAME_AND_GIVEN_NAMES,
  DATE_OF_BIRTH
} = TextFieldType

const { PORTRAIT, SIGNATURE } = GraphicFieldType

const DocumentUpload = () => {
  const nav = useNavigate()
  const signMutation = useSignupMutation()
  const documentMutation = useDocumentMutation()
  const { formValues } = useSignupApis()
  const { faceImage } = useStoreApis()
  const [image, setImage] = useState(null)
  const [bgUrl, setBgUrl] = useState()
  const {
    setModalComponent,
    setModalVisibility,
    setModalContentClass
  } = useModalAPIs()

  const openMessagePopup = status => {
    setModalContentClass("p-5")
    setModalComponent(() => (
      <MessagePopup
        status={status}
        nav={nav}
        setModalVisibility={setModalVisibility}
      />
    ))
    setModalVisibility(true)
  }

  const api = new DocumentReaderApi({ basePath: "https://localhost:3000" })
  const documentPayload = {
    front_image: faceImage,
    back_image: bgUrl
  }

  const processPhotoIdDocument = () => {
    webApiHandler
      .regulaProcess({
        processParam: {
          scenario: "FullProcess",
          doublePageSpread: true,
          measureSystem: 0,
          dateFormat: "M/d/yyyy",
          alreadyCropped: true
        },
        List: [
          {
            ImageData: { image: bgUrl },
            light: 6,
            page_idx: 0
          },
          {
            ImageData: { image: faceImage },
            light: 6,
            page_idx: 0
          }
        ]
      })
      .then(response => {
        const NAME = "Surname And Given Names"
        const details = getPhotoIdDetails(response.data.data)
        const user_picture = getUserPicture(response.data.data)
        const fullName = `${formValues.first_name} ${formValues.last_name}`
        if (APPCONFIG.debugJs)
          console.log(fullName?.toUpperCase(), details[NAME]?.toUpperCase())
        if (fullName?.toUpperCase() === details[NAME]?.toUpperCase()) {
          signMutation.mutate(
            { ...formValues, documents: { ...documentPayload, user_picture } },
            {
              onSuccess: res => {
                openMessagePopup("success")
                nav("/select-user-screen")
                // documentMutation.mutate(documentPayload, {
                //   onSuccess: res => {

                //   },
                //   onError: err => {
                //     console.log(err)
                //   }
                // })
              },
              onError: err => {
                console.log(err)
              }
            }
          )
        } else {
          openMessagePopup("fail")
        }
      })
      .catch(axiosError => {})
    /*const result = await api.process({
      images: [bgUrl]
    })
    console.log(result)
    const docNumber = result.text.getField(DOCUMENT_NUMBER)
    const fullName = result.text.getField(SURNAME_AND_GIVEN_NAMES)
    const dateOfBirth = result.text.getField(DATE_OF_BIRTH)

    // graphics fields
    const portraitAsBase64 = result.images.getField(PORTRAIT)
    const signatureAsBase64 = result.images.getField(SIGNATURE)
    console.log(docNumber.getValue())*/
  }

  const getPhotoIdDetails = data => {
    const list = data?.ContainerList?.List
    const findText = list?.find(item => {
      if (item["Text"]) {
        return item
      }
    })
    const details = {}
    const fieldList = findText ? findText["Text"]?.fieldList : []
    fieldList.forEach(({ fieldName, value }) => {
      details[fieldName] = value
    })
    return details
  }
  const getUserPicture = data => {
    const list = data?.ContainerList?.List
    const findImages = list?.find(item => {
      if (item["Images"]) {
        return item
      }
    })
    let user_picture = ""
    const fieldList = findImages ? findImages["Images"]?.fieldList : []
    fieldList.forEach(({ fieldName, valueList }) => {
      if (fieldName === "Portrait")
        user_picture = valueList && valueList.length ? valueList[0]?.value : ""
    })
    return user_picture
  }

  const fileInput = useRef()
  const handleBrowseImage = () => {
    fileInput.current.click()
  }

  const handleImageSelect = e => {
    setImage(e.target.files[0])
  }

  useEffect(() => {
    if (image) {
      const reader = new FileReader()
      reader.readAsDataURL(image)
      reader.onload = () => {
        setBgUrl(reader.result)
      }
    }
  }, [image])

  return (
    <>
      <input
        onChange={handleImageSelect}
        className="d-none"
        type="file"
        ref={fileInput}
        accept="image/*"
      />

      <div className="d-flex vh-100">
        <div
          style={{ backgroundImage: `url(${assets.loginBackgroundImage})` }}
          className="login-bg d-none d-md-block h-100 w-50 position-relative"
        >
          <div className="backdrop position-absolute h-100 w-100"></div>
          <img
            className="p-4 position-relative"
            src={assets.logoWhite}
            alt="logo"
          />
          <div className="spacer-parent d-flex flex-column">
            <div className="spacer"></div>
            <p className="login-welcome-text position-relative mx-auto text-center">
              Become a certified vendor on DV.
            </p>
          </div>
        </div>
        <div className="w-50 d-flex justify-content-center">
          <div className="w-75 mt-5 text-center">
            <h2 className="text-center w-100">
              Upload back image of your Photo ID
            </h2>
            <div
              style={
                bgUrl
                  ? {
                      backgroundImage: `url(${bgUrl})`,
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "contain"
                    }
                  : {}
              }
              className="id-image-drag-drop mt-5 mx-auto d-flex flex-column align-items-center justify-content-center"
            >
              {!image && (
                <>
                  <img src={assets.uploadIcon} />
                  <h4 className="text-center my-1 mt-3">
                    Drag image to Upload
                  </h4>
                  <h4 className="text-center my-1">OR</h4>
                  <DaarnaButton
                    onClick={handleBrowseImage}
                    className="mt-1 w-50"
                    text="Browse Files"
                  />
                </>
              )}
            </div>
            <DaarnaButton
              className="mt-5 continue-btn id-verify"
              text={"Continue"}
              disabled={!bgUrl}
              onClick={processPhotoIdDocument}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default DocumentUpload

const MessagePopup = ({ status, nav, setModalVisibility }) => {
  return (
    <>
      {status === "success" ? (
        <div>
          <h2 className="mb-5">
            You have successfully signed up. Please login
          </h2>
          <DaarnaButton
            text="Login"
            onClick={() => {
              setModalVisibility(false)
              nav("/login")
            }}
          />
        </div>
      ) : (
        <div>
          <Alert key={"danger"} className="mb-3" variant={"danger"}>
            Information Mismatch
          </Alert>

          <h3 className="mb-3">
            The uploaded photo ID document does not match with the details that
            you have entered.
          </h3>
          <h3 className="mb-5"> Please check the uploaded document</h3>
          <DaarnaButton
            text="Close"
            onClick={() => {
              setModalVisibility(false)
            }}
          />
        </div>
      )}
    </>
  )
}
