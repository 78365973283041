import React from "react"
import { Modal } from "react-bootstrap"
import { useModalAPIs } from "./modal-apis"

const ModalContainer = () => {
  const {
    ModalComponent,
    modalVisibility,
    onCloseModal,
    setModalVisibility,
    contentClassName,
    backdrop
  } = useModalAPIs()

  return (
    <Modal
      show={modalVisibility}
      onHide={() => {
        setModalVisibility(false)
        onCloseModal()
      }}
      backdrop={backdrop}
      contentClassName={contentClassName}
      centered
    >
      <ModalComponent />
    </Modal>
  )
}

export default ModalContainer
