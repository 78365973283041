import { useEffect, useMemo, useState } from "react"
import {
  UserTypes,
  assets,
  getUserTypeStringFromEnum,
  openBase64Image
} from "../../utils"
import { useGetOwnerList } from "../../queries-and-mutations/queries"
import { Dropdown as CustomDropdown } from "../../atoms"
import { Button, Dropdown, FormControl, InputGroup } from "react-bootstrap"
import { Table } from "../../molecules"
import { useModalAPIs } from "../../containers/popup-cotainer/modal-apis"
import { AddOwner } from "./add-owner"
import { useDeleteOwnerMutation } from "../../queries-and-mutations/mutation"
import { useQueryClient } from "react-query"
import { svgIcons } from "../../assets/svgIcons"

const AdminOwners = () => {
  const [query, setQuery] = useState("")
  const [page, setPage] = useState(1)
  const [selectedUser, setSelectedUser] = useState(null)
  const [sorting, setSorting] = useState(null)
  const [ordering, setOrdering] = useState()
  const deleteOwnerMutation = useDeleteOwnerMutation()
  const queryClient = useQueryClient()

  const {
    setModalComponent,
    setModalVisibility,
    setModalContentClass
  } = useModalAPIs()
  const updateSorting = fieldName => {
    if (sorting && sorting.field === fieldName) {
      if (sorting.order === "ASC") {
        setSorting({ ...sorting, order: "DESC" })
      } else {
        setSorting(null)
      }
    } else {
      setSorting({ field: fieldName, order: "ASC" })
    }
  }
  const { data, isFetching } = useGetOwnerList(
    page,
    query,
    10, //page size
    { ordering },
    {
      enabled: true
    }
  )
  const { results: ownerData, count, previous, next } = data?.data || {}

  const onNext = () => {
    setPage(prev => (next ? prev + 1 : prev))
  }
  const onPrev = () => {
    setPage(prev => (previous ? prev - 1 : prev))
  }
  const getUserProfileCell = cell => (
    <div
      className="d-flex table-profile-cell align-items-center pointer"
      // onClick={() => nav(`/dashboard/users/${cell.id}`)}
    >
      <span className="header-avatar-container">
        <img src={assets.avatar} alt="avatar" />
      </span>
      <div className="d-flex flex-column align-items-start justify-content-center ms-3">
        <span className="profile-name">{`${cell.name}`}</span>
        <span className="profile-email">{cell.email}</span>
      </div>
    </div>
  )
  const getSortingHeader = (fieldLabel, fieldName) => {
    if (sorting && sorting.field === fieldName) {
      if (sorting.order === "ASC") {
        return (
          <div className="pointer" onClick={() => updateSorting(fieldName)}>
            {fieldLabel}
            <span className="ms-2">&#x2191;</span>
          </div>
        )
      }
      return (
        <div className="pointer" onClick={() => updateSorting(fieldName)}>
          {fieldLabel}
          <span className="ms-2">&#x2193;</span>
        </div>
      )
    }
    return (
      <div className="pointer" onClick={() => updateSorting(fieldName)}>
        {fieldLabel}
      </div>
    )
  }
  useEffect(() => {
    if (sorting) {
      if (sorting.order === "ASC") {
        setOrdering(sorting.field)
      } else {
        setOrdering(`-${sorting.field}`)
      }
    } else {
      setOrdering(null)
    }
  }, [CustomDropdown, sorting])
  const columns = useMemo(
    () => [
      {
        Header: () => getSortingHeader("User Full Name", "first_name"),
        id: "first_name",
        styles: { textAlign: "left", paddingLeft: "42px" },
        accessor: getUserProfileCell
      },
      {
        Header: "ID Number",
        accessor: cell => cell?.userprofile?.id_number
      },
      {
        Header: "User Type",
        accessor: cell => getUserTypeStringFromEnum(cell.user_type)
      },
      // {
      //   Header: "Category",
      //   accessor: cell => cell?.userprofile?.category
      // },
      // {
      //   Header: "Company",
      //   accessor: cell => cell?.userprofile?.company
      // },
      // {
      //   Header: "Mobile Number",
      //   accessor: cell => cell?.mobile_number
      // },
      {
        Header: "Documents",
        accessor: cell =>
          cell.userdocument ? (
            <div className="d-flex gap-2">
              {cell.userdocument.front_image && (
                <a
                  href="#"
                  onClick={() => openBase64Image(cell.userdocument.front_image)}
                  target="_blank"
                >
                  <img
                    src={cell.userdocument.front_image}
                    alt="front_id"
                    width="50px"
                    height="50px"
                  />
                </a>
              )}
              {cell.userdocument.back_image && (
                <a
                  href="#"
                  onClick={() => openBase64Image(cell.userdocument.back_image)}
                  target="_blank"
                >
                  <img
                    src={cell.userdocument.back_image}
                    alt="front_id"
                    width="50px"
                    height="50px"
                  />
                </a>
              )}
            </div>
          ) : (
            ""
          )
      },
      {
        Header: "Action",
        accessor: cell => (
          <div role="button" onClick={() => openDeleteOwnerPopup(cell.id)}>
            <svgIcons.DeleteIcon />
          </div>
        )
      }
    ],
    [sorting]
  )
  const deleteOwner = id => {
    deleteOwnerMutation.mutate(id, {
      onSuccess: data => {
        alert("Owner Deleted successfully")
        setModalVisibility(false)
        queryClient.invalidateQueries(["ownerlist"])
      },
      onError: err => alert("Error Deleting Owner")
    })
  }

  const openDeleteOwnerPopup = ownerId => {
    setModalContentClass("p-3")
    setModalComponent(() => (
      <div>
        <div>Are you sure, you want to delete owner?</div>
        <div className="d-flex justify-content-end">
          <Button
            variant="danger"
            onClick={() => {
              deleteOwner(ownerId)
            }}
          >
            Delete
          </Button>
        </div>
      </div>
    ))
    setModalVisibility(true)
  }
  const openAddUserPopup = (editMode = false, user) => {
    setModalContentClass("p-5")
    setModalComponent(() => <AddOwner isEditMode={editMode} user={user} />)
    setModalVisibility(true)
  }
  return (
    <div>
      <div className="d-flex justify-content-between">
        <div className="section-heading">
          <h2>{"Owners"}</h2>
        </div>
        <div>
          <Button onClick={() => openAddUserPopup()} variant="custom">
            + Add New Owner
          </Button>
        </div>
      </div>
      <div className="d-flex align-items-center">
        <InputGroup className="my-3 w-50">
          <FormControl
            style={{ borderRight: 0 }}
            onChange={e => setQuery(e.target.value)}
            placeholder={"Search Users"}
            className="dashboard-header-search w-50"
          />
        </InputGroup>
        <button className="page-btn ms-auto px-2">
          <img src={assets.downloadIcon} alt="download" />
        </button>
        <button className="page-btn d-flex align-items-center mx-3 px-3">
          <img src={assets.sortIcon} alt="download" />
          <span className="ms-1 me-1">Filter</span>
          <img src={assets.dropdownArrow} alt="" />
        </button>
      </div>
      <Table
        data={ownerData || []}
        columns={columns}
        count={count}
        page={page}
        onNext={onNext}
        onPrev={onPrev}
        pageSize={10}
        isLoading={isFetching}
      />
    </div>
  )
}

export default AdminOwners
