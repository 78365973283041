import React from "react"
import { useNavigate } from "react-router-dom"
import { assets } from "../utils"

function BackArrowNoTail() {
  const nav = useNavigate()

  return (
    <img
      onClick={() => nav(-1)}
      className="me-3 pointer"
      src={assets.backArrowNoTail}
      alt="back"
    />
  )
}

export default BackArrowNoTail
