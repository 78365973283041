import React, { useEffect } from "react"
import { useTable } from "react-table"
import { Loader } from "../atoms"
import Button from "../atoms/button"

import "./molecules-styles/table.scss"

const Table = React.forwardRef(function (
  {
    data,
    columns,
    pageSize = 10,
    page = 1,
    count = 0,
    onNext,
    onPrev,
    isLoading = false,
    hidePagination = false,
    paginationStyle = false,
    scrollId = Date.now().toString(),
    className = "",
    fixedHead = false
  },
  tableRef
) {
  const tableData = React.useMemo(() => data, [data])
  const tableColumns = React.useMemo(() => columns, [columns])
  const containerRef = React.useRef()
  const [height, setHeight] = React.useState(0)

  const scrollToRow = num => { }

  React.useImperativeHandle(tableRef, () => ({ scrollToRow }))

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({ columns: tableColumns, data: tableData })

  const getElementHeight = el => el?.getBoundingClientRect?.().height
  const getAllSiblingsHeight = el => {
    let siblingsArray = []
    let node = el.parentNode.firstChild
    do {
      if (node != el) siblingsArray.push(node)
      node = node?.nextSibling
    } while (node)
    return siblingsArray.reduce((acc, el) => getElementHeight(el) + acc, 0)
  }

  React.useLayoutEffect(() => {
    if (fixedHead && containerRef.current) {
      setTimeout(() => {
        let parentHeight = getElementHeight(containerRef.current?.parentElement)
        setHeight(parentHeight - getAllSiblingsHeight(containerRef.current))
      }, 500)
    }
  }, [containerRef.current])

  return (
    <div
      style={fixedHead ? { height } : {}}
      ref={containerRef}
      className={`table-holder overflow-auto ${fixedHead ? "table-fixed-head" : ""
        } ${className}`}
    >
      <table className="dv-table" {...getTableProps()}>
        <thead className="">
          {headerGroups.map(headerGroup => (
            <tr className="dv-row" {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => {
                return (
                  <th
                    className="dv-table-head"
                    {...column.getHeaderProps()}
                    style={column.styles || {}}
                  >
                    {column.render("Header")}
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>
        {!isLoading && (
          <tbody className="white-bg " {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row)
              return (
                <tr
                  className="dv-row "
                  id={`${index}-${scrollId}`}
                  {...row.getRowProps()}
                >
                  {row.cells.map(cell => {
                    return (
                      <td
                        className="dv-table-cell"
                        style={cell.column?.styles || {}}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        )}
      </table>
      {isLoading && <Loader className="mt-5" />}
      {!hidePagination ? !paginationStyle ? (
        <>
          <div className="table-hr my-2"></div>
          <div className="d-flex align-items-center">
            <span className="pagination-details">
              {`Showing ${(page - 1) * pageSize + 1} to ${data.length + (page - 1) * pageSize
                } of ${count} results`}
            </span>
            <div className="d-flex pagination-btns ms-auto">
              <button onClick={onPrev} className="page-btn">
                Prev
              </button>
              <button onClick={onNext} className="page-btn ms-3">
                Next
              </button>
            </div>
          </div>
        </>) : (
        <>
          <div className="table-hr my-2"></div>

          <div className="d-flex align-items-center justify-content-end">
            <div className="pagination-details me-2">
              {`Page ${(page - 1) * pageSize + 1} of ${count} results`}
            </div>
            <div className="d-flex pagination-btns">
              <Button onClick={onPrev} className="h-32p no-right-border" text={"<"} />
              <Button onClick={onNext} className="h-32p no-left-border" text={">"} />
            </div>
          </div>
        </>
      ) : ''}
    </div>
  )
})

export default Table
