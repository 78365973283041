import React, { useState } from "react"
import { Button } from "react-bootstrap"
import { useParams } from "react-router-dom"
import { Loader } from "../../../../atoms"
import {
  useGetAllBuildings,
  useGetUser
} from "../../../../queries-and-mutations/queries"
import {
  assets,
  getUserTypeStringFromEnum,
  resolveKeyPath,
  UserTypes
} from "../../../../utils"
import "./view-user.scss"

function ViewUser(props) {
  const [authorisedAccessAreas, setAuthArea] = useState(new Array(5).fill({}))

  const { id } = useParams()
  const { data, isFetching } = useGetUser({ id })

  const {
    data: buildingResponse,
    isFetching: buildingLoading
  } = useGetAllBuildings({})

  const buildings = buildingResponse?.data?.results || []
  const { user, user_address } = data?.data?.data || {}

  if (isFetching || buildingLoading) return <Loader className="py-5 my-5" />

  const getDataPointsToDisplay = () => {
    let points = []
    switch (user.user_type) {
      case UserTypes.tenant:
        points = [
          [
            "Building",
            "user_address.building",
            building => buildings.find(e => e.id === building)?.name
          ],
          ["Floor", "user_address.floor_number"],
          ["Unit Number", "user_address.unit_number"]
        ]
        break
      case UserTypes.carrier:
        points = [
          ["Id Number", "id_number"],
          ["Driving License", "driving_licence"]
        ]
        break

      case UserTypes.serviceProvider:
        points = [
          ["Category", "category.name"],
          ["SubCategory", "user_subcategory.name"]
        ]
        break
    }
    return points
  }

  const getDataPoint = arr => {
    const val = resolveKeyPath(data?.data?.data, arr[1])
    return (
      <div className="col-md-6 d-flex flex-column py-3 ">
        <span className="profile-field-head ">{arr?.[0]}</span>
        <span className="profile-field-value">
          {arr?.[2] ? arr[2](val) : val}
        </span>
      </div>
    )
  }

  return (
    <>
      <h2 className="section-heading ">View User</h2>
      <div className="row view-user-screen white-bg">
        <div className="col-md-6 user-profile row">
          <div className="col-md-12 p-4 d-flex flex-column justify-content-center align-items-center">
            <img width={140} className="p-2" src={assets.avatar} alt="avatar" />
            <span className="profile-field-head large">{`${user.first_name} ${user.last_name}`}</span>
            <span className="profile-field-value large">{user?.email}</span>
          </div>
          <div className="table-hr my-5"></div>
          {getDataPointsToDisplay().map(getDataPoint)}
          <div className="col-md-6 d-flex flex-column py-3 ">
            <span className="profile-field-head">User Category</span>
            <span className="profile-field-value">
              {getUserTypeStringFromEnum(user?.user_type)}
            </span>
          </div>
          <div className="col-md-6 d-flex flex-column py-3 ">
            <span className="profile-field-head">Mobile Number</span>
            <span className="profile-field-value">{user?.phone}</span>
          </div>
          <div className="col-md-6 d-flex flex-column py-3 ">
            <span className="profile-field-head">Status</span>
            <span className="profile-field-value">Approved</span>
          </div>
          <div className="w-100 p-5"></div>
        </div>
        <div className="col-md-6 access-area-list ">
          <h4 className="head p-4">Building Name</h4>
          {authorisedAccessAreas.map((_, i) => (
            <div className="d-flex access-area-list-item p-3 align-items-center">
              <span>Access Area 1</span>
              <Button className="give-access-btn ms-auto" variant="outline">
                + Give Access
              </Button>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default ViewUser
