import React, { useEffect, useState } from "react"
import { DropdownDatepicker, Dropdown } from "../../../../../atoms"
import BarChartMinMaxAvg from "../../../../../charts/bar-chart-min-max-avg"
import IndexPalleteBarChartMinMaxAvg from "../../../../../atoms/chart-index-palette/bar-chart-min-max-avg"
import { prepareWaitingChartData } from "../../../../../utils/charts"
import { useGetWaitingTime } from "../../../../../queries-and-mutations/queries"
import { getChartFilters, getDate } from "../../../../../utils"
import ChartHeader from "../../../../../charts/chart-header"
import { useParams } from "react-router-dom"

function WaitingTimeChart(props) {
  const [params, setParams] = useState({})
  const { urlParam1 } = useParams()

  useEffect(() => {
    if (!!urlParam1) {
      document?.body
        ?.querySelector(".daarna-app")
        ?.classList?.add("custom-bg-color")
      document?.documentElement?.style?.setProperty(
        "--app-custom-bg-color",
        atob(urlParam1)
      )
    }
  })

  const [selectedDate, setSelectedDate] = useState(getDate())
  const [reportType, setReportType] = useState(getChartFilters()[0])
  const {
    data: dataElevatorGraph,
    isFetching: isFetchingElevatorGraph
  } = useGetWaitingTime({
    enabled: true,
    params: {
      date: params && params.selectedDate,
      "report-type": params && params.reportType && params.reportType.value
    }
  })

  return (
    <div className="white-bg chart-wrap p-3">
      <ChartHeader
        title="Waiting Time"
        subtitle="Curr. expected waiting time [secs]"
        dateFilter={true}
        filters={getChartFilters()}
        updateParams={q => setParams(q)}
      />
      <div className="text-center position-relative">
        {!isFetchingElevatorGraph && (
          <React.Fragment>
            <BarChartMinMaxAvg
              data={prepareWaitingChartData(
                dataElevatorGraph.data,
                params?.reportType?.value
              )}
              id="owner-wait-time-chart"
            />
            <IndexPalleteBarChartMinMaxAvg className={"mb - 3 py-1"} />
          </React.Fragment>
        )}
      </div>
    </div>
  )
}

export default WaitingTimeChart
