import React, { useEffect, useRef, useState, useMemo, useCallback } from "react"
import { useQueryClient } from "react-query"
import GenericForm from "../../containers/form-composer/generic-form"
import { useModalAPIs } from "../../containers/popup-cotainer/modal-apis"
import {
  useCreateOwnerMutation,
  useEditOwnerMutation
} from "../../queries-and-mutations/mutation"
import {
  extractValuesFromForm,
  resolveKeyPath,
  UserTypes,
  validation
} from "../../utils"

export const AddOwner = ({ user, isEditMode }) => {
  const formref = useRef()
  const { setModalVisibility } = useModalAPIs()
  const [allowSubmit, setBlockSubmit] = useState(false)
  const createUserMutation = useCreateOwnerMutation()
  const editUserMutation = useEditOwnerMutation()
  const queryClient = useQueryClient()

  const commonKeys = [
    "full_name",
    "phone",
    "email",
    ["user_type", "user_type.enum"]
  ]

  const userTypeMenu = useMemo(
    () => [
      { name: "Service Provider", enum: UserTypes.serviceProvider },
      { name: "Tenant", enum: UserTypes.tenant },
      { name: "Carrier", enum: UserTypes.carrier },
      { name: "Owner", enum: UserTypes.owner }
    ],
    []
  )

  const generateDefaultValues = useCallback(() => {
    if (!isEditMode) return null
    let defaultVals = { ...user }
    defaultVals.user_type = userTypeMenu.find(e => e.enum === user.user_type)
    defaultVals.full_name = `${user?.first_name} ${user?.last_name}`
    defaultVals.phone = user?.mobile_number.replace(/ /g, "")
    defaultVals.id_number = user?.userprofile?.id_number
    return defaultVals
  }, [])

  const formFields = [
    {
      label: "Full Name",
      name: "full_name",
      placeholder: "Enter Full Name",
      rules: {
        required: "Full Name required!",
        validate: { ...validation.name }
      }
    },
    {
      label: "Email",
      name: "email",
      placeholder: "Enter Email",
      rules: {
        required: "Email required!",
        validate: { ...validation.email }
      },
      className: "half"
    },
    {
      label: "Mobile Number",
      name: "phone",
      placeholder: "Enter Contact Number",
      rules: {
        required: "Mobile Number required!",
        validate: { ...validation.internationalPhone }
      },
      className: "half"
    }
  ].map(e => ({
    ...e,
    defaultValue: resolveKeyPath(generateDefaultValues(), e.name)
  }))

  const onSubmit = vals => {
    setBlockSubmit(true)
    const commonVals = extractValuesFromForm(commonKeys, vals)
    const [first_name, ...rest] = commonVals.full_name.split(" ")
    const last_name = rest.join(" ")
    if (!isEditMode) {
      createUserMutation.mutate(
        { first_name, last_name, ...commonVals },
        {
          onSuccess: response => {
            alert("Owner Created !")
            setBlockSubmit(false)
            queryClient.invalidateQueries(["ownerlist"])
            setModalVisibility(false)
          },
          onError: err => {
            setBlockSubmit(false)
          }
        }
      )
    } else {
      let editRequestObject = {
        id: user?.id,
        first_name,
        last_name,
        ...extractValuesFromForm(commonKeys, vals)
      }

      editUserMutation.mutate(editRequestObject, {
        onSuccess: response => {
          alert("Owner Updated !")
          setBlockSubmit(false)
          queryClient.invalidateQueries(["ownerlist"])
          setModalVisibility(false)
        },
        onError: err => {
          setBlockSubmit(false)
        }
      })
    }
  }

  return (
    <>
      <h2 className="text-center">
        {isEditMode ? "Edit Owner" : "Add New Owner"}
      </h2>
      {formFields.length ? (
        <GenericForm
          ref={formref}
          submitButtonClass="mt-5"
          formFields={formFields}
          submitButtonText="Save"
          onSubmit={onSubmit}
          logging={false}
          submitDisabled={allowSubmit}
        />
      ) : (
        ""
      )}
    </>
  )
}
