import React, { useState } from "react"
import { useParams } from "react-router-dom"
import IndexPalleteBarChartMinMaxAvg from "../../../../../atoms/chart-index-palette/bar-chart-min-max-avg"
import BarChartMinMaxAvg from "../../../../../charts/bar-chart-min-max-avg"
import ChartHeader from "../../../../../charts/chart-header"
import { useGetWaitingTime } from "../../../../../queries-and-mutations/queries"
import { getChartFilters } from "../../../../../utils"
import { prepareWaitingChartData } from "../../../../../utils/charts"

function DeliveryTimeChart({
  title,
  description,
  colorIndexPalleteClassName
})
{
  const [params, setParams] = useState({})
  const {
    data: dataElevatorGraph,
    isFetching: isFetchingElevatorGraph
  } = useGetWaitingTime({
    enabled: true,
    params: {
      date: params && params.selectedDate,
      "report-type": params && params.reportType && params.reportType.value
    }
  })

  return (
    <div className="white-bg chart-wrap p-3">
      <ChartHeader
        title="Waiting Time"
        subtitle="Curr. expected waiting time [secs]"
        dateFilter={true}
        filters={getChartFilters()}
        updateParams={q => setParams(q)}
      />
      <div className="text-center position-relative">
        {!isFetchingElevatorGraph && (
          <React.Fragment>
            <BarChartMinMaxAvg
              data={prepareWaitingChartData(
                dataElevatorGraph.data,
                params?.reportType?.value
              )}
              id="owner-wait-time-chart"
            />
            <IndexPalleteBarChartMinMaxAvg className={"mb - 3 py-1"} />
          </React.Fragment>
        )}
      </div>
    </div>
  )
}
export default DeliveryTimeChart
