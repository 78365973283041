import React from "react"
import { Col, Row } from "react-bootstrap"

const Legend = ({ keys, colors }) => (
  <Row>
    {keys.map(key => (
      <Col xs={"auto"}>
        <div className="d-flex align-items-baseline ">
          <div
            className="me-1"
            style={{
              width: "30px",
              height: "10px",
              backgroundColor: colors[key]
            }}
          ></div>
          <div style={{ fontSize: "0.7rem" }}>{key}</div>
        </div>
      </Col>
    ))}
  </Row>
)

export default Legend
