import React, { useState, useEffect, useCallback } from "react"
import { Navigate } from "react-router-dom"

function UserRoute({ element }) {
  const [userType, setUserType] = useState(localStorage.getItem("user-type"))

  const storageListener = useCallback(() => {
    setUserType(localStorage.getItem("user-type"))
  }, [])

  useEffect(() => {
    window.addEventListener("user-type-storage", storageListener)
    return () => {
      window.removeEventListener("user-type-storage", storageListener)
    }
  }, [])

  if (!userType) {
    return <Navigate to="/select-user-screen" replace />
  }
  return element
}

export default UserRoute
