import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import { prepareBarChartDataFromApiResponse } from "../../utils/charts"

import BarChartSection from "./bar-chart-section"

import { useGetElevatorGraph } from "../../queries-and-mutations/queries"
import { assets, formatDateToString } from "../../utils"
import { APPCONFIG } from "../../config"

const ChartArea = ({ chartFor = null, chartOptions = {} }) => {
  const { urlChartFor, urlParam1, urlParam2, urlParam3 } = useParams()

  if (!!urlParam3) {
    document?.body
      ?.querySelector(".daarna-app")
      ?.classList?.add("custom-bg-color")
    document?.documentElement?.style?.setProperty(
      "--app-custom-bg-color",
      atob(urlParam3)
    )
  }

  const [applicableParams, setApplicableParams] = useState({})

  useEffect(() => {
    let params = {
      chartFor: urlChartFor || chartFor,
      chartOptions: {
        ...chartOptions,
        week_day: activeFilter
          ? allFilters.findIndex(
              val => val.toLowerCase() === activeFilter.toLowerCase()
            ) + 1
          : 1,
        ...(!!urlChartFor && urlChartFor === "elevator"
          ? {
              elevator_kone_id: urlParam1,
              report_type: urlParam2
            }
          : {})
      }
    }
    setApplicableParams(params)
  }, [chartOptions.report_type, chartOptions.elevator_kone_id])

  const allFilters = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
  ]
  const [activeFilter, setActiveFilter] = useState(allFilters[0])

  useEffect(() => {
    if (activeFilter) {
      let params = {
        chartFor: urlChartFor || chartFor,
        chartOptions: {
          ...chartOptions,
          ...(!!urlChartFor && urlChartFor === "elevator"
            ? {
                elevator_kone_id: urlParam1,
                report_type: urlParam2
              }
            : {}),
          week_day:
            allFilters.findIndex(
              val => val.toLowerCase() === activeFilter.toLowerCase()
            ) + 1
        }
      }

      setApplicableParams(params)
    }
  }, [activeFilter])

  const {
    data: dataElevatorGraph,
    isFetching: isFetchingElevatorGraph
  } = useGetElevatorGraph({
    enabled: !!Object.keys(applicableParams).length,
    params: applicableParams?.chartOptions
  })

  switch (applicableParams.chartFor) {
    case "elevator":
      let chartSettings = {}
      switch (applicableParams.chartOptions.report_type) {
        case "stops":
          chartSettings.iconImage = assets.iconStops
          chartSettings.countCircleLabel = "Stop"
          chartSettings.yAxisLabelSuffix = " stops"
          break
        case "calls":
          chartSettings.iconImage = assets.iconPeople
          chartSettings.countCircleLabel = "Persons"
          chartSettings.yAxisLabelSuffix = " %"
          break
        case "time":
        default:
          chartSettings.iconImage = assets.iconClock
          chartSettings.countCircleLabel = "Secs"
          chartSettings.yAxisLabelSuffix = " sec"
          break
      }
      return (
        <BarChartSection
          title={`Popular Times`}
          showCountCircle={true}
          CountCircleIcon={() => (
            <div
              className="icon"
              style={{ backgroundImage: `url(${chartSettings.iconImage})` }}
            ></div>
          )}
          countCircleValue={Math.ceil(
            isFetchingElevatorGraph
              ? 0
              : dataElevatorGraph?.data?.reduce(
                  (total, item) =>
                    total +
                    (applicableParams.chartOptions.report_type === "stops"
                      ? item.count
                      : item.avg),
                  0
                ) / dataElevatorGraph?.data.length
          )}
          countCircleLabel={chartSettings.countCircleLabel}
          yAxisLabelSuffix={chartSettings.yAxisLabelSuffix}
          loadingData={isFetchingElevatorGraph}
          data={
            !!dataElevatorGraph
              ? prepareBarChartDataFromApiResponse(
                  dataElevatorGraph?.data,
                  applicableParams.chartOptions.report_type
                )
              : []
          }
          allFilters={allFilters}
          activeFilter={activeFilter}
          setActiveFilter={setActiveFilter}
        />
      )
    default:
      return <p>No charts to display</p>
  }
}

export default ChartArea
