import { FormControl, InputGroup, Tab, Tabs } from "react-bootstrap"
import { assets } from "../../../utils"
import First from "./first"
import Second from "./second"
import { useState } from "react"

const AdminDatabase = () => {
  const [query, setQuery] = useState("")

  return (
    <div>
      <h2 className="section-heading">{"Databases"}</h2>
      <div className="d-flex align-items-center">
        <InputGroup className="my-3 w-50">
          <FormControl
            style={{ borderRight: 0 }}
            onChange={e => setQuery(e.target.value)}
            placeholder={"Search Database"}
            className="dashboard-header-search w-50"
          />
        </InputGroup>
        <button className="page-btn ms-auto px-2">
          <img src={assets.downloadIcon} alt="download" />
        </button>
        <button className="page-btn d-flex align-items-center mx-3 px-3">
          <img src={assets.sortIcon} alt="download" />
          <span className="ms-1 me-1">Filter</span>
          <img src={assets.dropdownArrow} alt="" />
        </button>
      </div>
      <Tabs
        defaultActiveKey="first"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="first" title="First">
          <div>
            {" "}
            <First />
          </div>
        </Tab>
        <Tab eventKey="second" title="Second">
          <div>
            {" "}
            <Second />
          </div>
        </Tab>
      </Tabs>
    </div>
  )
}

export default AdminDatabase
