import React from "react"
import { FormControl, InputGroup, Tab, Tabs } from "react-bootstrap"
import './order.scss'
import Incoming from "./incoming"
import Outgoing from "./outgoing"


import { assets } from "../../../../utils"

function Order() {
    const [query, setQuery] = React.useState("")

    return (
        <div>
            <h2 className="section-heading">{"Orders"}</h2>
            <div className="d-flex align-items-center">
                <InputGroup className="my-3 w-50">
                    <FormControl
                        style={{ borderRight: 0 }}
                        onChange={e => setQuery(e.target.value)}
                        placeholder={"Search Orders"}
                        className="dashboard-header-search w-50"
                    />
                </InputGroup>
                <button className="page-btn ms-auto px-2">
                    <img src={assets.downloadIcon} alt="download" />
                </button>
                <button className="page-btn d-flex align-items-center mx-3 px-3">
                    <img src={assets.sortIcon} alt="download" />
                    <span className="ms-1 me-1">Filter</span>
                    <img src={assets.dropdownArrow} alt="" />
                </button>
            </div>
            <Tabs
                defaultActiveKey="incoming"
                id="uncontrolled-tab-example"
                className="mb-3"
            >
                <Tab eventKey="incoming" title="Incoming Orders">
                    <div> <Incoming /></div>
                </Tab>
                <Tab eventKey="outgoing" title="Outgoing Orders">
                    <div> <Outgoing /></div>
                </Tab>
            </Tabs>

        </div>
    )
}

export default Order
