import React, { useEffect, useRef, useState } from "react"
import {
  select,
  scaleBand,
  axisBottom,
  stack,
  max,
  scaleLinear,
  axisLeft,
  stackOrderAscending,
  scaleOrdinal,
  schemeSet2
} from "d3"
import { Col, Row } from "react-bootstrap"
import Legend from "./legends"

/**
 * Component that renders a StackedBarChart
 */

function StackedBarChart({
  id = "stacked-bar-chart-id",
  data,
  keys,
  colors,
  chartW = 550,
  chartH = 324,
  customPaddings = {}
}) {
  const [scaleX, setScalX] = useState(undefined)
  const [scaleY, setScalY] = useState(undefined)
  const paddings = {
    top: 25,
    left: 35,
    right: 25,
    bottom: 70,
    ...customPaddings
  }
  const createScales = () => {
    // stacks / layers
    // Create the stack and group it so that the smallest values are on the bottom
    const stackGenerator = stack().keys(keys) //.order(stackOrderAscending);
    const layers = stackGenerator(data)

    // This is our min/max values
    const extent = [
      0,
      max(layers, layer => max(layer, sequence => sequence[1] + 0.2))
    ]

    // scales
    const xScale = scaleBand()
      .domain(data.map(d => d.key))
      .range([paddings.left, chartW - paddings.right])
      .padding(0.2)

    const yScale = scaleLinear()
      .domain(extent)
      .range([chartH - paddings.bottom, paddings.top])
    return [xScale, yScale]
  }
  const createAxes = () => {
    if (scaleX && scaleY) return [axisBottom(scaleX), axisLeft(scaleY)]
    return []
  }
  useEffect(() => {
    const [x, y] = createScales()
    setScalX(() => x)
    setScalY(() => y)
  }, [data])
  useEffect(() => {
    const [xAxisCall, yAxisCall] = createAxes()
    if (xAxisCall && yAxisCall) {
      const stackGenerator = stack().keys(keys) //.order(stackOrderAscending);
      const layers = stackGenerator(data)
      const svgContainer = select(`svg#${id}`)
      const svg = svgContainer.select("g.container").node()
        ? svgContainer.select("g.container")
        : svgContainer.append("g").attr("class", "container")

      const xAxis = svg.selectAll("g.x-axis").node()
        ? svg.selectAll("g.x-axis")
        : svg.append("g").attr("class", "x-axis")
      xAxis
        .attr("transform", `translate(0, ${chartH - paddings.bottom})`)
        .call(xAxisCall)
        .selectAll("text")
        .style("text-anchor", "center")
        .attr("dx", "-2em")
        .attr("dy", "-1em")
        .attr("transform", "rotate(-90)")

      const yAxis = svg.selectAll("g.y-axis").node()
        ? svg.selectAll("g.y-axis")
        : svg.append("g").attr("class", "y-axis")
      yAxis
        .attr("transform", `translate(${paddings.left},${0})`)
        //.attr("height", chartH)
        .call(yAxisCall)
        .call(g => g.select(".domain").remove())
        .call(g =>
          g
            .selectAll(".tick line")
            .attr("x2", chartW - paddings.left - paddings.right)
            .attr("stroke-opacity", 0.2)
        )

      const groups = svg
        .selectAll(".layer")
        .data(layers)
        .join("g")
        .attr("class", "layer")
        .attr("fill", layer => colors[layer.key])

      groups
        .selectAll("rect")
        .data(layer => layer)
        .join("rect")
        .attr("class", "data-bar")
        .attr("x", sequence => scaleX(sequence.data.key))
        .attr("width", scaleX.bandwidth())
        .attr("y", sequence => scaleY(sequence[1]))
        .transition()
        .duration(1000)
        .attr("height", sequence => {
          if (!isNaN(sequence[0]) && !isNaN(sequence[1])) {
            return scaleY(sequence[0]) - scaleY(sequence[1])
          } else {
            return 0
          }
        })
      groups
        .selectAll("text")
        .data(layer => layer)
        .join("text")
        .attr(
          "x",
          sequence => scaleX(sequence.data.key) + scaleX.bandwidth() / 2 - 10
        )
        .attr("y", sequence => scaleY(sequence[1]) + 10)
        .attr("dy", ".1em")
        .text(function (d) {
          console.log(d)
          return d[1] - d[0]
        })
        .style("font-size", "10px")
        .attr("fill", "black")

      // const svgLabelContainer = select(`svg#${id}-labels`)
      // const svgLabel = svgLabelContainer.select("g.label-container").node()
      //     ? svgLabelContainer.select("g.label-container")
      //     : svgLabelContainer.append("g").attr("class", "label-container")

      // svgLabel
      //     .selectAll("rect")
      //     .data(keys)
      //     .enter()
      //     .append("rect")
      //     .attr("class", ".label-rect")
      //     .attr("x", function (d, i) { return paddings.left + i * 100 })
      //     .attr("width", 35)
      //     .attr("y", 10)
      //     .attr("height", 7)
      //     .attr("fill", function (d, i) { return colors[d] });

      // svgLabel
      //     .selectAll("text")
      //     .data(keys)
      //     .enter()
      //     .append("text")
      //     .attr("class", ".label-text")
      //     .attr("x", function (d, i) { return paddings.left+40 + i * 100 })
      //     .attr("y", 10)
      //     .text(function (d) { return d })
      //     .attr("text-anchor", "left")
      //     .style("alignment-baseline", "middle")
      //     .style("font-size", "10px")
      //     .attr("fill", "black");
    }
  }, [scaleX, scaleY])

  return (
    <React.Fragment>
      <svg
        className="stacked-bar-chart svg-chart"
        id={id}
        viewBox={`0 0 ${chartW} ${chartH}`}
      ></svg>
      <Legend keys={keys} colors={colors} />
    </React.Fragment>
  )
}

export default StackedBarChart
