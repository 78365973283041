import React, { useEffect, useRef, useState } from "react"
import {
  DocumentReaderApi,
  TextFieldType,
  GraphicFieldType
} from "@regulaforensics/document-reader-webclient"
import "react-image-crop/src/ReactCrop.scss"
import { assets } from "../../utils"
import { ALLROUTES } from "../../routes"
import "./id-verfication.scss"
import { DaarnaButton } from "../../atoms"
import { useNavigate } from "react-router-dom"
import { useStoreApis } from "../../store"

const {
  DOCUMENT_NUMBER,
  SURNAME_AND_GIVEN_NAMES,
  DATE_OF_BIRTH
} = TextFieldType

const { PORTRAIT, SIGNATURE } = GraphicFieldType

const DocumentUpload = () => {
  const [image, setImage] = useState(null)
  const nav = useNavigate()

  const { setFaceImage, faceImage } = useStoreApis()

  const fileInput = useRef()
  const handleBrowseImage = () => {
    fileInput.current.click()
  }

  const handleImageSelect = e => {
    setImage(e.target.files[0])
  }

  useEffect(() => {
    if (image) {
      const reader = new FileReader()
      reader.readAsDataURL(image)
      reader.onload = () => {
        setFaceImage(reader.result)
      }
    }
  }, [image])

  return (
    <>
      <input
        onChange={handleImageSelect}
        className="d-none"
        type="file"
        ref={fileInput}
        accept="image/*"
      />

      <div className="d-flex vh-100">
        <div
          style={{ backgroundImage: `url(${assets.loginBackgroundImage})` }}
          className="login-bg d-none d-md-block h-100 w-50 position-relative"
        >
          <div className="backdrop position-absolute h-100 w-100"></div>
          <img
            className="p-4 position-relative"
            src={assets.logoWhite}
            alt="logo"
          />
          <div className="spacer-parent d-flex flex-column">
            <div className="spacer"></div>
            <p className="login-welcome-text position-relative mx-auto text-center">
              Become a certified vendor on DV.
            </p>
          </div>
        </div>
        <div className="w-50 d-flex justify-content-center">
          <div className="w-75 mt-5 text-center">
            <h2 className="text-center w-100">
              Upload front image of your Photo ID
            </h2>
            <div
              style={
                faceImage
                  ? {
                      backgroundImage: `url(${faceImage})`,
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "contain"
                    }
                  : {}
              }
              className="id-image-drag-drop mt-5 mx-auto d-flex flex-column align-items-center justify-content-center"
            >
              {!faceImage && (
                <>
                  <img src={assets.uploadIcon} />
                  <h4 className="text-center my-1 mt-3">
                    Drag image to Upload
                  </h4>
                  <h4 className="text-center my-1">OR</h4>
                  <DaarnaButton
                    onClick={handleBrowseImage}
                    className="mt-1 w-50"
                    text="Browse Files"
                  />
                </>
              )}
            </div>
            <DaarnaButton
              className="mt-5 continue-btn id-verify"
              text={"Continue"}
              disabled={!faceImage}
              onClick={() => {
                nav(ALLROUTES.registrationUploadPhotoIdBack)
              }}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default DocumentUpload
