import axios from "axios"

export const Request = axios.create()

const tokenInterceptor = Request.interceptors.request.use(
  req => {
    const sessionToken = localStorage.getItem("token")
    if (!!sessionToken) {
      req.headers["Authorization"] = "Token " + sessionToken
    }
    return req
  },
  null,
  { synchronous: true }
)

const catchErrors = err => {
  if ([400, 500, 403].find(e => e == err?.response?.status)) {
    window?.customErrorCallback?.(err.response.data.message)
  }
  throw err
}

window.addEventListener("storage", () => {
  if (localStorage.getItem("token")) {
    Request.interceptors.request.eject(tokenInterceptor)
    Request.interceptors.request.use(tokenInterceptor)
  }
})

export const post = (url, data, config) => {
  return Request.post(url, data, config).catch(catchErrors)
}

export const patch = (url, data, config) => {
  return Request.patch(url, data, config).catch(catchErrors)
}

export const del = (url, data, config) => {
  return Request.delete(url, data, config).catch(catchErrors)
}

export const get = (url, config) => {
  return Request.get(url, config).catch(catchErrors)
}

export const put = (url, config) => {
  return Request.put(url, config).catch(catchErrors)
}
