import React, { useState } from "react"
import ChartHeader from "../../../../../charts/chart-header"

import StackedBarChart from "../../../../../charts/stacked-bar-chart"

const WaitTime = () => {
  const colors = {
    ["wait time. 3Mins"]: "#3F4589",
    ["wait time. 4Mins"]: "#2196F3"
  }
  const data = [
    {
      key: "10am",
      ["wait time. 3Mins"]: 100,
      ["wait time. 4Mins"]: 120
    },
    {
      key: "11am",
      ["wait time. 3Mins"]: 85,
      ["wait time. 4Mins"]: 75
    },
    {
      key: "12pm",
      ["wait time. 3Mins"]: 100,
      ["wait time. 4Mins"]: 90
    },
    {
      key: "1pm",
      ["wait time. 3Mins"]: 100,
      ["wait time. 4Mins"]: 120
    },
    {
      key: "2pm",
      ["wait time. 3Mins"]: 85,
      ["wait time. 4Mins"]: 75
    },
    {
      key: "3pm",
      ["wait time. 3Mins"]: 100,
      ["wait time. 4Mins"]: 90
    }
  ]
  const keys = ["wait time. 3Mins", "wait time. 4Mins"]
  const filters = [
    {
      name: "Time",
      value: "time"
    },
    {
      name: "Building",
      value: "building"
    },
    {
      name: "Delay time",
      value: "delay-time"
    }
  ]

  return (
    <div className="white-bg chart-wrap p-3">
      <ChartHeader
        title="Wait Time by Day - Delays"
        subtitle="Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi suscipit mollitia officia corrupti."
        dateFilter={true}
        filters={filters}
      />
      <div className="text-center position-relative">
        <StackedBarChart
          colors={colors}
          data={data}
          keys={keys}
          id={"wait-time-by-day-chart"}
        />
      </div>
    </div>
  )
}

export default WaitTime
