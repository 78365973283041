import { APPCONFIG } from "../config"

export const prepareBarChartDataFromApiResponse = (data, display) => {
  if (APPCONFIG.debugJsCharts) {
    console.log(
      "[prepareBarChartDataFromApiResponse]",
      "data",
      data,
      "display",
      display
    )
  }
  let latestDate = data[0]?.date || null
  let graphDataOnLatestDate = []
  const prepareBarGraphRecord = data => {
    const { elevator_kone_id, hour, ...barData } = data
    const recDate = new Date(`${latestDate} ${hour}`)
    const recHour = recDate.getHours()
    const rec = {
      bandName: `${recHour > 12 ? recHour % 12 : recHour}${
        recHour > 12 ? "pm" : "am"
      }`,
      bars: [
        display === "stops"
          ? { min: barData.count, avg: barData.count, max: barData.count }
          : { ...barData }
      ]
    }
    return rec
  }
  data.forEach((rec, i) => {
    if (rec.date === latestDate) {
      graphDataOnLatestDate.push(prepareBarGraphRecord(rec))
    }
    if (new Date(rec.date).getTime() > new Date(latestDate).getTime()) {
      latestDate = rec.date
      graphDataOnLatestDate = [prepareBarGraphRecord(rec)]
    }
  })
  if (APPCONFIG.debugJsCharts) {
    console.log(graphDataOnLatestDate)
  }
  return graphDataOnLatestDate
}

export const prepareWaitingChartData = (data, display) => {
  switch (display){
    case "time":
        return _getDataForTime(data)
    case "category":
        return _getDataForCategory(data)
    case "area":
      return _getDataForArea(data)
    default:
      return _getDataForTime(data)
  }
}

const _getDataForTime = (data) => {
  return data.map(row=>{
    return {
      bandName: row.building,
      bars: [{min: row.min,avg: row.avg,max: row.max}]
    }
  })
}
const _getDataForCategory = (data) => {
  return data.map(row => {
    return {
      bandName: row.category,
      bars: [{ min: row.min, avg: row.avg, max: row.max }]
    }
  })
}
const _getDataForArea = (data) => {
  return data.map(row => {
    return {
      bandName: row.start_floor,
      bars: [{ min: row.min, avg: row.avg, max: row.max }]
    }
  })
}
