import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { APPCONFIG } from "../../config"
import CenteredContainer from "../../containers/centered-container/centered-container"
import GenericForm from "../../containers/form-composer/generic-form"
import {
  useGetCategories,
  useGetSubCategories
} from "../../queries-and-mutations/queries"
import {
  useCreateUserMutation,
  useLoginMutation,
  useSendOtpMutation
} from "../../queries-and-mutations/mutation"
import {
  assets,
  extractValuesFromForm,
  getUserEnum,
  UserTypes,
  validation
} from "../../utils"
import { ALLROUTES } from "../../routes"
import { useSignupApis } from "../../store"
import "../../assets/styles/common.scss"
import "./registration.scss"
import Gmap from "../../utils/gmap"

const Registration = () => {
  const nav = useNavigate()
  const { formValues, setFormValues } = useSignupApis()
  const [appUser, setAppUser] = useState(
    getUserEnum() || UserTypes.serviceProvider
  )
  const [renderedFields, setRenderedFields] = useState([])
  const [formIsValid, setFormIsValid] = useState(false)
  const formRef = useRef()
  const [selectedCat, setSelectedCat] = useState(null)
  const { data: categoryResponse } = useGetCategories({})
  const {
    data: subCategoryResponse,
    isFetching: subCatLoading
  } = useGetSubCategories(selectedCat?.id, {
    enabled: selectedCat?.id ? true : false
  })

  // useEffect(() => {
  //   const categories =
  //     categoryResponse?.data?.data?.filter(e =>
  //       !!appUser ? e?.user_type == appUser : false
  //     ) || []
  //   console.log("categories", categories)
  //   console.log("categoryResponse", categoryResponse)
  //   const subCategories = subCategoryResponse?.data?.data || []
  //   setCategories([...categories])
  //   setSubCategories(subCategories)
  // }, [appUser, categoryResponse, subCategoryResponse])

  const formFields = useMemo(
    () => [
      {
        type: "field",
        name: "first_name",
        label: "First Name",
        rules: {
          required: "Please enter your first name"
        }
      },
      {
        type: "field",
        name: "last_name",
        label: "Last Name",
        rules: {
          required: "Please enter your last name"
        }
      },
      {
        type: "field",
        name: "email",
        label: "Email address",
        rules: {
          required: "Please enter your email address",
          validate: { ...validation.email }
        }
      },
      {
        type: "field",
        name: "phone",
        label: "Mobile number",
        rules: {
          required: "Please enter your mobile number",
          validate: { ...validation.username }
        }
      },
      {
        type: "field",
        name: "trade_name",
        label: "Entity Trade Name",
        rules: {
          required: "Please enter your entity trade name"
        }
      },
      {
        type: "field",
        name: "trade_license_number",
        label: "Trade License Number",
        rules: {
          required: "Please enter your trade license number"
        }
      },
      {
        type: "field",
        name: "corporate_email",
        label: "Corporate Email Address",
        rules: {
          required: "Please enter your corporate email address",
          validate: { ...validation.email }
        }
      },
      {
        label: "Category",
        name: "category",
        placeholder: "Select Category",
        compType: "dropdown",
        menu: categoryResponse?.data?.data || [],
        className: `half`,
        onChange: cat => {
          if (cat && cat?.id !== selectedCat?.id) {
            formRef.current.setValue("sub_category", null)
            setSelectedCat(cat)
          }
        },
        rules: { required: "required" }
      },
      ,
      {
        label: "Sub-category",
        name: "sub_category",
        placeholder: "Select Sub-category",
        compType: "dropdown",
        menu: subCategoryResponse?.data?.data || [],
        className: `half`,
        rules: { required: "required" }
      },
      /*{
    compType: "dropdown",
    name: "purpose_of_visit",
    label: "Purpose of Visit",
    menu: purposeOfVisitMenu,
    rules: {
      required: "Please select the purpose of visit"
    }
  },*/
      {
        name: "street_address",
        label: "Establishment Street Address",
        rules: {
          required: "Please enter the establishment street address"
        }
      },
      {
        name: "location",
        label: "Establishment Location - Pin in Map",
        rules: {
          required: "Please enter the establishment location"
        }
      }
    ],

    [appUser, categoryResponse?.data?.data, subCategoryResponse?.data?.data]
  )

  const generateDefaultValues = useCallback(() => {
    let defaultVals = {}
    //defaultVals.category = ""
    //defaultVals.sub_category = ""
    return defaultVals
  }, [])

  const commonKeys = ["phone", "email", ["user_type", "user_type.enum"]]

  const catKeys = [
    ["category", "category.id"],
    ["user_subcategory", "sub_category.id"]
  ]

  const addressKeys = [
    "user_address",
    ["user_address.building", "user_address.building.id"]
  ]

  const carrierKeys = ["driving_licence", "id_number"]

  const createUserMutation = useCreateUserMutation()

  const updateLocation = val => {
    formRef.current && formRef.current.setValue("location", val)
  }

  const onSubmit = formValues => {
    console.log("formValues", formValues)
    let userTypeBasedVals = {}
    switch (appUser) {
      case UserTypes.carrier:
        userTypeBasedVals = {
          userprofile: extractValuesFromForm(carrierKeys, formValues)
        }
        break

      case UserTypes.serviceProvider:
      case UserTypes.owner:
        userTypeBasedVals = {
          userprofile: extractValuesFromForm(catKeys, formValues)
        }
        break
      case UserTypes.tenant:
        userTypeBasedVals = extractValuesFromForm(addressKeys, formValues)
        break
    }
    const {
      first_name,
      last_name,
      email,
      phone,
      location,
      ...restOfTheValues
    } = formValues
    const requestParams = {
      first_name,
      last_name,
      email,
      phone,
      user_type: Number(localStorage.getItem("user-type")),
      userprofile: {
        ...restOfTheValues,
        purpose_of_visit: "TBD",
        ...userTypeBasedVals?.userprofile,
        location: location ? location.toString() : ""
      }
    }
    delete requestParams.userprofile.sub_category
    console.log("requestParams", requestParams)

    setFormValues(requestParams)
    nav(ALLROUTES.registrationUploadPhotoIdFront)
  }

  return (
    <Container fluid={true} className="d-flex vh-100 no-gutter">
      <Row className="flex-grow-1">
        <Col md={6} className="position-relative">
          <div className="login-bg primary-bg vh-100 d-flex flex-column justify-content-between position-sticky top-0">
            <div className="backdrop position-absolute h-100 w-100"></div>
            <div className="p-4">
              <img
                className="p-4 position-relative"
                src={assets.logoWhite}
                alt="logo"
              />
            </div>
            <p className="login-welcome-text pb-5 mb-5 position-relative mx-auto text-center">
              Become a certified vendor on DV.
            </p>
          </div>
        </Col>
        <Col md={6} className="white-bg login-form-container">
          <CenteredContainer containerClass="login-form mt-2 mt-sm-5">
            <h1 className="fs-36">Sign In to {APPCONFIG.appPublicName}</h1>
            <p className="welcome-text-form fs-18">
              Please fill the form below to register with us.
            </p>
            <GenericForm
              submitButtonText={"Continue"}
              className="mt-5"
              formFields={formFields}
              submitButtonClass="mt-5 custom-submit"
              onSubmit={onSubmit}
              submitDisabled={formIsValid}
              ref={formRef}
              logging={false}
            />
            <div className="map-container">
              <Gmap
                location={{
                  address:
                    "1600 Amphitheatre Parkway, Mountain View, california.",
                  lat: 37.42216,
                  lng: -122.08427
                }}
                zoomLevel={17}
                updateLocation={loc => updateLocation(loc)}
              />
            </div>
          </CenteredContainer>
          <p className="mt-5 pt-3 text-center login-terms">
            <a>{"Terms & Privacy Policy"}</a>
          </p>
        </Col>
      </Row>
    </Container>
  )
}

export default Registration
