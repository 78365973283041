import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { Nav } from "react-bootstrap"

import { assets, getUserEnum, UserTypes } from "../../../../utils"

import DeliveryTimeChart from "../home/sp-charts/delivery-time-chart"

import "./services.scss"

function ScreenDashServicesDigitalLocker(props) {
  const nav = useNavigate()
  const userEnum = getUserEnum()
  const isServiceProvider = userEnum === UserTypes.serviceProvider
  const isOwner = userEnum === UserTypes.owner

  const subTabs = [
    {
      slug: "all-lockers",
      title: "All Lockers"
    },
    {
      slug: "available-lockers",
      title: "Available Lockers"
    }
  ]

  const [activeSubTab, setActiveSubTab] = useState(subTabs[0])

  return (
    <div className="primary-tab-content px-md-3 px-lg-3" id="elevator-status">
      <div className="d-flex align-items-center justify-content-between mb-4">
        <h3 className="mb-0">Current Capacity</h3>
        <div className="building-label d-flex">
          <div
            className="icon icon-19 me-3"
            style={{ backgroundImage: `url(${assets.buildingIcon})` }}
          />
          Looms Building
        </div>
      </div>

      <div className="d-flex jusify-content-center">
        <Nav className="sub-tabs pills d-inline-flex mx-auto mb-3 mt-5">
          {subTabs.map((tab, i) => (
            <Nav.Item
              className={`pill${
                tab.slug === activeSubTab.slug ? " active" : ""
              }`}
              title={tab.title}
              key={i}
            >
              <Nav.Link
                onClick={() => {
                  setActiveSubTab(tab)
                }}
              >
                {tab.title}
              </Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
      </div>

      {subTabs.map((tab, i) => (
        <div
          className={`pill-tab-content${
            tab.slug === activeSubTab.slug ? " active" : ""
          }`}
          key={i}
        >
          <div className="count-circle mx-auto mt-4">
            <div
              className="icon"
              style={{ backgroundImage: `url(${assets.peopleIcon})` }}
            ></div>
            <div className="count">{4 - i}</div>
            <div className="entity">Lockers</div>
          </div>
        </div>
      ))}

      <div className="d-flex chart-container">
        <div className="chart-wrap d-flex flex-column">
          <DeliveryTimeChart title="Popular Times" description="22/05/2022" />
        </div>
      </div>
    </div>
  )
}

export default ScreenDashServicesDigitalLocker
