import React, { useEffect, useState } from "react"
import { Map, GoogleApiWrapper, Marker } from "google-maps-react"
import "./map.css"
import { APPCONFIG } from "../config"

const Gmap = props => {
  let [currentPosition, setCurrentPosition] = useState()

  useEffect(() => {
    navigator.geolocation &&
      navigator.geolocation.getCurrentPosition(pos =>
        updateCurrentPosition(pos)
      )
  }, [])

  useEffect(() => {
    currentPosition &&
      props.updateLocation([currentPosition.lat, currentPosition.lng])
  }, [currentPosition])

  let onMarkerDragEnd = coord => {
    const { latLng } = coord
    const lat = latLng.lat()
    const lng = latLng.lng()
    setCurrentPosition({ lat, lng })
  }

  const updateCurrentPosition = pos => {
    const crd = pos.coords
    setCurrentPosition({
      lat: crd.latitude,
      lng: crd.longitude
    })
  }
  const mapStyles = {
    height: "250px"
  }
  return (
    <div className="map">
      {currentPosition && (
        <div className="google-map">
          <Map
            google={props.google}
            zoom={14}
            style={mapStyles}
            className="centered-content"
            initialCenter={currentPosition}
          >
            <Marker
              id={"location-marker"}
              position={{
                lat: currentPosition.lat,
                lng: currentPosition.lng
              }}
              onClick={() => console.log("Clicked")}
              draggable={true}
              onDragend={(t, map, coord) => onMarkerDragEnd(coord)}
            />
          </Map>
        </div>
      )}
    </div>
  )
}
export default GoogleApiWrapper({
  apiKey: APPCONFIG.mapKey
})(Gmap)
