import React, { useEffect, useState } from "react"
import { Dropdown, DropdownDatepicker } from "../atoms"
import { getChartFilters, getDate } from "../utils"

const ChartHeader = ({
  title,
  subtitle,
  dateFilter,
  filters,
  updateParams
}) => {
  const [selectedDate, setSelectedDate] = useState(getDate())
  const [reportType, setReportType] = useState(getChartFilters()[0])

  useEffect(() => {
    updateParams && updateParams({ selectedDate, reportType })
  }, [selectedDate, reportType])
  return (
    <div className="d-flex align-items-start mb-4">
      <div className="chart-description w-50">
        <h3>{typeof title === "function" ? title() : title}</h3>
        <p>{subtitle}</p>
      </div>
      <div className="w-50 d-flex flex-column gap-2">
        {dateFilter && (
          <DropdownDatepicker
            className=""
            onChange={d => setSelectedDate(getDate(d))}
          />
        )}
        <Dropdown
          className="no-hover-highlight no-select-highlight"
          menu={filters}
          onChange={e => {
            setReportType(e)
          }}
          selectedOption={reportType}
          placeholder={`Filter By`}
          showNullOption={false}
        />
      </div>
    </div>
  )
}

export default ChartHeader
