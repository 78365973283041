import React, { useRef } from "react"
import { useNavigate } from "react-router-dom"
import CenteredContainer from "../../containers/centered-container/centered-container"
import GenericForm from "../../containers/form-composer/generic-form"
import { useAdminLoginMutation } from "../../queries-and-mutations/mutation"

import { assets, LocalStorage, setUserEnum } from "../../utils"

const AdminLogin = () => {
  const formRef = useRef()
  const nav = useNavigate()
  const adminLoginMutation = useAdminLoginMutation()
  const formFields = [
    {
      type: "field",
      name: "username",
      prefix: (
        <div className="ps-2">
          <img src={assets.profileIcon} />
        </div>
      ),
      placeholder: "Username",
      rules: {
        required: "Username required!"
      }
    },
    {
      type: "password",
      name: "password",
      prefix: (
        <div className="ps-2">
          <img src={assets.profileIcon} />
        </div>
      ),
      placeholder: "Password",
      rules: {
        required: "Password required!"
      }
    }
  ]

  const onSubmit = values => {
    adminLoginMutation.mutate(values, {
      onSuccess: res => {
        const { token, user } = res.data.data
        LocalStorage.write("token", token)
        LocalStorage.write("user", user)
        setUserEnum(5)
        nav("/dashboard/admin-users")
      },
      onError: err => {
        console.log("Error log in", err)
      }
    })
  }

  return (
    <div className="d-flex vh-100">
      <div
        style={{ backgroundImage: `url(${assets.loginBackgroundImage})` }}
        className="login-bg d-none d-md-block h-100 w-50 position-relative"
      >
        <div className="backdrop position-absolute h-100 w-100"></div>
        <img
          className="p-4 position-relative"
          src={assets.logoWhite}
          alt="logo"
        />
        <div className="spacer-parent d-flex flex-column">
          <div className="spacer"></div>
          <p className="login-welcome-text position-relative mx-auto text-center">
            Welcome on DV.
          </p>
        </div>
      </div>
      <div className="h-100 w-100 p-4 p-sm-0 w-50 white-bg py-5 login-form-container overflow-scroll">
        <CenteredContainer containerClass="login-form mt-2 mt-sm-5">
          <h4>Login To DV</h4>
          <p className="welcome-text-form">Welcome back!</p>
          <GenericForm
            submitButtonText={"Login"}
            className="mt-5"
            formFields={formFields}
            submitButtonClass="mt-5"
            onSubmit={onSubmit}
            ref={formRef}
            logging={false}
          ></GenericForm>
        </CenteredContainer>
      </div>
    </div>
  )
}

export default AdminLogin
