import React from "react"
import BackArrowNoTail from "../../../../atoms/back-arrow-no-tail"
import { assets } from "../../../../utils"
import "./accept-code.scss"

function AcceptAccess(props) {
  return (
    <>
      <div className="d-flex">
        <BackArrowNoTail />
        <h2 className="section-heading">Access</h2>
      </div>
      <div className="row g-4">
        <div className="col-md-8 d-flex align-items-center white-bg">
          <img width={140} className="p-2" src={assets.avatar} alt="avatar" />
          <div className="d-flex flex-column p-3">
            <h4 className="access-code-name py-2">Amanda James</h4>
            <span className="small-label">Carrier</span>
            <span className="delivery-address">
              No 126, st Paul street, LA, USA
            </span>
            <div className="d-flex py-3 carrier-call-and-email align-items-center">
              <span className="carrier-contact-text">
                <img src={assets.callIcon} className="p-2" alt="" />
                1800 267 0556
              </span>
              <span className="carrier-contact-text ms-auto">
                <img className="p-2" src={assets.mailIcon} alt="" />
                amass@dafda.com
              </span>
            </div>
          </div>
        </div>
        <div className="col-md-4 white-bg">
          <img src={assets.mockQRcode} className="p-1" />
        </div>
        <div className="col-md-12 p-3 white-bg">
          <div className="map-image-placeholder white-bg"></div>
          <div className="p3">
            <h4 className="delivery-notes-head">Delivery Notes</h4>
            <p className="delivery-notes">
              Amet minim mollit non deserunt ullamco est sit aliqua dolor do
              amquat duis enim velit mollit. Exercitation veniam consequat sunt
              nostrud amet.mollit. Exercitation veniam consequat sunt nostrud
              amet.
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default AcceptAccess
