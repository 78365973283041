import React, { useMemo, useState } from "react"
import { Button } from "react-bootstrap"
import { useLocation, useNavigate } from "react-router-dom"
import { svgIcons } from "../assets/svgIcons"
import { DaarnaButton } from "../atoms"
import { assets, getUserEnum, UserTypes } from "../utils"
import "./molecules-styles/side-nav.scss"
import { useAdminLogoutMutation } from "../queries-and-mutations/mutation"

const {
  SettingsIcon,
  ProfileIcon,
  ServicesIcon,
  OrdersIcon,
  ReviewsNavIcon,
  AcceptAccessNavIcon,
  HomeNavIcon,
  ChartIcon,
  AnalyticsIcon,
  ApproveAccountIcon,
  SendAccessIcon,
  UserIcon,
  FeedbackIcon
} = svgIcons

const SideNav = () => {
  const nav = useNavigate()
  const { pathname } = useLocation()
  const adminLogoutMutation = useAdminLogoutMutation()

  const navItems = useMemo(
    () => [
      {
        icon: HomeNavIcon,
        title: "Dashboard",
        href: "/dashboard/home",
        roles: [UserTypes.serviceProvider, UserTypes.owner]
      },
      {
        icon: AcceptAccessNavIcon,
        title: "Accept Access",
        href: "/dashboard/accept-access",
        roles: [UserTypes.serviceProvider]
      },
      {
        icon: ReviewsNavIcon,
        title: "Review",
        href: "/dashboard/order-review",
        roles: [UserTypes.serviceProvider]
      },
      {
        icon: ReviewsNavIcon,
        title: "Carrier Feedback",
        href: "/dashboard/carrier-feedback",
        roles: [UserTypes.serviceProvider]
      },
      {
        icon: OrdersIcon,
        title: "Orders",
        href: "/dashboard/orders",
        roles: [UserTypes.serviceProvider]
      },
      {
        icon: ChartIcon,
        title: "Carrier Performance",
        href: "/dashboard/carrier-performance",
        roles: [UserTypes.serviceProvider]
      },
      {
        icon: ServicesIcon,
        title: "Services",
        href: "/dashboard/services",
        roles: [UserTypes.serviceProvider]
      },
      {
        icon: SendAccessIcon,
        title: "Send Access",
        href: "/dashboard/send-access",
        roles: [UserTypes.owner]
      },
      {
        icon: ApproveAccountIcon,
        title: "Approve Account",
        href: "/dashboard/approve-account",
        roles: [UserTypes.owner]
      },
      {
        icon: UserIcon,
        title: "Users",
        href: "/dashboard/users",
        roles: [UserTypes.owner]
      },
      {
        icon: AnalyticsIcon,
        title: "Analytics",
        href: "/dashboard/analytics",
        roles: [UserTypes.owner]
      },
      {
        icon: FeedbackIcon,
        title: "Feedback",
        href: "/dashboard/feedback",
        roles: [UserTypes.owner]
      },
      {
        icon: ProfileIcon,
        title: "Profile",
        href: "/dashboard/profile",
        roles: [UserTypes.serviceProvider]
      },
      {
        icon: SettingsIcon,
        title: "Setting",
        href: "/dashboard/settings",
        roles: [UserTypes.serviceProvider, UserTypes.owner]
      },
      {
        icon: UserIcon,
        title: "Users",
        href: "/dashboard/admin-users",
        roles: [5]
      },
      {
        icon: AnalyticsIcon,
        title: "Analytics",
        href: "/dashboard/admin-analytics",
        roles: [5]
      },
      {
        icon: UserIcon,
        title: "Owners",
        href: "/dashboard/admin-owners",
        roles: [5]
      },
      {
        icon: AnalyticsIcon,
        title: "Database",
        href: "/dashboard/admin-database",
        roles: [5]
      }
    ],
    []
  )

  const getLogoutText = () => (
    <span>
      <img src={assets.logoutIcon} /> <span className="px-2">Logout</span>
    </span>
  )

  return (
    <div className="dv-side-nav white-bg vh-100 d-flex flex-column">
      <div className="logo-container p-3 my-5 d-flex">
        <img src={assets.daarnaLogo} alt="logo" />
        <img className="ms-3" src={assets.dvTextIcon} alt="text" />
      </div>
      <div className="d-flex flex-column nav-content ">
        {navItems
          .filter(e => !e.roles || e.roles.includes(getUserEnum()))
          .map((Item, index) => {
            const isSelected = pathname.includes(Item.href)
            return (
              <div
                key={index}
                onClick={() => {
                  nav(Item.href)
                  Item?.onClick?.()
                }}
                className={`d-flex dv-nav align-items-center py-3 my-1 pe-2 ms-3 justify-content-start ${
                  isSelected ? "selected" : ""
                }`}
              >
                <Item.icon
                  fill={
                    isSelected ? "var(--selected-nav-icon-color)" : undefined
                  }
                />
                <span className="ms-3">{Item.title}</span>
              </div>
            )
          })}
      </div>
      <div className="mt-auto ">
        <DaarnaButton
          onClick={() => {
            setTimeout(() => {
              localStorage.clear()
              window.dispatchEvent(new Event("storage"))
              getUserEnum() === 5 && nav("/admin/login")
            }, 500)
            getUserEnum() === 5 && adminLogoutMutation.mutate()
          }}
          className="px-0"
          text={getLogoutText()}
        />
      </div>
    </div>
  )
}

export default SideNav
