import React, { useState } from "react"
import { useNavigate } from "react-router-dom"

import { ALLROUTES } from "../../../../routes"
import { assets, getUserEnum, UserTypes } from "../../../../utils"
import BackArrowNoTail from "../../../../atoms/back-arrow-no-tail"

import ScreenDashServicesElevatorStatus from "./services-elevator-status"
import ScreenDashServicesDigitalLocker from "./services-digital-locker"

import "./services.scss"

function ScreenDashServices(props) {
  const nav = useNavigate()
  const userEnum = getUserEnum()
  const isServiceProvider = userEnum === UserTypes.serviceProvider
  const isOwner = userEnum === UserTypes.owner

  const primaryTabs = [
    {
      slug: "elevator-status",
      title: "Elevator Status",
      bgImage: assets.TileBgElevatorStatus
    },
    {
      slug: "digital-locker",
      title: "Digital Locker",
      bgImage: assets.TileBgDigitalLocker
    },
    {
      slug: "parking-status",
      title: "Parking status",
      bgImage: assets.TileBgParkingStatus
    },
    {
      slug: "sales-and-leasing",
      title: "Sales and leasing",
      bgImage: assets.TileBgSalesNLeasing,
      disabled: true
    }
  ]

  const [activePrimaryTab, setActivePrimaryTab] = useState(primaryTabs[0])

  return (
    <>
      <div className="page-heading">
        <h2 className="section-heading">
          <BackArrowNoTail />
          Services
        </h2>
        <div className="breadcrumb">
          <a
            className="clickable"
            onClick={() => {
              nav(ALLROUTES.dashboard.home)
            }}
          >
            Dashboard
          </a>
          <span>/</span>
          <span className="current-page">Services</span>
        </div>
      </div>
      <div className="row g-4 mt-0 white-panel">
        <div className="col-md-5 mt-0 py-4 position-relative">
          <div className="separator right d-none d-md-block d-lg-block"></div>
          <div className="px-md-3 px-lg-3">
            <div className="d-flex flex-column">
              {primaryTabs.map((tab, i) => (
                <button
                  className={`tile-button w-img${
                    tab.slug === activePrimaryTab?.slug ? " active" : ""
                  }`}
                  type="button"
                  key={i}
                  onClick={() => {
                    setActivePrimaryTab(tab)
                  }}
                  disabled={!!tab?.disabled}
                >
                  <div
                    className="img"
                    style={{
                      backgroundImage: `url(${tab.bgImage})`
                    }}
                  ></div>
                  {tab.title}
                </button>
              ))}
            </div>
          </div>
        </div>

        <div className="col-md-7 mt-0 py-4">
          {activePrimaryTab?.slug === primaryTabs[0]?.slug && (
            <ScreenDashServicesElevatorStatus />
          )}
          {activePrimaryTab?.slug === primaryTabs[1]?.slug && (
            <ScreenDashServicesDigitalLocker />
          )}
        </div>
      </div>
    </>
  )
}

export default ScreenDashServices
