import { Button, Col, Container, Dropdown, Row } from "react-bootstrap"
import BackArrowNoTail from "../../../../atoms/back-arrow-no-tail"
import { assets } from "../../../../utils"

function OrderDetail() {

    return (
        <div>
            <div className="d-flex justify-content-start">
                <BackArrowNoTail />
                <h2 className="section-heading">Order ID #1234</h2>
            </div>
            <div className="d-flex align-items-center justify-content-end mb-2">
                <div className="me-2">
                    <Dropdown>
                        <Dropdown.Toggle id="dropdown-basic" variant="light">
                            Assign Driver
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item href="#">#</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <div>
                    <Button variant="custom">Request Access</Button>
                </div>
            </div>
            <Container className="white-bg pb-4">
                <Row>
                    <Col lg="4" className="border p-2 text-center">
                        <Container>
                            <Row>
                                <Col><img src={assets.OrderDetail} alt="orderdetail" /></Col>
                            </Row>
                            <Row>
                                <Col>Akapo Bridget</Col>
                            </Row>
                            <Row>
                                <Col> Email: Timmy@gmail.com</Col>
                            </Row>
                            <Row>
                                <Col>+234870 990 454</Col>
                            </Row>
                        </Container>
                    </Col>
                    <Col className="border p-2">
                        <Container>
                            <Row>
                                <Col>
                                    <h3>DELIVERY NOTE</h3>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    Euismod lectus viverra nibh pretium id. sit  donec commodo et, natoque sit sed, viverra nibh pretium id. Donec commodo . Euismod lectus viverra nibh pretium id. sit  donec commodo et, natoque sit sed, viverra nibh pretium id. Donec commodo .
                                </Col>
                            </Row>
                        </Container>
                        <Container className="mt-5">
                            <Row>
                                <Col>
                                    <h3>DELIVERY ADDRESS</h3>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    2927 Westhemier Jackson Street. Block 5, Floor 4, Flat 15
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
                <Row className="mt-5">
                    <h3>ORDERED ITEMS</h3>
                    <Container className="mt-5">
                        <Row>
                            <Col>
                                <div className="d-flex">
                                    <div>
                                        <img src={assets.OrderItem1} alt="orderitem" />
                                    </div>
                                    <div className="ms-2">
                                        <h6>Spicy Grilled Turkey</h6>
                                        <span>5 Packs</span>
                                    </div>
                                </div>
                            </Col>
                            <Col>
                                <div className="d-flex">
                                    <div>
                                        <img src={assets.OrderItem2} alt="orderitem" />
                                    </div>
                                    <div className="ms-2">
                                        <h6>Spicy Grilled Turkey</h6>
                                        <span>5 Packs</span>
                                    </div>
                                </div>
                            </Col>
                            <Col>
                                <div className="d-flex">
                                    <div>
                                        <img src={assets.OrderItem1} alt="orderitem" />
                                    </div>
                                    <div className="ms-2">
                                        <h6>Spicy Grilled Turkey</h6>
                                        <span>5 Packs</span>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Row>
            </Container>
        </div>
    )
}

export default OrderDetail