import React from "react"
import { FormControl, InputGroup, Spinner } from "react-bootstrap"
import Button from "../../../../atoms/button"
import { Table } from "../../../../molecules"
import { useGetReviews } from "../../../../queries-and-mutations/queries"
import { assets } from "../../../../utils"

function OrderReview() {
  const [query, setQuery] = React.useState("")
  const [page, setPage] = React.useState(1)
  const { data, isLoading } = useGetReviews(
    page,
    query,
    10, //page size
    {}
  )
  const { results = [], count, previous, next } = data?.data || {}

  const onNext = () => {
    setPage(prev => (next ? prev + 1 : prev))
  }
  const onPrev = () => {
    setPage(prev => (previous ? prev - 1 : prev))
  }
  const columns = React.useMemo(
    () => [
      {
        Header: "Carrier Name",
        styles: { textAlign: "left", paddingLeft: "42px" },
        accessor: cell =>
          cell?.delivery?.sender?.first_name +
          " " +
          cell?.delivery?.sender?.last_name
      },
      {
        Header: "User Email",
        accessor: cell => cell?.delivery?.receiver?.email
      },
      {
        Header: "Order Details",
        accessor: cell => cell?.delivery?.delivery_item
      },
      {
        Header: "Order Time",
        accessor: cell => new Date(cell?.delivery?.created_on).toUTCString()
      },
      {
        Header: "Delivery Time",
        accessor: cell =>
          new Date(
            `${cell?.delivery?.delivery_date} ${cell?.delivery?.delivery_time}`
          ).toUTCString()
      },
      {
        Header: "Action",
        accessor: () => (
          <div>
            <Button className="h-32p" text="View" />
          </div>
        )
      }
    ],
    []
  )

  return (
    <div>
      <h2 className="section-heading">{"Reviews"}</h2>
      <div className="d-flex align-items-center">
        <InputGroup className="my-3 w-50">
          <FormControl
            style={{ borderRight: 0 }}
            onChange={e => setQuery(e.target.value)}
            placeholder={"Search Reviews"}
            className="dashboard-header-search w-50"
          />
        </InputGroup>
        <button className="page-btn ms-auto px-2">
          <img src={assets.downloadIcon} alt="download" />
        </button>
        <button className="page-btn d-flex align-items-center mx-3 px-3">
          <img src={assets.sortIcon} alt="download" />
          <span className="ms-1 me-1">Filter</span>
          <img src={assets.dropdownArrow} alt="" />
        </button>
      </div>
      <Table
        data={results}
        columns={columns}
        paginationStyle={true}
        count={count}
        page={page}
        onNext={onNext}
        onPrev={onPrev}
        pageSize={10}
        isLoading={isLoading}
      />
    </div>
  )
}

export default OrderReview
