import React from "react"
import { useNavigate } from "react-router-dom"
import BackArrowNoTail from "../../atoms/back-arrow-no-tail"

import "./settings.scss"

function Settings() {
  const nav = useNavigate()

  const links = [
    { name: "Privacy Policy", link: "/dashboard/privacy-policy" },
    { name: "Terms & Conditions", link: "/dashboard/terms-and-conditions" },
    { name: "Support/Send Feedback" },
    { name: "Delete Account" }
  ]

  return (
    <>
      <div className="d-flex">
        <BackArrowNoTail />
        <h2 className="section-heading my-5">Settings</h2>
      </div>
      <div className="white-bg p-3 settings">
        {links.map(link => (
          <p key={link.name} className="p-3 my-0 link-border">
            <a onClick={() => nav(link.link || window?.location?.pathname)}>
              {link.name}
            </a>
          </p>
        ))}
      </div>
    </>
  )
}

export default Settings
