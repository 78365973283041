import React, { useState } from "react"
import ChartHeader from "../../../../../charts/chart-header"
import MultilineChart from "../../../../../charts/multiline-chart"
import Legend from "../../../../../charts/multiline-chart/components/legend"
import schc from "./SCHC.json"
import vcit from "./VCIT.json"
import portfolio from "./portfolio.json"
import "./styles.css"

const portfolioData = {
  name: "Portfolio",
  color: "#000",
  strokeDashArray: 3,
  strokeDashOffset: 3,
  items: portfolio.map(d => ({ ...d, date: new Date(d.date) }))
}
const schcData = {
  name: "SCHC",
  color: "#000",
  strokeWidth: 4,
  items: schc.map(d => ({ ...d, date: new Date(d.date) }))
}
const vcitData = {
  name: "VCIT",
  color: "#000",
  strokeDashArray: 10,
  strokeDashOffset: 10,
  items: vcit.map(d => ({ ...d, date: new Date(d.date) }))
}
function PeakTime(props) {
  const [selectedItems, setSelectedItems] = useState([])
  const legendData = [portfolioData, schcData, vcitData]
  const chartData = [
    portfolioData,
    ...[schcData, vcitData].filter(d => selectedItems.includes(d.name))
  ]
  const onChangeSelection = name => {
    const newSelectedItems = selectedItems.includes(name)
      ? selectedItems.filter(item => item !== name)
      : [...selectedItems, name]
    setSelectedItems(newSelectedItems)
  }
  const filters = [
    {
      name: "Time",
      value: "time"
    },
    {
      name: "Building",
      value: "building"
    },
    {
      name: "Area",
      value: "Area"
    }
  ]
  return (
    <div className="white-bg chart-wrap p-3">
      <ChartHeader
        title="Peak Times"
        subtitle="Lorem ipsum dolor sit amet consectetur adipisicing elit."
        dateFilter={true}
        filters={filters}
      />
      <div className="text-center position-relative my-5">
        <MultilineChart data={chartData} id={"peak-times"} />
        <Legend
          data={legendData}
          selectedItems={selectedItems}
          onChange={onChangeSelection}
        />
      </div>
    </div>
  )
}

export default PeakTime
