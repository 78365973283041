import React from "react"
import BackArrowNoTail from "../../../../atoms/back-arrow-no-tail"

function PrivacyPolicy(props) {
  return (
    <>
      <div className="d-flex">
        <BackArrowNoTail />
        <h2 className="section-heading my-5">Settings</h2>
      </div>
      <div className="terms-and-condition">
        <h4>Introduction</h4>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe
          ducimus, illo aliquam ad voluptatibus deserunt? Asperiores, quisquam
          dolorem! Debitis quis delectus eligendi tempora beatae, rerum et
          dignissimos placeat facere dolore. Lorem ipsum dolor sit amet
          consectetur adipisicing elit. Exercitationem quod repudiandae
          explicabo laudantium repellat maiores reiciendis. Ullam nesciunt
          maxime esse officia quas! Impedit ipsam debitis unde quidem amet
          perferendis. Itaque?
        </p>
        <h5>How we use your data ?</h5>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe
          ducimus, illo aliquam ad voluptatibus deserunt? Asperiores, quisquam
          dolorem! Debitis quis delectus eligendi tempora beatae, rerum et
          dignissimos placeat facere dolore. Lorem ipsum dolor sit amet
          consectetur adipisicing elit. Exercitationem quod repudiandae
          explicabo laudantium repellat maiores reiciendis. Ullam nesciunt
          maxime esse officia quas! Impedit ipsam debitis unde quidem amet
          perferendis. Itaque?
        </p>
        <h5>Personalising your data</h5>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe
          ducimus, illo aliquam ad voluptatibus deserunt? Asperiores, quisquam
          dolorem! Debitis quis delectus eligendi tempora beatae, rerum et
          dignissimos placeat facere dolore. Lorem ipsum dolor sit amet
          consectetur adipisicing elit. Exercitationem quod repudiandae
          explicabo laudantium repellat maiores reiciendis. Ullam nesciunt
          maxime esse officia quas! Impedit ipsam debitis unde quidem amet
          perferendis. Itaque?
        </p>
      </div>
    </>
  )
}

export default PrivacyPolicy
