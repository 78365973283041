import React from "react"
import create from "zustand"

const useStore = create(set => ({
  setFaceImage: faceImage => set({ faceImage }),
  faceImage: null,
  docImages: [],
  pushDocImage: img => set(state => ({ docImages: [...state.docImages, img] })),
  setDocImages: imageArray => set({ docImages: imageArray })
}))

export const useStoreApis = () => {
  const [setFaceImage, faceImage] = useStore(state => [
    state.setFaceImage,
    state.faceImage
  ])
  return { setFaceImage, faceImage }
}


const useSignup = create(set => ({
  formValues: {},
  setFormValues: (values) => set(state => ({ ...state, formValues: { ...state.formValues, ...values}}))
}))

export const useSignupApis = () => {
  const [formValues, setFormValues] = useSignup(state => [
    state.formValues,
    state.setFormValues
  ])
  return { formValues, setFormValues }
}
