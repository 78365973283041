import React, { useState } from "react"
import IndexPalleteBarChartMinMaxAvg from "../../../../atoms/chart-index-palette/bar-chart-min-max-avg"
import BarChartMinMaxAvg from "../../../../charts/bar-chart-min-max-avg"
import ChartHeader from "../../../../charts/chart-header"
import { useGetWaitingTime } from "../../../../queries-and-mutations/queries"
import { prepareWaitingChartData } from "../../../../utils/charts"

function DeliveryTimesChart(props) {
  const [params, setParams] = useState({})
  const filters = [
    {
      name: "Time",
      value: "time"
    },
    {
      name: "Category",
      value: "category"
    },
    {
      name: "Transport Time",
      value: "transport-time"
    }
  ]
  const {
    data: dataElevatorGraph,
    isFetching: isFetchingElevatorGraph
  } = useGetWaitingTime({
    enabled: true,
    params: {
      date: params && params.selectedDate,
      "report-type": params && params.reportType && params.reportType.value
    }
  })
  return (
    <div className="white-bg chart-wrap p-3">
      <ChartHeader
        title="Delivery Time"
        subtitle="Lorem ipsum dolor sit amet consectetur adipisicing elit."
        dateFilter={true}
        filters={filters}
        updateParams={q => setParams(q)}
      />
      <div className="text-center position-relative">
        {!isFetchingElevatorGraph && (
          <React.Fragment>
            <BarChartMinMaxAvg
              data={prepareWaitingChartData(
                dataElevatorGraph.data,
                params?.reportType?.value
              )}
              id="owner-wait-time-chart"
            />
            <IndexPalleteBarChartMinMaxAvg className={"mb - 3 py-1"} />
          </React.Fragment>
        )}
      </div>
    </div>
  )
}

export default DeliveryTimesChart
