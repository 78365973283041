import { post, get, patch, del, put } from "./http-methods"

import loginBackgroundImage from "../assets/images/login-background.png"

import selectServiceProviderLogo from "../assets/images/sp-logo.png"
import selectOwnerLogo from "../assets/images/sp-logo.png"
import logoWhite from "../assets/images/logo-white.png"
import daarnaLogo from "../assets/images/daarna-logo.svg"

import buildingIcon from "../assets/images/icon-building.png"
import peopleIcon from "../assets/images/people.png"
import profileIcon from "../assets/images/profile.svg"
import lockIcon from "../assets/images/lockIcon.svg"
import searchIcon from "../assets/images/search-icon.svg"
import bellIcon from "../assets/images/bell.svg"
import dvTextIcon from "../assets/images/dv-text.svg"
import maskIcon from "../assets/images/action-mask-icon.svg"
import downloadIcon from "../assets/images/download.svg"
import sortIcon from "../assets/images/sort.svg"
import callIcon from "../assets/images/call-icon.svg"
import mailIcon from "../assets/images/mail-icon.svg"
import logoutIcon from "../assets/images/logout-icon.svg"
import uploadIcon from "../assets/images/upload.svg"

import iconClock from "../assets/images/icon-clock.svg"
import iconPeople from "../assets/images/icon-people.svg"
import iconStops from "../assets/images/icon-stops.svg"

import avatar from "../assets/images/avatar.svg"
import dropdownArrow from "../assets/images/drop-down-arrow.svg"
import backArrowNoTail from "../assets/images/back-arrow.svg"
import mockQRcode from "../assets/images/qr-sample.svg"

import TileBgElevatorStatus from "../assets/images/button-img-elevator-status.png"
import TileBgDigitalLocker from "../assets/images/button-img-digital-locker.png"
import TileBgParkingStatus from "../assets/images/button-img-parking-status.png"
import TileBgSalesNLeasing from "../assets/images/button-img-sales-n-leasing.png"
import OrderDetail from "../assets/images/order-detail.png"
import OrderItem1 from "../assets/images/order-item-1.png"
import OrderItem2 from "../assets/images/order-item-2.png"
import Gym from "../assets/images/gym.png"
import Parking from "../assets/images/parking.png"
import ImageIcon from "../assets/images/image-icon.png"
import PdfIcon from "../assets/images/pdf-icon.png"

export const assets = {
  loginBackgroundImage,

  selectServiceProviderLogo,
  selectOwnerLogo,
  logoWhite,
  daarnaLogo,

  buildingIcon,
  peopleIcon,
  profileIcon,
  lockIcon,
  searchIcon,
  bellIcon,
  dvTextIcon,
  maskIcon,
  downloadIcon,
  sortIcon,
  callIcon,
  mailIcon,
  logoutIcon,
  uploadIcon,

  iconClock,
  iconPeople,
  iconStops,

  avatar,
  dropdownArrow,
  backArrowNoTail,
  mockQRcode,

  TileBgElevatorStatus,
  TileBgDigitalLocker,
  TileBgParkingStatus,
  TileBgSalesNLeasing,

  OrderDetail,
  OrderItem1,
  OrderItem2,

  Gym,
  Parking,
  ImageIcon,
  PdfIcon
}

export const LocalStorage = {
  write: (key, val) => {
    if (val && typeof val === "object")
      localStorage.setItem(key, JSON.stringify(val))
    else localStorage.setItem(key, val)
  },
  read: key => {
    let str = localStorage.getItem(key)
    if (["{", "["].find(e => str?.charAt(0) === e) || str === "null") {
      return JSON.parse(str)
    }
    return str
  }
}

const regex = {
  internationalPhone: /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/,
  email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  name: /^([a-zA-Z, .'-]){3,}$/i
}

export const UserTypes = {
  serviceProvider: 3,
  owner: 4,
  tenant: 1,
  carrier: 2
}

export const UserEnum = {
  TENANT: 1,
  CARRIER: 2,
  serviceProvider: 3,
  Owner: 4,
  ADMIN: 5
}

export const getUserTypeStringFromEnum = _enum => {
  switch (_enum) {
    case 1:
      return "Tenant"
    case 2:
      return "Carrier"
    case 3:
      return "Service Provider"
    case 4:
      return "Owner"
    case 5:
      return "Admin"
  }
}

export const validation = {
  //** do not name the method for matching against regex as pattern as it seems to create name conflict with useform library **
  username: {
    match: username =>
      regex.email.test(username) ||
      regex.internationalPhone.test(username) ||
      "Not a valid phone/email"
  },
  user_email: {
    match: user_email => regex.email.test(user_email) || "Not a valid email"
  },
  user_number: {
    match: user_number =>
      regex.internationalPhone.test(user_number) || "Not a valid phone"
  },

  name: {
    match: name =>
      !name ||
      regex.name.test(name) ||
      (name.length < 3 ? "Too short" : "Invalid name")
  },

  nameMax30: {
    match: name =>
      !name ||
      /^([a-zA-Z, .'-]){3,30}$/i.test(name) ||
      (name.length < 3
        ? "Too short"
        : name.length > 30
        ? "Too long"
        : "Invalid name")
  },

  nameMax40: {
    match: name =>
      !name ||
      /^([a-zA-Z, .'-]){3,40}$/i.test(name) ||
      (name.length < 3
        ? "Too short"
        : name.length > 40
        ? "Too long"
        : "Invalid name")
  },

  email: {
    match: email => !email || regex.email.test(email) || "invalid email address"
  },

  number: {
    match: number => !number || /^\d+(\.\d+)?$/.test(number) || "Invalid number"
  },

  phone: {
    us: phoneNumber =>
      !phoneNumber ||
      (/^\d+$/.test(phoneNumber) && phoneNumber.length === 10) ||
      "invalid phone number"
  },

  dob: {
    notToday: dateInput => {
      let today = new Date()
      today.setHours(0, 0, 0, 0)
      return (
        !dateInput ||
        new Date(dateInput).getTime() < today.getTime() ||
        "Please enter your date of birth"
      )
    }
  },
  internationalPhone: {
    internationalPhone: val =>
      regex.internationalPhone.test(val) || "invalid phone"
  }
}

export const getUserEnum = () => parseInt(LocalStorage.read("user-enum"))
export const setUserEnum = _enum => LocalStorage.write("user-enum", _enum)
export const getUserDetails = () => LocalStorage.read("user") || {}
export const getDate = input => {
  let date =
    input instanceof Date && !isNaN(input) ? new Date(input) : new Date()
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
}
export const resolveKeyPath = (obj, keyPath, { fallbackValue } = {}) => {
  return (
    keyPath.split(".").reduce((acc, key) => acc?.[key], obj) || fallbackValue
  )
}
export const placeValueOnKeyPath = (
  destinationObj = {},
  destinationKeyPath,
  sourceObj,
  sourceKeyPath
) => {
  const [keyToUse, ...rest] = destinationKeyPath.split(".")
  if (keyToUse)
    return {
      ...destinationObj,
      [keyToUse]: placeValueOnKeyPath(
        destinationObj?.[keyToUse],
        rest.join("."),
        sourceObj,
        sourceKeyPath
      )
    }
  return resolveKeyPath(sourceObj, sourceKeyPath)
}

export const extractValuesFromForm = (keyArr, vals) =>
  keyArr.reduce((acc, key) => {
    if (!Array.isArray(key)) return { ...acc, [key]: resolveKeyPath(vals, key) }
    return placeValueOnKeyPath(acc, key[0], vals, key[1])
  }, {})

export const translateSvg = (x, y) => ["transform", `translate(${x},${y})`]

export const getChartFilters = () => {
  return [
    {
      name: "Time",
      value: "time"
    },
    {
      name: "Category",
      value: "category"
    },
    {
      name: "Area",
      value: "area"
    }
  ]
}
export const webApiHandler = {
  sendOtp: payload => post("/home/api/v1/send-phone-otp/", payload),
  resendOtp: payload => post("/home/api/v1/login/resend-otp/", payload),
  verifyOtp: payload => post("/home/api/v1/confirm-phone-otp/", payload),
  adminLogin: payload => post("/custom-admin/api/v1/login/", payload),
  adminLogout: payload => post("/custom-admin/api/v1/logout/"),
  getCarriers: (page, q, page_size = 10) =>
    get("/carriers/api/v1/get-carriers/", { params: { page, page_size, q } }),
  getUsers: (page, search, page_size = 10, params = {}) =>
    get("/owner-admin/api/v1/users/", {
      params: { page, page_size, search, ...params }
    }),
  getAdminUsers: (page, search, page_size = 10, params = {}) =>
    get("/custom-admin/api/v1/users/", {
      params: { page, page_size, search, ...params }
    }),
  getAdminUserDetail: id => get(`/custom-admin/api/v1/users/${id}/`),
  getCategories: () => get("/home/api/v1/get-categories/"),
  getSubCategories: id => get(`/home/api/v1/get-sub-categories/${id}/`),
  createUser: payload => post("/home/api/v1/signup/", payload),
  createRequestAccess: payload => post("/home/api/v1/request-access/", payload),
  editUser: (id, payload) => put(`/owner-admin/api/v1/user/${id}/`, payload),
  getUser: id => get(`/user/api/v1/get-user/${id}/`),
  getApproveUser: (page, q, page_size = 10, params = {}) =>
    get(`/owner-admin/api/v1/approve-account/`, {
      params: { page, page_size, search: q, ...params }
    }),
  getFeedbackListing: (params = {}) =>
    get(`/user/api/v1/user-profile-feedback/`, { params }),
  feedbackReply: (id, payload) =>
    patch(`/user/api/v1/user-profile-feedback/${id}/`, payload),
  regulaProcess: payload => post(`/api/process`, payload),
  userSignup: payload => post(`/home/api/v1/signup/`, payload),
  userDocument: payload => post(`/user/api/v1/document/`, payload),

  // property / building APIs
  getAllBuildings: () => get(`/property/api/v1/building/`),
  getElevatorGraph: params => {
    const { report_type, ...restOfTheParams } = params
    return get(`/property/api/v1/graph/elevator/`, {
      params: {
        "report-type": report_type,
        ...restOfTheParams
      }
    })
  },
  getWaitingTime: params =>
    get(`/property/api/v1/graph/owner/elevator/`, { params }),
  getReviews: (page, q, page_size = 10, params = {}) =>
    get("/sp-admin/api/v1/reviews/", {
      params: { page, page_size, search: q, ...params }
    }),
  approveUser: (id, payload) =>
    patch(`/owner-admin/api/v1/approve-account/${id}/`, payload),
  getSlots: () => get("/car/api/v1/slot/"),
  deleteUser: id => del(`/user/api/v1/all-users/${id}/`),
  getOwnerList: (page, q, page_size = 10, params = {}) =>
    get("/custom-admin/api/v1/owner/", {
      params: { page, page_size, search: q, ...params }
    }),
  createOwner: payload => post("/custom-admin/api/v1/owner/", payload),
  editOwner: (id, payload) => put(`/custom-admin/api/v1/owner/${id}/`, payload),
  deleteOwner: id => del(`/custom-admin/api/v1/owner/${id}/`),
  sendAppLinkOwner: payload => post('/owner-admin/api/v1/app-link/', payload),
  sendAppLinkAdmin: payload => post('/custom-admin/api/v1/app-link/', payload),
  flagUnflagUser: (id, payload) => patch(`/custom-admin/api/v1/users/${id}/`, payload),
}

export const formatDateToString = dateObject => {
  return `${dateObject.getFullYear()}-${zPad(
    dateObject.getMonth() + 1,
    2
  )}-${zPad(dateObject.getDate(), 2)}`
}

export const formatDateToStringUS = dateObject => {
  let d = formatDateToString(dateObject)
  d = d.split("-")
  return `${d[1]}/${d[2]}/${d[0]}`
}

export const zPad = (n, width, z) => {
  z = z || "0"
  n = n + ""
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n
}
export const openBase64Image = base64ImageData => {
  const contentType = "image/png"
  const byteCharacters = atob(
    base64ImageData.substr(`data:${contentType};base64,`.length)
  )
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
    const slice = byteCharacters.slice(offset, offset + 1024)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)

    byteArrays.push(byteArray)
  }
  const blob = new Blob(byteArrays, { type: contentType })
  const blobUrl = URL.createObjectURL(blob)

  window.open(blobUrl, "_blank")
}
