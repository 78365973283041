import React, { useState } from "react"
import Button from "../../../../atoms/button"
import { useModalAPIs } from "../../../../containers/popup-cotainer/modal-apis"
import { useReplyFeedbackMutation } from "../../../../queries-and-mutations/mutation"
import "./carrier-feedback.scss"

function CarrierFeedbackResponsePopup({ feedbackDetails }) {
  const [reply_message, setReply] = useState(
    feedbackDetails.reply_message || ""
  )
  const { setModalVisibility } = useModalAPIs()

  const feedbackReplyMutation = useReplyFeedbackMutation()

  const { title, message, id } = feedbackDetails

  const onReply = () => {
    feedbackReplyMutation.mutate(
      { id, title, message, status: false, reply_message },
      {
        onSuccess: res => {
          setModalVisibility(false)
        },
        onError: err => {}
      }
    )
  }

  return (
    <>
      <h3 className="text-center">Feedback</h3>
      <div className="popup-display-field-container">
        <h5 className="field-head">Title</h5>
        <p className="field-description">{title}</p>
      </div>
      <div className="popup-display-field-container">
        <h5 className="field-head">Message</h5>
        <p className="field-description">{message}</p>
      </div>
      <div className="popup-display-field-container">
        <h5 className="field-head">Reply</h5>
        <textarea
          value={reply_message}
          onChange={e => setReply(e.target.value)}
          className="w-100 text-area-reply p-3"
        ></textarea>
      </div>

      <div className="d-flex btn-grp pt-3 w-100">
        <button
          onClick={() => setModalVisibility(false)}
          className="cancel-btn white-bg w-100"
        >
          Cancel
        </button>
        <Button onClick={onReply} className="reply-btn" text={"Send Reply"} />
      </div>
    </>
  )
}

export default CarrierFeedbackResponsePopup
